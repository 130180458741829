/** @flow */
export default {
  colors: {
    primary: "#90c1db", //#FBDE18
    secondary: "#FBDE18" //#4CC0F6
  },
  samples: {
    limiter: 0.2
  }
};
