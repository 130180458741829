/** @flow */
import React from "react";

import Logo from "../components/Logo";

type Props = {};

const Splashscreen = (props: Props) => (
  <div className={"splashscreen-container"}>
    <Logo />
    <div>
      <i className={"icon-spin animate-spin"} />
    </div>
  </div>
);

export default Splashscreen;
