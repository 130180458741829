/** @flow */
import { action, observable, computed } from "mobx";
import i18nService from "../services/i18n";

import * as serviceWorker from "../serviceWorker";

export default class PWA {
  @observable
  isUpdateAvailable: boolean = false;

  constructor() {
    serviceWorker.register({
      onUpdate: () => {
        this.isUpdateAvailable = true;
      }
    });
  }

  @action
  reload = () => window.location.reload();
}
