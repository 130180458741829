/** @flow */
import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import CommonLayout from "../components/CommonLayout";
import Indicator from "../components/Indicator";
import Toolbar from "../components/Toolbar";
import MessageBox from "../components/MessageBox";

import utilText from "../utils/text";

import type { Stores } from "../types/Stores";
import type { Indicator as TypeIndicator } from "../types/Indicator";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { User as TypeUser } from "../types/User";
import type { Translate, I18n } from "../types/I18n";
import type { Match } from "../types/ContextRouter";

type StoresProps = {
  resetMonthlyProductions: () => void,
  fetchMonthlyProductions: () => Promise<TypeResultMessage>,
  monthlyProductions: TypeIndicator[] | null,
  monthlyTotalProduction: number,
  isFetchingMonthlyProductions: boolean,
  user: TypeUser,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = {
  match: Match, //react-router url parameters
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const month = parseInt(props.match.params.month) || 0;
  return {
    resetMonthlyProductions: stores.plants.resetMonthlyProductions,
    fetchMonthlyProductions: () => stores.plants.fetchMonthlyProductions(month),
    monthlyProductions: stores.plants.monthlyProductions,
    monthlyTotalProduction: stores.plants.monthlyTotalProduction,
    isFetchingMonthlyProductions: stores.plants.isFetchingMonthlyProductions,
    user: stores.auth.user,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class MonthlyProductions extends Component<Props, State> {
  @observable
  fetchError: TypeResultMessage | null = null;
  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { fetchMonthlyProductions, markSessionAsExpired } = this.props;

    this.setFetchError(null);

    const result = await fetchMonthlyProductions();
    if (!result.success) {
      if (result.error === "Unauthorized") {
        markSessionAsExpired();
      }
      this.setFetchError(result);
    }
  };

  componentWillUnmount() {
    this.props.resetMonthlyProductions();
  }

  render() {
    const {
      monthlyProductions,
      monthlyTotalProduction,
      t,
      isFetchingMonthlyProductions,
      user,
      isSessionExpired,
      isOpenSidebarMenu,
      toggleSidebarMenu
    } = this.props;

    const _currTime = new Date(),
      currYear = _currTime.getFullYear(),
      { month } = this.props.match.params;

    if (isSessionExpired) return null;

    return (
      <CommonLayout
        breadcrumb={t("totalProductions")}
        user={user}
        backButton={true}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          <h2>
            {t("monthlyProductions", {
              month: t(`calendar.months.${month ? month : "-1"}`),
              year: currYear
            })}
          </h2>
        </div>
        <section className="content lr-pdd prod-ann-container">
          {this.fetchError && !this.fetchError.success && (
            /** $FlowFixMe */
            <MessageBox
              type={this.fetchError.type}
              message={this.fetchError.message}
            />
          )}
          {monthlyProductions &&
            monthlyProductions.map((prod, idx) => (
              <Indicator key={idx} data={prod} />
            ))}
        </section>

        {!isFetchingMonthlyProductions && (
          <Toolbar
            title={t("totalProduction")}
            metaList={[
              {
                value: t("kWhValue", {
                  value: utilText.formatNumber(monthlyTotalProduction)
                })
              }
            ]}
          />
        )}
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(MonthlyProductions);
