/** @flow */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { action, observable, computed } from "mobx";

import MenuSvg from "../assets/images/line-menu.svg";
import Logo from "../assets/images/logo.svg";

import type { RouterHistory, Location } from "../types/ContextRouter";

type Props = {
  location: Location, // react-router-dom different from RouterHistory.location
  history: RouterHistory,
  backButton?: boolean,
  backUrl?: string, // force the URL of the back button
  plantId?: string,
  toggleMainMenu: () => any
};
type State = {};

@observer
class MainNav extends Component<Props, State> {
  handleNavAction = () => {
    const { backUrl, history } = this.props;
    if (!backUrl) {
      history.goBack();
    } else {
      history.push(backUrl);
    }
  };

  render() {
    const { backButton, toggleMainMenu } = this.props;
    let navActionIcon = "menu";

    if (this.props.backButton) {
      navActionIcon = "left-arrow";
    }

    return (
      <div className={`main-nav`}>
        <div className={"nav-action"}>
          {!backButton && (
            <button className="nav-btn" onClick={toggleMainMenu}>
              <i className={`icon-menu`} />
            </button>
          )}
          {backButton && (
            <button className="nav-btn" onClick={this.handleNavAction}>
              <i className={`icon-left-arrow`} />
            </button>
          )}
        </div>
        <img className="brand" src={Logo} alt="logo" />
      </div>
    );
  }
}

export default withRouter(MainNav);
