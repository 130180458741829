/** @flow */
import React, { Component } from "react";
import { observable, action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import Input from "../components/Input";
import Button from "../components/Button";
import MessageBox from "../components/MessageBox";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { RouterHistory } from "../types/ContextRouter";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

type StoresProps = {
  changePassword: (newPassword: string, oldPassword: string) => any
};
type OwnProps = {
  history: RouterHistory,
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  changePassword: stores.auth.changePassword
});

@inject(mapStoresToProps)
@observer
class ProfileUser extends Component<Props, State> {
  @observable
  changePasswordResult: TypeResultMessage = {};

  @observable
  isChangePasswordPending: boolean = false;
  @observable
  oldPassword: string = "";
  @observable
  newPassword: string = "";
  @observable
  confirmNewPassword: string = "";

  @action
  setOldPassword = (e: any) => (this.oldPassword = e.target.value);
  @action
  setNewPassword = (e: any) => (this.newPassword = e.target.value);
  @action
  setConfirmNewPassword = (e: any) =>
    (this.confirmNewPassword = e.target.value);

  @computed
  get isFormValid() {
    return (
      this.oldPassword &&
      this.newPassword &&
      this.confirmNewPassword &&
      this.newPassword === this.confirmNewPassword
    );
  }

  subChangePassword = async () => {
    this.changePasswordResult = {};
    this.isChangePasswordPending = true;

    this.changePasswordResult = await this.props.changePassword(
      this.newPassword,
      this.oldPassword
    );

    this.isChangePasswordPending = false;
  };

  render() {
    const { t } = this.props;
    return (
      <div className={"content-wrapper"}>
        <div className={"sub-header lr-pdd"}>
          <h2>{t("changePassword")}</h2>
        </div>
        <section className={"stat-imp-container content lr-pdd"}>
          {this.changePasswordResult && (
            /** $FlowFixMe */
            <MessageBox
              type={this.changePasswordResult.type}
              message={this.changePasswordResult.message}
            />
          )}

          <Input
            type={"password"}
            name={"oldPassword"}
            value={this.oldPassword}
            onChange={this.setOldPassword}
            label={t("oldPassword")}
          />

          <Input
            type={"password"}
            name={"newPassword"}
            value={this.newPassword}
            onChange={this.setNewPassword}
            label={t("newPassword")}
          />

          <Input
            type={"password"}
            name={"confirmNewPassword"}
            value={this.confirmNewPassword}
            onChange={this.setConfirmNewPassword}
            label={t("confirmNewPassword")}
          />

          <div className="btn-wrapper">
            <Button
              text={t("confirm")}
              disabled={!this.isFormValid || this.isChangePasswordPending}
              isLoading={this.isChangePasswordPending}
              onClick={this.subChangePassword}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(ProfileUser);
