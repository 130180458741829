/** @flow */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import type { Translate, I18n } from "../types/I18n";

type Props = {
  t: Translate,
  i18n: I18n,
  data: Object,
  handleClick: (path: string) => any
};
type State = {};

class MenuItem extends Component<Props, State> {
  render() {
    const { t, data, handleClick } = this.props;

    return (
      <li
        className={data.active ? "nav-item active" : "nav-item"}
        onClick={() => handleClick(`/${data.path}`)}
      >
        <i className={`icon-menu icon-menu-${data.label}`} />
        {t(data.label)}

        {/*<Link to={`/${data.path}`}>
          <i className={`icon-menu icon-menu-${data.label}`} />
          {t(data.label)}
    </Link>*/}
      </li>
    );
  }
}

export default withTranslation("menu")(MenuItem);
