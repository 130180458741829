/** @flow */
import React, { Component } from "react";

import MainSidebar from "./MainSidebar";
import MainNav from "./MainNav";
import Breadcrumb from "./Breadcrumb";
import type { User as TypeUser } from "../types/User";

type Props = {
  children?: any,
  breadcrumb?: string,
  user: TypeUser,
  permissions?: string[],
  backButton?: boolean,
  backUrl?: string,
  plantId?: string,
  isMainMenuOpen: boolean,
  toggleMainMenu: () => any
};

type State = {};

class CommonLayout extends Component<Props, State> {
  render() {
    const {
      children,
      breadcrumb,
      user,
      permissions,
      backButton,
      backUrl,
      plantId,
      isMainMenuOpen,
      toggleMainMenu
    } = this.props;
    return (
      <div
        className={isMainMenuOpen ? "root-wrapper nav-open" : "root-wrapper"}
      >
        <MainSidebar
          user={user}
          open={isMainMenuOpen}
          handleToggle={toggleMainMenu}
          permissions={permissions}
          plantId={plantId}
        />
        <div className={"container"}>
          <header>
            <MainNav
              user={user}
              permissions={permissions}
              backButton={backButton}
              backUrl={backUrl}
              plantId={plantId}
              toggleMainMenu={toggleMainMenu}
            />

            {breadcrumb && <Breadcrumb label={breadcrumb} />}
          </header>
          <section className={"content-wrapper"}>{children}</section>
        </div>
      </div>
    );
  }
}
export default CommonLayout;
