/** @flow */

const getDateFormatISO8601 = (date?: Date): string => {
  let d = date ? new Date(date) : new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const isTodayDate = (date: Date) => {
  const currDate = new Date();

  return (
    currDate.getFullYear() === date.getFullYear() &&
    currDate.getMonth() === date.getMonth() &&
    currDate.getDate() === date.getDate()
  );
};

const getDayHours = (date: Date, hLimit?: number = 23) => {
  const dayHours = [];

  if (isTodayDate(date)) {
    hLimit = new Date().getHours();
  }

  for (let i = 0; i <= hLimit; i += 2) {
    let d = new Date(date.getTime());
    d.setHours(i, 0, 0, 0);
    dayHours.push(d.getTime());
  }

  return dayHours;
};

const getCurrentDayHours = () => {
  const _currDay = new Date(),
    _currH = _currDay.getHours();

  return getDayHours(_currDay, _currH);
};

const formatHoursMinutes = (date: Date): string => {
  let h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
    m = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${h}:${m}`;
};

const getYearHours = (limitDate: Date): number => {
  let fistDayOfYear = new Date(limitDate.getFullYear(), 0, 1);

  return Math.abs(limitDate - fistDayOfYear) / 36e5;
};

export default {
  getDateFormatISO8601,
  getDayHours,
  getCurrentDayHours,
  formatHoursMinutes,
  getYearHours
};
