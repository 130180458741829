/** @flow */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import type { Translate, I18n } from "../types/I18n";
import MessageBox from "./MessageBox";

type State = {
  hasError: boolean
};

type Props = {
  t: Translate,
  i18n: I18n,
  children: any
};

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false
  };
  static getDerivedStateFromError(error: any) {
    return {
      hasError: true
    };
  }
  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return <MessageBox type={"err"} message={t("requestError")} />;
    }
    return this.props.children;
  }
}

export default withTranslation("common")(ErrorBoundary);
