/** @flow */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import PlantModel from "../models/Plant";

import CommonLayout from "../components/CommonLayout";
import TabNav from "../components/TabNav";
import ProfileUser from "./ProfileUser";
import ProfileSettings from "./ProfileSettings";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { TabNav as TypeTabNav } from "../types/TabNav";
import type { User as TypeUser } from "../types/User";

const MODULE_NAME = "PROFILE";

type StoresProps = {
  setCurrentModule: (module: string) => string,
  user: TypeUser,
  plant: ?PlantModel,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = { t: Translate, i18n: I18n };
type Props = StoresProps & OwnProps;
type State = {};

const mapStoresToProps = (stores: Stores): StoresProps => {
  const plantId = stores.auth.getLastActivePlantId() || "";
  return {
    setCurrentModule: stores.auth.setCurrentModule,
    user: stores.auth.user,
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class Profile extends Component<Props, State> {
  componentDidMount() {
    this.props.setCurrentModule(MODULE_NAME);
  }

  render() {
    const { t, user, plant, isOpenSidebarMenu, toggleSidebarMenu } = this.props;

    const tabs: TypeTabNav[] = [
      {
        label: t("user"),
        icon: "user",
        url: "user"
      },
      {
        label: t("settings"),
        icon: "settings",
        url: "settings"
      }
    ];

    return (
      <CommonLayout
        breadcrumb={t("accountMng")}
        user={user}
        permissions={plant ? plant.permissions : []}
        plantId={plant ? plant.id : ""}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <TabNav tabsNav={tabs}>
          <ProfileUser />
          <ProfileSettings />
        </TabNav>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(Profile);
