/** @flow */

import languages from "../config/languages";
import type { Language as TypeLanguage } from "../types/Language";

const getClientLanguage = (): TypeLanguage => {
  // en-GB -> en
  // $FlowFixMe
  let lang = (navigator.language || navigator.userLanguage).split("-")[0];

  if (languages.indexOf(lang) === 0) {
    lang = "it";
  }

  // $FlowFixMe
  return lang;
};

export default {
  getClientLanguage
};
