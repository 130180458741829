/** @flow */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import MenuItem from "./MenuItem";

import type { Translate, I18n } from "../types/I18n";

type Props = {
  t: Translate,
  i18n: I18n,
  data: Object,
  handleClick: (path: string) => any
};
type State = {
  open: boolean
};

class MenuItemDropdown extends Component<Props, State> {
  state = {
    open: true
  };

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { t, data, handleClick } = this.props,
      { open } = this.state,
      icon = `icon-${open ? "up-open" : "down-open"}`;

    return (
      <li className={"nav-separator"}>
        <div className={"nav-separator-header"} onClick={this.toggle}>
          <span>{t(data.label)}</span>
          <span>
            <i className={icon} />
          </span>
        </div>
        {open && (
          <ul className={"nav"}>
            {data.children.map((item, idx) => (
              <MenuItem data={item} key={idx} handleClick={handleClick} />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

export default withTranslation("menu")(MenuItemDropdown);
