/** @flow */
import React, { Component } from "react";

type State = {
  active: boolean
};
type Props = {
  type: string,
  name: string,
  id?: string,
  error?: string,
  isEnabled?: boolean,
  label?: string,
  placeholder?: string,
  iconName?: string,
  iconSize?: number,
  autoFocus?: boolean
};

class Input extends Component<Props, State> {
  state = {
    active: false
  };

  inputRef: ?any = null;

  handleFocus = () => {
    this.setState({
      active: true
    });
  };

  handleBlur = () => {
    this.setState({
      active: false
    });
  };

  handleClick = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  render() {
    const { id, name, label, error, iconName, ...otherProps } = this.props;

    let elId = id ? id : name;

    return (
      <div
        className={`form-item ${this.state.active && !error ? "active" : ""} ${
          error ? "error" : ""
        }`}
      >
        {label && <label htmlFor={elId}>{label}</label>}
        <div className="input-wrapper">
          {iconName && (
            <i className={`icon-${iconName}`} onClick={this.handleClick} />
          )}
          <input
            id={elId}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            ref={ref => (this.inputRef = ref)}
            {...otherProps}
          />

          {error && <div className="input-errors">{error}</div>}
        </div>
      </div>
    );
  }
}

export default Input;
