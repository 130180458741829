/** @flow */
import constants from "../config/constants";
import qs from "querystring";

const fetchOpts = {
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/x-www-form-urlencoded",
    "X-STEGuardian-App": "true"
  },
  credentials: "include"
};
const fetchOptsJson = {
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "X-STEGuardian-App": "true"
  },
  credentials: "include"
};

const _getUrlWithParams = (url: string, params: {}) => {
  let _url = new URL(url);
  Object.keys(params).forEach(key =>
    _url.searchParams.append(key, params[key])
  );

  return _url;
};

// 2. Autenticazione
const login = async (username: string, password: string) => {
  const res = await fetch(`${constants.SERVER_BASE_URL}/authenticate`, {
    ...fetchOpts,
    method: "POST",
    body: qs.stringify({
      username,
      password
    })
  });

  return await res.json();
}; // login

const logout = async () => {
  const res = await fetch(`${constants.SERVER_BASE_URL}/logout`, {
    ...fetchOpts,
    method: "GET"
  });

  return await res.json();
}; // logout

// 4.1 Allarmi Service
const getListaClassiAllarmi = async () => {
  const res = await fetch(`${constants.API_BASE_URL}/allarmi/classi`, {
    ...fetchOptsJson,
    method: "GET"
  });
  return await res.json();
}; //getListaClassiAllarmi

const getListaAllarmiImpianto = async (id: string) => {
  const res = await fetch(`${constants.API_BASE_URL}/allarmi/list/${id}`, {
    ...fetchOptsJson,
    method: "GET"
  });
  return await res.json();
}; //getListaAllarmiImpianto

const getDettaglioAllarme = async (id: number) => {
  const res = await fetch(`${constants.API_BASE_URL}/allarmi/${id}`, {
    ...fetchOptsJson,
    method: "GET"
  });
  return await res.json();
}; //getDettaglioAllarme

const submitMessaggioAckAllarme = async (id: number, message: string) => {
  const res = await fetch(`${constants.API_BASE_URL}/allarmi/${id}/ack`, {
    ...fetchOptsJson,
    method: "POST",
    body: JSON.stringify({
      commento: message
    })
  });

  return await res.json();
};

// 4.7 Forecast Service
const getForecastListaMensile = async (plantId: string, year: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/forecast/${plantId}/${year}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );
  return await res.json();
}; //getForecastListaMensile

const getForecastListaGiornaliera = async (
  plantId: string,
  year: number,
  month: number
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/forecast/${plantId}/${year}/${month}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );
  return await res.json();
}; //getForecastListaGiornaliera

const getForecastTrendGiornalieri = async (
  plantId: string,
  year: number,
  month: number,
  day: number
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/forecast/${plantId}/${year}/${month}/${day}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );
  return await res.json();
}; //getForecastTrendGiornalieri

// 4.8 Impianti Service
const getStatisticheImpianti = async () => {
  const res = await fetch(`${constants.API_BASE_URL}/impianti/statistiche`, {
    ...fetchOptsJson,
    method: "GET"
  });

  return await res.json();
}; // getStatisticheImpianti

// 4.9 Impianto Service
const getImpianto = async (id: string) => {
  const res = await fetch(`${constants.API_BASE_URL}/impianto/${id}`, {
    ...fetchOptsJson,
    method: "GET"
  });
  return await res.json();
}; //getImpianto

const getControlloAllarmi = async (id: string) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/impianto/${id}/check-alarms`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );
  return await res.json();
}; //getControlloAllarmi

const getControlloWarnings = async (id: string) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/impianto/${id}/check-warnings`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );
  return await res.json();
}; //getControlloWarnings

const getPotenzaAttuale = async (id: string) => {
  const res = await fetch(`${constants.API_BASE_URL}/impianto/${id}/potenza`, {
    ...fetchOptsJson,
    method: "GET"
  });
  return await res.json();
}; //getPotenzaAttuale

const getListaTags = async (id: string) => {
  const res = await fetch(`${constants.API_BASE_URL}/impianto/${id}/tags`, {
    ...fetchOptsJson,
    method: "GET"
  });

  return await res.json();
}; //getListaTags

const getListaValoriTags = async (id: string) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/impianto/${id}/tags/values`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getListaValoriTags

const getTrendPotenzaOdierno = async (id: string, maxSamples?: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/impianto/${id}/trend-potenza${
      maxSamples ? `?limit=${maxSamples}` : ""
    }`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getTrendPotenzaOdierno

// 4.10 Operatore Service
const getCurrentUser = async () => {
  const res = await fetch(`${constants.API_BASE_URL}/operatore`, {
    ...fetchOpts,
    method: "GET"
  });

  return await res.json();
}; // getCurrentUser

const getAbilitazioni = async () => {
  const res = await fetch(`${constants.API_BASE_URL}/operatore/abilitazioni`, {
    ...fetchOptsJson,
    method: "GET"
  });

  return await res.json();
}; // getAbilitazioni

const changePassword = async (newPassword: string, oldPassword: string) => {
  const input = { newPassword, oldPassword };

  const res = await fetch(
    `${constants.API_BASE_URL}/operatore/change-password`,
    {
      ...fetchOptsJson,
      method: "POST",
      body: JSON.stringify(input)
    }
  );

  return await res.json();
}; // changePassword

const getListaImpianti = async () => {
  const res = await fetch(`${constants.API_BASE_URL}/operatore/impianti`, {
    ...fetchOptsJson,
    method: "GET"
  });

  return await res.json();
}; // getListaImpianti

const getListaPreset = async () => {
  const res = await fetch(`${constants.API_BASE_URL}/operatore/presets`, {
    ...fetchOptsJson,
    method: "GET"
  });

  return await res.json();
}; // getListaPreset

// 4.12 Password Service
const resetPassword = async (username: string) => {
  const url = _getUrlWithParams(`${constants.API_BASE_URL}/password/reset`, {
    username
  });

  const res = await fetch(url, {
    ...fetchOptsJson,
    method: "POST"
  });

  return await res.json();
}; // resetPassword

// 4.14 Produzioni Service
const getProduzioniListaMensile = async (year: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/produzione/totale/${year}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; // getProduzioniListaMensile

const getProduzioniListaGiornaliera = async (year: number, month: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/produzione/totale/${year}/${month}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; // getProduzioniListaGiornaliera

const getProduzioniListaMensileImpianto = async (
  plantId: string,
  year: number
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/produzione/${plantId}/${year}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getProduzioniListaMensileImpianto

const getProduzioniListaGiornalieraImpianto = async (
  plantId: string,
  year: number,
  month: number
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/produzione/${plantId}/${year}/${month}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getProduzioniListaGiornalieraImpianto

// 4.15 Report Service
const getShutdownReport = async (
  plantId: string,
  fromDate: string,
  toDate: string
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/report/${plantId}/fermi/${fromDate}/${toDate}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getShutdownReport

const getIndicatorsReport = async (plantId: string, year: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/report/${plantId}/indicatori/${year}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getIndicatorsReport

const getMonthlyReport = async (
  plantId: string,
  year: number,
  month: number
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/report/${plantId}/mensile/${year}/${month}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getMonthlyReport

const getPdmReport = async (
  plantId: string,
  fromDate: string,
  toDate: string,
  type?: string,
  wip: string,
  shutdowns?: string,
  open: string
) => {
  const res = await fetch(
    `${
      constants.API_BASE_URL
    }/report/${plantId}/pdm/${fromDate}/${toDate}?aperti=${open.toString()}&inLavorazione=${wip.toString()}${
      type ? `&tipologia=${type}` : ""
    }${shutdowns ? `&fermi=${shutdowns.toString()}` : ""}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getPdmReport

// 4.16 Session Service
const setLocale = async (locale: string) => {
  const url = _getUrlWithParams(
    `${constants.API_BASE_URL}/session/set-locale`,
    { locale }
  );

  const res = await fetch(url, {
    ...fetchOptsJson,
    method: "POST"
  });

  return await res.json();
}; // setLocale

const setPreset = async (preset: ?string) => {
  const params = preset ? { preset } : {};
  const url = _getUrlWithParams(
    `${constants.API_BASE_URL}/session/set-preset`,
    params
  );

  const res = await fetch(url, {
    ...fetchOptsJson,
    method: "POST"
  });

  return await res.json();
}; // setPreset

const setTimezone = async (timeZone: string) => {
  const url = _getUrlWithParams(
    `${constants.API_BASE_URL}/session/set-timezone`,
    { timeZone }
  );

  const res = await fetch(url, {
    ...fetchOptsJson,
    method: "POST"
  });

  return await res.json();
}; // setTimezone

// 4.17 Statistiche Service
const getDisponibilitaMensili = async (plantId: string, year: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/stats/${plantId}/disponibilita/${year}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; // getDisponibilitaMensili

const getIndicatoriMensili = async (plantId: string, year: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/stats/${plantId}/indicatori/${year}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; // getIndicatoriMensili

const getPRMensili = async (plantId: string, year: number) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/stats/${plantId}/pr/${year}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; // getPRMensili

const getPRGiornalieri = async (
  plantId: string,
  year: number,
  month: number
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/stats/${plantId}/pr/${year}/${month}`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; // getPRGiornalieri

// 4.18 Trend Service
// format date: yyyy-mm-dd
const getTrendGiornaliero = async (
  tagId: string,
  date: string,
  maxSamples?: number
) => {
  const res = await fetch(
    `${constants.API_BASE_URL}/trend/${tagId}/${date}${
      maxSamples ? `?limit=${maxSamples}` : ""
    }`,
    {
      ...fetchOptsJson,
      method: "GET"
    }
  );

  return await res.json();
}; //getTrendGiornaliero

const getListaAllarmi = async () => {
  const res = await fetch(`${constants.API_BASE_URL}/allarmi/list`, {
    ...fetchOptsJson,
    method: "GET"
  });

  return await res.json();
}; // getListaAllarmi

export default {
  login,
  logout,
  getListaClassiAllarmi,
  getListaAllarmiImpianto,
  getDettaglioAllarme,
  submitMessaggioAckAllarme,
  getForecastListaMensile,
  getForecastListaGiornaliera,
  getForecastTrendGiornalieri,
  getStatisticheImpianti,
  getImpianto,
  getControlloAllarmi,
  getControlloWarnings,
  getPotenzaAttuale,
  getListaTags,
  getListaValoriTags,
  getTrendPotenzaOdierno,
  getCurrentUser,
  getAbilitazioni,
  changePassword,
  getListaImpianti,
  getListaPreset,
  resetPassword,
  getProduzioniListaMensile,
  getProduzioniListaGiornaliera,
  getProduzioniListaMensileImpianto,
  getProduzioniListaGiornalieraImpianto,
  getShutdownReport,
  getIndicatorsReport,
  getMonthlyReport,
  getPdmReport,
  setLocale,
  setPreset,
  setTimezone,
  getDisponibilitaMensili,
  getIndicatoriMensili,
  getPRMensili,
  getPRGiornalieri,
  getTrendGiornaliero,
  getListaAllarmi
};
