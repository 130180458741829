/** @flow */
import React, { Component } from "react";
import PlantModel from "../models/Plant";
import { withRouter, Link } from "react-router-dom";
import type { RouterHistory as TypeRouterHistory } from "../types/ContextRouter";
import { withTranslation } from "react-i18next";
import type { Translate, I18n } from "../types/I18n";

import Button from "./Button";

import Drawer from "./Drawer";
import Select from "./Select";

type StoreProps = {
  plant: PlantModel,
  plants: PlantModel[],
  history: TypeRouterHistory //react-router-dom
};
type OwnProps = { t: Translate, i18n: I18n };
type Props = StoreProps & OwnProps;
type State = {
  visibleDrawer: boolean,
  selectedPlant: PlantModel
};

class PlantSelect extends Component<Props, State> {
  state = {
    visibleDrawer: false,
    selectedPlant: this.props.plant
  };

  componentDidMount() {
    if (this.props.plant) {
      this.props.plant.fetchMeta();
    }
  }

  toggleDrawer = () => {
    this.setState({
      visibleDrawer: !this.state.visibleDrawer
    });
  };

  setSelectedPlant = (e: any) => {
    this.setState({
      selectedPlant: this.props.plants.find(p => p.id === e.target.value)
    });
  };

  handleChangePlant = () => {
    const pathname = this.props.history.location.pathname.split("/");
    pathname[1] = this.state.selectedPlant.id;
    this.props.history.push(pathname.join("/"));
    this.toggleDrawer();
  };

  render() {
    const { plant, plants, t } = this.props;
    let _options: Object[] = plants
      ? plants.map(p => ({ id: p.id, value: p.name }))
      : [];

    return (
      <div className={"plant-select-container"}>
        <div className={"plant-select"} onClick={this.toggleDrawer}>
          <div className={"plant-select-title"}>
            <h2>{plant.name}</h2>
            {plant.activeAlarms && <i className={"icon-alarm"} />}
            {plant.activeWarnings && !plant.activeAlarms && (
              <i className={"icon-attention"} />
            )}
          </div>
          <i className={"icon-down-open-big"} />
        </div>
        <Drawer
          open={this.state.visibleDrawer}
          position={"right"}
          toggle={this.toggleDrawer}
        >
          <div className="drawer-header">
            <button className="btt-close" onClick={this.toggleDrawer}>
              <i className={"icon-cancel"} />
            </button>
          </div>

          <div className={"drawer-content"}>
            <h3>{t("changePlant")}</h3>
            <Select
              name="newPlant"
              value={this.state.selectedPlant.id}
              onChange={this.setSelectedPlant}
              options={_options}
              placeholder={"Impianto"}
            />

            <div className="btt-wrapper">
              <Button onClick={this.handleChangePlant} text={t("confirm")} />
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withTranslation("common")(withRouter(PlantSelect));
