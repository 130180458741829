/** @flow */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { withTranslation } from "react-i18next";

import PlantModel from "../models/Plant";

import CommonLayout from "../components/CommonLayout";
import PlantSelect from "../components/PlantSelect";
import MessageBox from "../components/MessageBox";

import DatePicker from "../components/DatePicker";
import Button from "../components/Button";
import ToggleSwitch from "../components/ToggleSwitch";
import Select from "../components/Select";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { Language as TypeLanguage } from "../types/Language";
import type { User as TypeUser } from "../types/User";
import type { Match as TypeMatch } from "../types/ContextRouter";

const MODULE_NAME = "PDM";

type StoresProps = {
  user: TypeUser,
  isCustomer: boolean,
  setCurrentModule: (module: string, plantId?: ?string) => string,
  plants: ?(PlantModel[]),
  plant: ?PlantModel,
  plantsAllowed: PlantModel[],
  sessionLanguage: TypeLanguage,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = {
  match: TypeMatch, //react-router url parameters
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    isCustomer: stores.auth.isCustomer,
    setCurrentModule: stores.auth.setCurrentModule,
    plants: stores.plants.plants,
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    plantsAllowed: stores.auth.plantsAllowed,
    sessionLanguage: stores.auth.sessionLanguage,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class PlantReportPdM extends Component<Props, State> {
  @observable
  isFetchingReport: boolean = false;
  @observable
  fetchError: TypeResultMessage | null;
  @observable
  fromDate: ?Date;
  @observable
  toDate: ?Date;
  @observable
  includeOpen: boolean = false;
  @observable
  includeWiP: boolean = false;
  @observable
  shutdowns: boolean | null;
  @observable
  type: string | null;

  @action
  setFromDate = (date: Date) => {
    this.fromDate = date;
    if (this.toDate && date > this.toDate) {
      this.toDate = null;
    }
  };
  @action
  setToDate = (date: Date) => (this.toDate = date);

  @action
  setIncludeOpen = (e: any) => (this.includeOpen = e.target.value);

  @action
  setIncludeWiP = (e: any) => (this.includeWiP = e.target.value);

  @action
  setShutdowns = (e: any) => (this.shutdowns = e.target.value);

  @action
  setType = (e: any) => (this.type = e.target.value);

  @action
  setFetchError = (data: TypeResultMessage | null) => (this.fetchError = data);

  @computed
  get validForm() {
    return this.fromDate && this.toDate;
  }

  componentDidMount() {
    this.props.setCurrentModule(
      MODULE_NAME,
      this.props.plant ? this.props.plant.id : null
    );
    this.initView();
  }

  initView = () => {
    // Set to default fromDate = first day of the previous month, toDate = last day of the previous month
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
    this.setFromDate(date);
    this.setToDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  };

  fetchReport = async () => {
    const { plant, markSessionAsExpired } = this.props;
    this.isFetchingReport = true;
    this.setFetchError(null);

    if (plant && this.fromDate && this.toDate) {
      const res = await plant.fetchPdMReport(
        this.fromDate,
        this.toDate,
        this.type ? this.type : "",
        this.includeWiP.toString(),
        this.shutdowns ? this.shutdowns.toString() : "",
        this.includeOpen.toString()
      );

      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);
      } else {
        res.data && window.location.assign(res.data.filename);
      }
    }

    this.isFetchingReport = false;
  };

  render() {
    const {
      user,
      plant,
      plantsAllowed,
      t,
      sessionLanguage,
      isCustomer,
      isSessionExpired,
      isOpenSidebarMenu,
      toggleSidebarMenu
    } = this.props;

    const maxDate = new Date(new Date().setDate(new Date().getDate()));

    if (isSessionExpired) return null;

    const optShutdowns = [
      {
        id: "true",
        value: t("yes")
      },
      {
        id: "false",
        value: t("no")
      }
    ];

    const optTypes = [
      {
        id: "ORDINARIO",
        value: t("pdMTypeOrdinary")
      },
      {
        id: "STRAORDINARIO",
        value: t("pdMTypeExtraordinary")
      }
    ];

    return (
      <CommonLayout
        breadcrumb={`${t("report")} ${t("pdm")}`}
        user={user}
        permissions={plant ? plant.permissions : []}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          {plant && <PlantSelect plant={plant} plants={plantsAllowed} />}
        </div>

        <div className={"content lr-pdd"}>
          {this.fetchError && (
            <MessageBox
              /** $FlowFixMe */
              type={this.fetchError.type}
              /** $FlowFixMe */
              message={this.fetchError.message}
            />
          )}

          <form id={"login-form"} noValidate>
            <DatePicker
              label={t("startDate")}
              maxDate={maxDate}
              iconName={"calendar"}
              selected={this.fromDate}
              onChange={this.setFromDate}
              locale={sessionLanguage}
            />
            <DatePicker
              label={t("endDate")}
              minDate={this.fromDate}
              maxDate={maxDate}
              iconName={"calendar"}
              selected={this.toDate}
              onChange={this.setToDate}
              locale={sessionLanguage}
            />
            {!isCustomer && (
              <ToggleSwitch
                onClick={this.setIncludeOpen}
                label={t("includePdMOpen")}
              />
            )}
            {!isCustomer && (
              <ToggleSwitch
                onClick={this.setIncludeWiP}
                label={t("includePdMWiP")}
              />
            )}
            <Select
              name="shutdowns"
              value={this.shutdowns}
              onChange={this.setShutdowns}
              options={optShutdowns}
              emptyOption={true}
              label={t("shutdowns")}
            />
            <Select
              name="type"
              value={this.type}
              onChange={this.setType}
              options={optTypes}
              emptyOption={true}
              label={t("pdMType")}
            />

            <div className={"btn-wrapper"}>
              <Button
                icon={"download-alt"}
                text={t("generateReport")}
                onClick={this.fetchReport}
                disabled={!this.validForm}
                isLoading={this.isFetchingReport}
              />
            </div>
          </form>
        </div>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(PlantReportPdM);
