/** @flow */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";

import MenuItem from "./MenuItem";
import MenuItemDropdown from "./MenuItemDropdown";

import MENU_STRUCTURE from "../config/menu";

import PlantModel from "../models/Plant";

import { isMobile } from "../utils/screen";

import type { Stores } from "../types/Stores";
import type { User as TypeUser } from "../types/User";
import type {
  Match as TypeMatch,
  Location as TypeLocation,
  RouterHistory as TypeRouterHistory
} from "../types/ContextRouter";


type StoresProps = {
  plant: ?PlantModel
};
type OwnProps = {
  user: TypeUser,
  permissions?: string[],
  match: TypeMatch,
  location: TypeLocation,
  history: TypeRouterHistory,
  plantId?: string,
  toggle: () => any
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null
  };
};

@inject(mapStoresToProps)
class MainMenu extends Component<Props, State> {
  isActiveRoute = (itemRoute: string): boolean => {
    const { location } = this.props,
      pathname = location.pathname.substring(1),
      search = location.search; // remove the initial '/' from the pathname

    return pathname.includes(itemRoute) || pathname + search === itemRoute;
  };

  handleNavigateTo = newPath => {
    this.props.history.push(newPath);

    if (isMobile()) {
      this.props.toggle();
    }
  };

  render() {
    const { plant, user, permissions, match, plantId, location } = this.props;
    const _plantId = match.params.plantId || plantId || "";

    const _menu = MENU_STRUCTURE.filter(
      item => {
          let hasPermission = (permissions && permissions.indexOf(item.key) !== -1);
          if(item.exp){
            hasPermission = hasPermission && item.exp(this.props)
          }
          return hasPermission || item.default;
      }
    )
      .map(item => ({
        ...item,
        path: item.path.replace(":plantId", _plantId),
        active: this.isActiveRoute(item.path.replace(":plantId", _plantId))
      }))
      .reduce((items, item) => {
        item.group
          ? items.findIndex(i => i.key === item.group) !== -1
            ? items[items.findIndex(i => i.key === item.group)].children.push(
                item
              )
            : items.push({
                key: item.group,
                label: item.group,
                children: [item]
              })
          : items.push(item);
        return items;
      }, []);

    return (
      <div className={"main-menu-container"}>
        <div className={"main-menu-header"}>
          <div className={"avatar"}>
            <i className={"icon-user"} />
          </div>
          <div className={"user-data"}>{`${user.nome} ${user.cognome}`}</div>
        </div>
        <div className={"main-menu-content"}>
          <ul className={"nav"}>
            {_menu.map((item, idx) =>
              item.children ? (
                <MenuItemDropdown
                  key={idx}
                  data={item}
                  handleClick={this.handleNavigateTo}
                />
              ) : (
                <MenuItem
                  key={idx}
                  data={item}
                  handleClick={this.handleNavigateTo}
                />
              )
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation("menu")(withRouter(MainMenu));
