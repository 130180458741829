/** @flow */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { inject } from "mobx-react";

import type { Stores } from "../types/Stores";

type StoresProps = {
  logout: () => any
};
type OwnProps = {};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  logout: stores.auth.logout
});

@inject(mapStoresToProps)
export default class Logout extends Component<Props, State> {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    return <Redirect to="/login" />;
  }
}
