/** @flow */
import React, { Component } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import PresetItem from "./PresetItem";

// import TagModel from "../models/Tag";
import type { PresetDTO as TypePreset } from "../types/PresetDTO";

type Props = {
  data: TypePreset[],
  onClick: (preset: TypePreset) => any,
  selectedItemId?: ?string
};
type State = {};

@observer
class TagsList extends Component<Props, State> {
  render() {
    const { data, onClick, selectedItemId } = this.props;

    return (
      <div className={"preset-list"}>
        {data.map((preset: TypePreset, idx: number) => (
          <PresetItem
            key={preset.id}
            title={preset.nome}
            description={preset.impianti.join(", ")}
            selected={selectedItemId === preset.id}
            onClick={() => onClick(preset)}
          />
        ))}
      </div>
    );
  }
}

export default TagsList;
