/** @flow */
import React, { Component, Fragment } from "react";

type State = {};
type Props = {
  text?: string,
  icon?: string,
  onClick: any => mixed,
  isLoading?: boolean,
  isSecondary?: boolean
};

class Button extends Component<Props, State> {
  render() {
    const { text, icon, isLoading, isSecondary, ...otherProps } = this.props;
    return (
      <button
        type={"button"}
        className={`btn ${isSecondary ? "secondary-btn" : "primary-btn"}`}
        {...otherProps}
      >
        {!isLoading && (
          <Fragment>
            {" "}
            {icon && <i className={`icon-${icon}`} />} {text}
          </Fragment>
        )}
        {isLoading && <i className="icon-spin animate-spin" />}
      </button>
    );
  }
}

export default Button;
