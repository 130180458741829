/** @flow */
import { observable } from "mobx";
import api from "../services/api";
import i18nService from "../services/i18n";
import type { PlantStatistics as TypePlantStatistics } from "../types/PlantStatistics";
import utilsText from "../utils/text";
import utilsDateTime from "../utils/dateTime";
import { saveAs } from "../utils/fileSaver";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { Indicator as TypeIndicator } from "../types/Indicator";
import type { TagDTO as TypeTagDTO } from "../types/TagDTO";
import type { LastValueDTO as TypeLastValueDTO } from "../types/LastValueDTO";
import type { TrendPointDTO as TypeTrendPointDTO } from "../types/TrendPointDTO";
import type { ChartDot as TypeChartDot } from "../types/ChartDot";
import type { ProduzioneDTO as TypeProduzioneDTO } from "../types/ProduzioneDTO";
import type { AllarmeDTO as TypeAllarmeDTO } from "../types/AllarmeDTO";
import type { ClasseAllarmeDTO as TypeClasseAllarmeDTO } from "../types/ClasseAllarmeDTO";
import type { ForecastDTO as TypeForecastDTO } from "../types/ForecastDTO";
import type { ForecastTrendDTO as TypeForecastTrendDTO } from "../types/ForecastTrendDTO";
import type { IndicatoriDTO as TypeIndicatoriDTO } from "../types/IndicatoriDTO";
import type { DisponibilitaDTO as TypeDisponibilitaDTO } from "../types/DisponibilitaDTO";
import type { PRDTO as TypePRDTO } from "../types/PRDTO";

import AlarmModel from "./Alarm";
import TagModel from "./Tag";

export default class PlantModel {
  @observable
  id: string;
  @observable
  code: string;
  @observable
  name: string;
  @observable
  type: string;
  @observable
  permissions: string[];
  @observable
  statistics: TypePlantStatistics;
  @observable
  tags: TagModel[];
  @observable
  trendTags: TagModel[];
  @observable
  annualProductions: {
    [year: number]: {
      maxProduction: number,
      totalProduction: number,
      productions: TypeIndicator[],
    },
  };
  @observable
  monthlyProductions: {
    [yearMonth: string]: {
      maxProduction: number,
      totalProduction: number,
      productions: TypeIndicator[],
    },
  };
  @observable
  alarms: AlarmModel[];
  @observable
  activeAlarms: ?boolean;
  @observable
  activeWarnings: ?boolean;
  @observable
  annualForecasts: {
    [year: number]: {
      totalEnergy: number,
      totalExptectedEnergy: number,
      forecasts: TypeIndicator[],
    },
  };
  @observable
  monthlyForecasts: {
    [yearMonth: string]: {
      totalEnergy: number,
      totalExptectedEnergy: number,
      forecasts: TypeIndicator[],
    },
  };
  @observable
  dailyForecasts: {
    [yearMonthDay: string]: {
      energyTrend: TypeChartDot[],
      energyForecastTrend: TypeChartDot[],
      powerTrend: TypeChartDot[],
      powerForecastTrend: TypeChartDot[],
    },
  };
  @observable
  annualIndicators: {
    [year: number]: {
      totalProduction: number,
      totalEqHours: number,
      ratioHours: number,
      indicators: TypeIndicator[],
    },
  };
  @observable
  annualDisponibility: {
    [year: number]: {
      maxDisponibility: number,
      maxFailureDisponibility: number,
      maxFailSchedDisponibility: number,
      indicators: TypeIndicator[],
    },
  };
  @observable
  annualPerfomanceRatio: {
    [year: number]: {
      maxValue: number,
      indicators: TypeIndicator[],
    },
  };
  @observable
  monthlyPerfomanceRatio: {
    [yearMonth: string]: {
      maxValue: number,
      indicators: TypeIndicator[],
    },
  };

  constructor(
    id: string,
    code: string,
    name: string,
    type: string,
    permissions: string[]
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.type = type;
    this.permissions = permissions;
    this.tags = [];
    this.trendTags = [];
    this.annualProductions = {};
    this.monthlyProductions = {};
    this.alarms = [];
    this.annualForecasts = {};
    this.monthlyForecasts = {};
    this.dailyForecasts = {};
    this.annualIndicators = {};
    this.annualDisponibility = {};
    this.annualPerfomanceRatio = {};
    this.monthlyPerfomanceRatio = {};
  }

  _loadCurrentPowerTag = async (): Promise<TagModel> => {
    const res = await api.getPotenzaAttuale(this.id);

    if (!res.success) {
      throw new Error(res.error);
    }

    // map current power to Tag structure and set the Id equal to plant Id (required by the API)
    return new TagModel(
      this.id,
      i18nService.t("common:totalPower"),
      "CURRENT_POWER",
      i18nService.t("common:mu:kw"),
      res.result,
      utilsText.formatNumber(res.result),
      true // fixed value for 'trend' because this Tag must be visible on Trend filter
    );
  };

  _loadTags = async (trendModuleEnabled: boolean): Promise<TagModel[]> => {
    //const currPowerTag = await this._loadCurrentPowerTag();

    const remoteTags = await api.getListaTags(this.id);

    if (!remoteTags.success) {
      throw new Error(remoteTags.error);
    }

    const remoteTagsValues = await api.getListaValoriTags(this.id);

    if (!remoteTagsValues.success) {
      throw new Error(remoteTagsValues.error);
    }

    const tags: TypeTagDTO[] = remoteTags.result,
      tagsValues: TypeLastValueDTO[] = remoteTagsValues.result;

    const tagsMerged: TagModel[] = tagsValues.map((tagVal) => {
      const tag: TypeTagDTO | null =
        tags.find((tag) => tag.id === tagVal.idTag) || null;
      let _tagModel = {};
      if (tag) {
        _tagModel = new TagModel(
          tag.id,
          tag.descrizione,
          "TAG",
          tag.unitaMisura,
          tagVal.value,
          tagVal.formattedValue,
          tag.trend && trendModuleEnabled,
          tag.valoreMinimo,
          tag.valoreMassimo
        );
      }
      // $FlowFixMe
      return _tagModel;
    });

    //const currPowerTagAnag: ?TypeTagDTO = tags
    //  ? tags.find((tag) => tag.potenzaTotale)
    //  : null;

    //if (currPowerTagAnag) {
    //  currPowerTag.minValue = currPowerTagAnag.valoreMinimo;
    //  currPowerTag.maxValue = currPowerTagAnag.valoreMassimo;
    //}

    //tagsMerged.unshift(currPowerTag);

    return tagsMerged;
  }; //_loadTags

  setStatistics(statistics: TypePlantStatistics) {
    this.statistics = statistics;
  }

  async fetchMeta(): Promise<TypeResultMessage> {
    let result = { success: true };

    if (typeof this.activeAlarms === "undefined") {
      try {
        const remoteData = await api.getControlloAllarmi(this.id);

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        this.activeAlarms = remoteData.result;
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    if (typeof this.activeWarnings === "undefined") {
      try {
        const remoteData = await api.getControlloWarnings(this.id);

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        this.activeWarnings = remoteData.result;
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  } // fetchMeta

  async fetchTags(
    trendModuleEnabled: boolean = true
  ): Promise<TypeResultMessage> {
    let result = { success: true };
    try {
      this.tags = await this._loadTags(trendModuleEnabled);
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError"),
      };
    }

    return result;
  } // fetchTags

  async fetchTrendTags(): Promise<TypeResultMessage> {
    let result = { success: true };
    if (this.trendTags.length === 0) {
      try {
        let remoteTags = await api.getListaTags(this.id);

        if (!remoteTags.success) {
          throw new Error(remoteTags.error);
        }
        const tags: TypeTagDTO[] = remoteTags.result;

        this.trendTags = tags
          .filter((tag) => tag.trend)
          .map(
            (tag) =>
              new TagModel(
                tag.id,
                tag.descrizione,
                tag.tipo,
                tag.unitaMisura,
                0,
                "",
                tag.trend,
                tag.valoreMinimo,
                tag.valoreMassimo
              )
          );
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  } // fetchTrendTags

  async fetchAnnualProductions(year: number): Promise<TypeResultMessage> {
    let result = { success: true };

    if (!this.annualProductions[year]) {
      try {
        let remoteData = await api.getProduzioniListaMensileImpianto(
          this.id,
          year
        );

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _productions: TypeProduzioneDTO[] = remoteData.result;

        // If current year, limit data until the current month
        const _date = new Date();
        if (year === _date.getFullYear()) {
          _productions = _productions.filter(
            (prod) => prod.data <= _date.getMonth() + 1
          );
        }

        const maxProduction = _productions.reduce(
          (result: number, production) =>
            production.valoreMassimo > result
              ? production.valoreMassimo
              : result,
          0
        );

        const totalProduction = _productions.reduce(
          (result: number, production) => production.valore + result,
          0
        );

        // map Productions to Indicators
        this.annualProductions[year] = {
          maxProduction,
          totalProduction,
          productions: _productions.map((prod) => ({
            key: prod.data.toString(),
            label: i18nService.t(`common:calendar:months:${prod.data}`),
            samples: [
              {
                label: i18nService.t(`common:kWhValue`, {
                  value: utilsText.formatNumber(prod.valore),
                }),
                value: prod.valore,
                max: maxProduction,
              },
            ],
          })),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  }

  async fetchMonthlyProductions(
    year: number,
    month: number
  ): Promise<TypeResultMessage> {
    let result = { success: true };
    const _key = `${year}${month}`;

    if (!this.monthlyProductions[_key]) {
      try {
        const _cDate = new Date(),
          _cDay = _cDate.getDate(),
          _cMonth = _cDate.getMonth() + 1,
          _cYear = _cDate.getFullYear();

        let remoteData = await api.getProduzioniListaGiornalieraImpianto(
          this.id,
          year,
          month
        );

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _productions: TypeProduzioneDTO[] = remoteData.result;

        // Only for the current month-year, limit data until the current day
        if (month == _cMonth && year == _cYear) {
          _productions = _productions.filter((prod) => prod.data <= _cDay);
        }

        const maxProduction = _productions.reduce(
          (result: number, production) =>
            production.valoreMassimo > result
              ? production.valoreMassimo
              : result,
          0
        );

        const totalProduction = _productions.reduce(
          (result: number, production) => production.valore + result,
          0
        );

        // map Productions to Indicators
        this.monthlyProductions[_key] = {
          maxProduction,
          totalProduction,
          productions: _productions.map((prod) => {
            const date = new Date(year, month - 1, prod.data);
            return {
              key: prod.data.toString(),
              label: `${i18nService.t(
                `common:calendar:days:${date.getDay()}`
              )} ${prod.data}`,
              samples: [
                {
                  label: i18nService.t(`common:kWhValue`, {
                    value: utilsText.formatNumber(prod.valore),
                  }),
                  value: prod.valore,
                  max: maxProduction,
                },
              ],
            };
          }),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  }

  async fetchAlarms(): Promise<TypeResultMessage> {
    let result = { success: true };
    this.alarms = [];

    try {
      const resLCA = await api.getListaClassiAllarmi();

      if (!resLCA.success) {
        throw new Error(resLCA.error);
      }

      const remoteAlarmClasses: TypeClasseAllarmeDTO[] = resLCA.result;

      const resLAI = await api.getListaAllarmiImpianto(this.id);

      if (!resLAI.success) {
        throw new Error(resLAI.error);
      }

      const remoteAlarms: TypeAllarmeDTO[] = resLAI.result;

      this.alarms = remoteAlarms.map((a) => {
        const alarmClass = remoteAlarmClasses.find(
          (aC) => aC.nome === a.classe
        );
        return new AlarmModel(
          a.id,
          a.classe,
          a.descrizione,
          a.oraAck,
          a.oraIngresso,
          a.oraUscita,
          alarmClass ? alarmClass.colore : ""
        );
      });
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError"),
      };
    }

    return result;
  } // fetchAlarms

  async fetchShutdownReport(
    fromDate: Date,
    toDate: Date
  ): Promise<TypeResultMessage> {
    let result = { success: true };

    const fromDateISO8601 = utilsDateTime.getDateFormatISO8601(fromDate),
      toDateISO8601 = utilsDateTime.getDateFormatISO8601(toDate);

    const res = await api.getShutdownReport(
      this.id,
      fromDateISO8601,
      toDateISO8601
    );

    if (!res) {
      result = {
        success: false,
        type: "err",
        error: res.error,
        message: i18nService.t("common:reportError"),
      };
    } else {
      result = {
        success: true,
        data: res.result,
      };
    }

    return result;
  }

  async fetchPdMReport(
    fromDate: Date,
    toDate: Date,
    type?: string,
    wip: string,
    shutdowns: string,
    open: string
  ): Promise<TypeResultMessage> {
    let result = { success: true };

    const fromDateISO8601 = utilsDateTime.getDateFormatISO8601(fromDate),
      toDateISO8601 = utilsDateTime.getDateFormatISO8601(toDate);

    const res = await api.getPdmReport(
      this.id,
      fromDateISO8601,
      toDateISO8601,
      type,
      wip,
      shutdowns,
      open
    );

    if (!res) {
      result = {
        success: false,
        type: "err",
        error: res.error,
        message: i18nService.t("common:reportError"),
      };
    } else {
      result = {
        success: true,
        data: res.result,
      };
    }

    return result;
  }

  async fetchIndicatorsReport(year: number): Promise<TypeResultMessage> {
    let result = { success: true };

    const res = await api.getIndicatorsReport(this.id, year);

    if (!res) {
      result = {
        success: false,
        type: "err",
        error: res.error,
        message: i18nService.t("common:reportError"),
      };
    } else {
      result = {
        success: true,
        data: res.result,
      };
    }

    return result;
  }

  async fetchMonthlyReport(
    year: number,
    month: number
  ): Promise<TypeResultMessage> {
    let result = { success: true };

    const res = await api.getMonthlyReport(this.id, year, month);

    if (!res) {
      result = {
        success: false,
        type: "err",
        error: res.error,
        message: i18nService.t("common:reportError"),
      };
    } else {
      result = {
        success: true,
        data: res.result,
      };
    }

    return result;
  }

  async fetchAnnualForecasts(year: number): Promise<TypeResultMessage> {
    let result = { success: true };

    if (!this.annualForecasts[year]) {
      try {
        let remoteData = await api.getForecastListaMensile(this.id, year);

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _forecasts: TypeForecastDTO[] = remoteData.result;

        // If current year, limit data until the current month
        const _date = new Date();
        if (year === _date.getFullYear()) {
          _forecasts = _forecasts.filter((f) => f.date <= _date.getMonth() + 1);
        }

        const _totalEnergy = _forecasts.reduce(
          (result: number, forecast) => forecast.energia + result,
          0
        );

        const _totalExptectedEnergy = _forecasts.reduce(
          (result: number, forecast) => forecast.forecastEnergia + result,
          0
        );

        const _maxEnergy = _forecasts.reduce(
          (result: number, forecast) =>
            forecast.energiaMassima > result ? forecast.energiaMassima : result,
          0
        );

        const _maxExptectedEnergy = _forecasts.reduce(
          (result: number, forecast) =>
            forecast.forecastEnergia > result
              ? forecast.forecastEnergia
              : result,
          0
        );

        // map Forecasts to Indicators
        this.annualForecasts[year] = {
          totalEnergy: _totalEnergy,
          totalExptectedEnergy: _totalExptectedEnergy,
          forecasts: _forecasts.map((f) => ({
            key: f.date.toString(),
            label: i18nService.t(`common:calendar:months:${f.date}`),
            samples: [
              {
                label: i18nService.t(`common:kWValue`, {
                  value: utilsText.formatNumber(f.energia),
                }),
                value: f.energia,
                max: _maxEnergy,
              },
              {
                label: i18nService.t(`common:kWValue`, {
                  value: utilsText.formatNumber(f.forecastEnergia),
                }),
                value: f.forecastEnergia,
                max: _maxExptectedEnergy,
              },
            ],
          })),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  }

  async fetchMonthlyForecasts(
    year: number,
    month: number
  ): Promise<TypeResultMessage> {
    let result = { success: true };
    const _key = `${year}${month}`;

    if (!this.monthlyForecasts[_key]) {
      try {
        const _cDate = new Date(),
          _cDay = _cDate.getDate(),
          _cMonth = _cDate.getMonth() + 1,
          _cYear = _cDate.getFullYear();

        let remoteData = await api.getForecastListaGiornaliera(
          this.id,
          year,
          month
        );

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _forecasts: TypeForecastDTO[] = remoteData.result;

        // Only for the current month-year, limit data until the current day
        if (month == _cMonth && year == _cYear) {
          _forecasts = _forecasts.filter((f) => f.date <= _cDay);
        }

        const _totalEnergy = _forecasts.reduce(
          (result: number, forecast) => forecast.energia + result,
          0
        );

        const _totalExptectedEnergy = _forecasts.reduce(
          (result: number, forecast) => forecast.forecastEnergia + result,
          0
        );

        const _maxEnergy = _forecasts.reduce(
          (result: number, forecast) =>
            forecast.energiaMassima > result ? forecast.energiaMassima : result,
          0
        );

        const _maxExptectedEnergy = _forecasts.reduce(
          (result: number, forecast) =>
            forecast.forecastEnergia > result
              ? forecast.forecastEnergia
              : result,
          0
        );

        // map Forecasts to Indicators
        this.monthlyForecasts[_key] = {
          totalEnergy: _totalEnergy,
          totalExptectedEnergy: _totalExptectedEnergy,
          forecasts: _forecasts.map((f) => {
            const date = new Date(year, month - 1, f.date);
            return {
              key: f.date.toString(),
              label: `${i18nService.t(
                `common:calendar:days:${date.getDay()}`
              )} ${f.date}`,
              samples: [
                {
                  label: i18nService.t(`common:kWValue`, {
                    value: utilsText.formatNumber(f.energia),
                  }),
                  value: f.energia,
                  max: _maxEnergy,
                },
                {
                  label: i18nService.t(`common:kWValue`, {
                    value: utilsText.formatNumber(f.forecastEnergia),
                  }),
                  value: f.forecastEnergia,
                  max: _maxExptectedEnergy,
                },
              ],
            };
          }),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  }

  async fetchDailyTrendForecast(
    year: number,
    month: number,
    day: number
  ): Promise<TypeResultMessage> {
    let result = { success: true };
    const _key = `${year}${month}${day}`;

    if (!this.dailyForecasts[_key]) {
      try {
        const remoteData = await api.getForecastTrendGiornalieri(
          this.id,
          year,
          month,
          day
        );

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        const _forecastTrends: TypeForecastTrendDTO = remoteData.result;

        this.dailyForecasts[_key] = {
          energyTrend: _forecastTrends.trendEnergia
            .filter((tE) => tE.value != null)
            .map((tE) => ({
              x: tE.timestamp,
              y: tE.value,
            })),
          energyForecastTrend: _forecastTrends.trendForecastEnergia
            .filter((tE) => tE.value != null)
            .map((tE) => ({
              x: tE.timestamp,
              y: tE.value,
            })),
          powerTrend: _forecastTrends.trendPotenza
            .filter((tE) => tE.value != null)
            .map((tE) => ({
              x: tE.timestamp,
              y: tE.value,
            })),
          powerForecastTrend: _forecastTrends.trendForecastPotenza
            .filter((tE) => tE.value != null)
            .map((tE) => ({
              x: tE.timestamp,
              y: tE.value,
            })),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  }

  async fetchAnnualIndicators(year: number): Promise<TypeResultMessage> {
    let result = { success: true };

    if (!this.annualIndicators[year]) {
      try {
        const remoteData = await api.getIndicatoriMensili(this.id, year);

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _mIndicators: TypeIndicatoriDTO[] = remoteData.result;

        // If current year, limit data until the current month
        const _date = new Date();
        let yearHours = 8760; // 365 days x 24 h

        if (year === _date.getFullYear()) {
          _mIndicators = _mIndicators.filter(
            (i) => i.mese <= _date.getMonth() + 1
          );

          yearHours = utilsDateTime.getYearHours(
            new Date(_date.getFullYear(), _date.getMonth(), _date.getDate())
          );
        }

        const totalProduction = _mIndicators.reduce(
          (result: number, indicator) => indicator.produzione + result,
          0
        );

        const totalEqHours = _mIndicators.reduce(
          (result: number, indicator) => indicator.oreEquivalenti + result,
          0
        );

        const ratioHours = (totalEqHours / yearHours) * 100;

        const _maxProduction = _mIndicators.reduce(
          (result: number, indicator) =>
            indicator.produzione > result ? indicator.produzione : result,
          0
        );

        const _maxEqHours = _mIndicators.reduce(
          (result: number, indicator) =>
            indicator.oreEquivalenti > result
              ? indicator.oreEquivalenti
              : result,
          0
        );

        // map Monthly Indicators to Indicators Obj
        this.annualIndicators[year] = {
          totalProduction,
          totalEqHours,
          ratioHours,
          indicators: _mIndicators.map((i) => ({
            key: i.mese.toString(),
            label: i18nService.t(`common:calendar:months:${i.mese}`),
            samples: [
              {
                label: i18nService.t(`common:kWhValue`, {
                  value: utilsText.formatNumber(i.produzione),
                }),
                value: i.produzione,
                max: _maxProduction,
              },
              {
                label: i18nService.t(`common:hValue`, {
                  value: utilsText.formatNumber(i.oreEquivalenti),
                }),
                value: i.oreEquivalenti,
                max: _maxEqHours,
              },
              {
                label: i18nService.t(`common:percValue`, {
                  value: utilsText.formatNumber(i.fattoreCapacita),
                }),
                value: i.fattoreCapacita,
                max: 100,
              },
            ],
          })),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  } // fetchAnnualIndicators

  async fetchAnnualDisponibility(year: number): Promise<TypeResultMessage> {
    let result = { success: true };

    if (!this.annualDisponibility[year]) {
      try {
        const remoteData = await api.getDisponibilitaMensili(this.id, year);

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _dIndicators: TypeDisponibilitaDTO[] = remoteData.result;

        // If current year, limit data until the current month
        const _date = new Date();
        if (year === _date.getFullYear()) {
          _dIndicators = _dIndicators.filter(
            (i) => i.mese <= _date.getMonth() + 1
          );
        }

        const maxDisponibility = 1,
          maxFailureDisponibility = 1,
          maxFailSchedDisponibility = 1;

        // map Monthly Disponibility to Indicators Obj
        this.annualDisponibility[year] = {
          maxDisponibility,
          maxFailureDisponibility,
          maxFailSchedDisponibility,
          indicators: _dIndicators.map((i) => ({
            key: i.mese.toString(),
            label: i18nService.t(`common:calendar:months:${i.mese}`),
            samples: [
              {
                label: i18nService.t(`common:percValue`, {
                  value: utilsText.formatNumber(i.disponibilitaSuGuasti * 100),
                }),
                value: i.disponibilitaSuGuasti,
                max: maxFailureDisponibility,
              },
              {
                label: i18nService.t(`common:percValue`, {
                  value: utilsText.formatNumber(
                    i.disponibilitaSuGuastiEProgrammati * 100
                  ),
                }),
                value: i.disponibilitaSuGuastiEProgrammati,
                max: maxFailSchedDisponibility,
              },
              {
                label: i18nService.t(`common:percValue`, {
                  value: utilsText.formatNumber(i.disponibilita * 100),
                }),
                value: i.disponibilita,
                max: maxDisponibility,
              },
            ],
          })),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  } // fetchAnnualDisponibility

  async fetchAnnualPerformanceRatio(year: number): Promise<TypeResultMessage> {
    let result = { success: true };

    if (!this.annualPerfomanceRatio[year]) {
      try {
        const remoteData = await api.getPRMensili(this.id, year);

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _prIndicators: TypePRDTO[] = remoteData.result;

        // If current year, limit data until the current month
        const _date = new Date();
        if (year === _date.getFullYear()) {
          _prIndicators = _prIndicators.filter(
            (i) => i.data <= _date.getMonth() + 1
          );
        }

        const maxValue = 1;

        // map Monthly Performace Ratio to Indicators Obj
        this.annualPerfomanceRatio[year] = {
          maxValue,
          indicators: _prIndicators.map((i) => ({
            key: i.data.toString(),
            label: i18nService.t(`common:calendar:months:${i.data}`),
            samples: [
              {
                label: i18nService.t(`common:percValue`, {
                  value: utilsText.formatNumber(i.valore * 100),
                }),
                value: i.valore,
                max: maxValue,
                valid: i.valido,
              },
            ],
          })),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  } // fetchAnnualPerformanceRatio

  async fetchMonthlyPerformanceRatio(
    year: number,
    month: number
  ): Promise<TypeResultMessage> {
    let result = { success: true };
    const _key = `${year}${month}`;

    if (!this.monthlyPerfomanceRatio[_key]) {
      try {
        const _cDate = new Date(),
          _cDay = _cDate.getDate(),
          _cMonth = _cDate.getMonth() + 1,
          _cYear = _cDate.getFullYear();

        let remoteData = await api.getPRGiornalieri(this.id, year, month);

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        let _prIndicators: TypePRDTO[] = remoteData.result;

        // Only for the current month-year, limit data until the current day
        if (month == _cMonth && year == _cYear) {
          _prIndicators = _prIndicators.filter((prod) => prod.data <= _cDay);
        }

        const maxValue = 1;

        // map Daily Performace Ratio to Indicators
        this.monthlyPerfomanceRatio[_key] = {
          maxValue,
          indicators: _prIndicators.map((i) => {
            const date = new Date(year, month - 1, i.data);
            return {
              key: i.data.toString(),
              label: `${i18nService.t(
                `common:calendar:days:${date.getDay()}`
              )} ${i.data}`,
              samples: [
                {
                  label: i18nService.t(`common:percValue`, {
                    value: utilsText.formatNumber(i.valore * 100),
                  }),
                  value: i.valore,
                  max: maxValue,
                  valid: i.valido,
                },
              ],
            };
          }),
        };
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError"),
        };
      }
    }

    return result;
  }
}
