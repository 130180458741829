/** @flow */
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import type { CustomRoute as RouteType } from "../types/CustomRoute";
import type { Stores } from "../types/Stores";

type StoresProps = {
  isLoggedIn: boolean
};
type OwnProps = {};
type Props = StoresProps & RouteType & OwnProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  isLoggedIn: stores.auth.isLoggedIn
});

const PrivateRoute = inject(mapStoresToProps)(
  observer((props: Props) => {
    return (
      <Route
        exact
        path={props.path}
        render={renderProps =>
          props.isLoggedIn ? (
            <props.component {...renderProps} routes={props.routes} />
          ) : (
            <Redirect
              to={{
                pathname: "/login"
              }}
            />
          )
        }
      />
    );
  })
);

const CustomRoute = (route: RouteType) => {
  let _route;

  if (route.private || route.authorization) {
    _route = (
      <PrivateRoute
        path={route.path}
        component={route.component}
        routes={route.routes}
        authorization={route.authorization}
      />
    );
  } else {
    _route = (
      <Route
        path={route.path}
        render={props => <route.component {...props} routes={route} />}
      />
    );
  }

  return _route;
};

export default CustomRoute;
