/** @flow */
import React from "react";
import { withTranslation } from "react-i18next";

import type { Translate, I18n } from "../types/I18n";

type Props = {
  t: Translate,
  styleClass?: string,
  value?: number,
  max?: number,
  percentage?: number,
  label?: string,
  valid?: boolean
};

const getWidthPerc = (value?: number, max?: number, percentage?: number) => {
  let _perc = percentage || 0;

  if (value && max) {
    _perc = (value / max) * 100;
  }
  return `${_perc}%`;
};

const ProgressBar = (props: Props) => {
  const { t, styleClass, value, max, percentage, label, valid } = props;

  if (typeof valid !== "undefined" && !valid) {
    return <div className={"pbar-not-valid"}>{t("notAvailable")}</div>;
  }

  let widthPerc = 0;

  return (
    <div className={styleClass ? `pbar ${styleClass}` : "pbar"}>
      <span
        className="pbar-value"
        style={{ width: getWidthPerc(value, max, percentage) }}
      />
      {label && <span className={"pbar-lbl"}>{label}</span>}
    </div>
  );
};

export default withTranslation("common")(ProgressBar);
