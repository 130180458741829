/** @flow */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { toJS } from "mobx";
import { isEqual } from "lodash";

import chartConfig from "../config/chart";

import "../../node_modules/react-vis/dist/style.css";
import {
  FlexibleXYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  Hint,
  Crosshair,
  DiscreteColorLegend
} from "react-vis";

import utilsDateTime from "../utils/dateTime";

import type { Translate, I18n } from "../types/I18n";
import type { ChartDot as TypeChartDot } from "../types/ChartDot";
import type { ChartSerie as TypeChartSerie } from "../types/ChartSerie";

const GRID_TICK_NUMBER = 5;

type Props = {
  t: Translate,
  i18n: I18n,
  isLoading?: boolean,
  showLegend?: boolean,
  showHorizontalGridLines?: boolean,
  dontCheckIfEmpty?: boolean,
  xTitle: string,
  yTitle: string,
  ySecondaryTitle?: string,
  series: TypeChartSerie[],
  secondarySeries?: TypeChartSerie[],
  xTickValues?: any[]
};
type State = {
  crosshairValues: any[],
  crossHairIndex: ?number
};

class XYChart extends Component<Props, State> {
  state = {
    crosshairValues: [],
    crossHairIndex: null
  };

  get legend() {
    return this.props.series.map(serie => ({
      title: serie.title,
      color: serie.color || chartConfig.colors.primary
    }));
  }

  get isEmpty() {
    const { series } = this.props;

    return (
      !series ||
      (series && series.length === 0) ||
      series.reduce((prev, curr) => curr.data.length === 0 && prev, true)
    );
  }

  get yDomain() {
    return this._getSeriesDomain(this.props.series);
  }

  get yDomainSecondary() {
    const { secondarySeries } = this.props;

    if (!secondarySeries) {
      return [];
    }

    return this._getSeriesDomain(secondarySeries);
  }

  get yTicks() {
    const yDomain = this.yDomain;

    if (yDomain.length === 0) {
      return [];
    }

    let gap = (yDomain[1] - yDomain[0]) / (GRID_TICK_NUMBER - 1);

    if (gap > 1) {
      gap = parseInt(gap.toFixed());
    }

    const yTicks = [yDomain[0]];

    for (let i = 1; i <= GRID_TICK_NUMBER - 1; i++) {
      yTicks.push(yDomain[0] + gap * i);
    }

    return yTicks;
  }

  get ySecondaryTicks() {
    const yDomain = this.yDomainSecondary;

    if (yDomain.length === 0) {
      return [];
    }

    let gap = (yDomain[1] - yDomain[0]) / (GRID_TICK_NUMBER - 1);

    if (gap > 1) {
      gap = parseInt(gap.toFixed());
    }

    const yTicks = [yDomain[0]];

    for (let i = 1; i <= GRID_TICK_NUMBER - 1; i++) {
      yTicks.push(yDomain[0] + gap * i);
    }

    return yTicks;
  }

  _getSeriesDomain = (series: TypeChartSerie[]) => {
    if (this.isEmpty) {
      return [];
    }

    const maxVal = series.reduce((prev, curr) => {
      const currSerieMaxVal = curr.data.reduce((prev, curr) => {
        return curr.y > prev ? curr.y : prev;
      }, 0);
      return Math.max(
        currSerieMaxVal,
        prev,
        curr.yDomain ? curr.yDomain.max : 0
      );
    }, 0);

    const minVal = series.reduce((prev, curr) => {
      const currSerieMinVal = curr.data.reduce((prev, curr) => {
        return curr.y < prev ? curr.y : prev;
      }, 999999999);
      return Math.min(
        currSerieMinVal,
        prev,
        curr.yDomain ? curr.yDomain.min : 999999999
      );
    }, 999999999);

    const delta = maxVal - minVal;

    let yDomainMax = Math.ceil(maxVal),
      yDomainMin = minVal > 1 ? Math.floor(minVal) - 0.5 : minVal;

    if (delta > 1) {
      yDomainMax = parseInt(yDomainMax.toFixed(0));
      yDomainMin = parseInt(yDomainMin.toFixed(0));
    }

    return [yDomainMin, yDomainMax];
  };

  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  _onNearestX = (chartDot: TypeChartDot, { index }: any) => {
    let primarySeriesNearestDots = this.props.series.map((serie, index) => {
      let dotMatch = serie.data.find(dot => dot.x === chartDot.x);

      if (dotMatch) {
        return {
          x: dotMatch.x,
          y: dotMatch.y,
          serie
        };
      }
    });

    let secondarySeriesNearestDots = [];
    if (
      this.props.secondarySeries &&
      this.props.secondarySeries.length > 0 &&
      this.props.secondarySeries[0].data.length > 0
    ) {
      try {
        secondarySeriesNearestDots = this.props.secondarySeries.map(serie => {
          let dotMatch = serie.data.find(dot => dot.x === chartDot.x);

          if (dotMatch) {
            return {
              x: dotMatch.x,
              y: dotMatch.y,
              serie
            };
          }
        });
      } catch (e) {}

      if (primarySeriesNearestDots[0] === undefined) {
        primarySeriesNearestDots = [];
      }
    }

    this.setState({
      crosshairValues: [
        ...primarySeriesNearestDots,
        ...secondarySeriesNearestDots
      ],
      crossHairIndex: index
    });
  };

  _mapCrosshairRow = (row: any, index: number) => {
    return (
      <div className={"chart--crosshair-row"} key={index}>
        <div style={{ color: row.serie.color || chartConfig.colors.primary }}>
          {parseFloat(row.y.toFixed(2)).toLocaleString()} {row.serie.unit}
        </div>
      </div>
    );
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.series, this.props.series)) {
      this.setState({ crosshairValues: [], crossHairIndex: null });
    }
  }

  render() {
    const {
      isLoading,
      t,
      showHorizontalGridLines = true,
      showLegend,
      dontCheckIfEmpty = false,
      xTitle,
      yTitle,
      ySecondaryTitle,
      xTickValues = utilsDateTime.getCurrentDayHours(),
      series,
      secondarySeries
    } = this.props;

    return (
      <div className={"chart-container"}>
        {!this.isEmpty && (
          <FlexibleXYPlot
            yDomain={this.yDomain}
            margin={{ top: 10, right: secondarySeries ? 60 : 0, left: 60 }}
            dontCheckIfEmpty={dontCheckIfEmpty}
            onMouseLeave={this._onMouseLeave}
          >
            {showHorizontalGridLines && (
              <HorizontalGridLines tickValues={this.yTicks} />
            )}

            <XAxis
              title={xTitle}
              tickFormat={v =>
                `${
                  new Date(v).getHours() < 10
                    ? `0${new Date(v).getHours()}`
                    : new Date(v).getHours()
                }`
              }
              tickValues={xTickValues}
            />

            <YAxis
              orientation={"left"}
              tickValues={this.yTicks}
              title={yTitle}
              tickSize={0}
              tickFormat={v => parseFloat(v.toFixed(2)).toLocaleString()}
            />

            {!isLoading &&
              series.map((serie, idx) => (
                <LineSeries
                  key={idx}
                  animation
                  data={serie.data}
                  color={serie.color || chartConfig.colors.primary}
                  onNearestX={this._onNearestX}
                />
              ))}
            {this.state.crosshairValues[0] && (
              <Crosshair values={this.state.crosshairValues}>
                <div className={"chart--crosshair"}>
                  <p className={"chart--crosshair-title"}>
                    {t("hour")}:
                    {this.state.crosshairValues[0].x
                      ? utilsDateTime.formatHoursMinutes(
                          new Date(this.state.crosshairValues[0].x)
                        )
                      : ""}
                  </p>
                  {this.state.crosshairValues.map(this._mapCrosshairRow)}
                </div>
              </Crosshair>
            )}

            {showLegend && (
              <DiscreteColorLegend
                items={this.legend}
                orientation={"horizontal"}
              />
            )}
          </FlexibleXYPlot>
        )}

        {!this.isEmpty &&
          secondarySeries &&
          secondarySeries.length > 0 &&
          secondarySeries[0].data.length > 0 && (
            <div className={"secondary-chart"}>
              <FlexibleXYPlot
                yDomain={this.yDomainSecondary}
                margin={{ right: secondarySeries ? 60 : 0, left: 60 }}
                onMouseLeave={this._onMouseLeave}
              >
                <YAxis
                  orientation={"right"}
                  tickValues={this.ySecondaryTicks}
                  title={ySecondaryTitle}
                  tickSize={0}
                  tickFormat={v => parseFloat(v.toFixed(2)).toLocaleString()}
                />

                {secondarySeries.map((serie, index) => {
                  return (
                    <LineSeries
                      animation
                      key={serie.color}
                      data={serie.data}
                      color={serie.color}
                      onNearestX={this._onNearestX}
                    />
                  );
                })}
              </FlexibleXYPlot>
            </div>
          )}

        {isLoading && (
          <span className={"chart-loader"}>
            <i className={"icon-spin animate-spin"} />
          </span>
        )}
        {this.isEmpty && !isLoading && !dontCheckIfEmpty && (
          <span className="chart-msg">{t("noData")}</span>
        )}
      </div>
    );
  }
}

export default withTranslation("common")(XYChart);
