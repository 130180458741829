/** @flow */
import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { Redirect, Link } from "react-router-dom";

import { withTranslation } from "react-i18next";
import type { Translate, I18n } from "../types/I18n";
import type { Stores } from "../types/Stores";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

import Logo from "../components/Logo";
import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import MessageBox from "../components/MessageBox";

import timezones from "../config/timezones";
import utilTimezone from "../utils/timezone";

type StoresProps = {
  login: (
    username: string,
    password: string,
    timezone: string
  ) => Promise<TypeResultMessage>,
  isLoggingIn: boolean,
  isLoggedIn: boolean,
  isSessionExpired: boolean
};
type OwnProps = { t: Translate, i18n: I18n };
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  login: stores.auth.login,
  isLoggingIn: stores.auth.isLoggingIn,
  isLoggedIn: stores.auth.isLoggedIn,
  isSessionExpired: stores.auth.isSessionExpired
});

@inject(mapStoresToProps)
@observer
class Login extends Component<Props, State> {
  @observable username: string = "";
  @observable password: string = "";
  @observable timezone: string = "";
  @observable loginError: TypeResultMessage | null = null;

  @action setUsername = (e: any) => (this.username = e.target.value);
  @action setPassword = (e: any) => (this.password = e.target.value);
  @action setTimezone = (e: any) =>
    typeof e === "string"
      ? (this.timezone = e)
      : (this.timezone = e.target.value);
  @action setLoginError = (loginError: TypeResultMessage | null) =>
    (this.loginError = loginError);

  componentDidMount() {
    this.setTimezone(utilTimezone.getClientTimezone());

    if (this.props.isSessionExpired) {
      this.setLoginError({
        success: false,
        type: "err",
        message: this.props.t("messages.sessionExpired")
      });
    }
  }

  get isFormValid(): boolean {
    return this.username !== "" && this.password !== "" && this.timezone !== "";
  }

  subLogin = async () => {
    this.setLoginError(null);
    let result = await this.props.login(
      this.username,
      this.password,
      this.timezone
    );
    if (!result.success) {
      this.setLoginError(result);
    }
  };

  render() {
    const { t, isLoggingIn, isLoggedIn } = this.props;

    if (isLoggedIn) return <Redirect to="/home" />;

    return (
      <div className={"container auth-container"}>
        <section className={"col-wrapper auth-content"}>
          <Logo />

          <form id={"login-form"} noValidate>
            {this.loginError && (
              /* $FlowFixMe */
              <MessageBox
                type={this.loginError.type}
                message={this.loginError.message}
              />
            )}

            <Input
              type="text"
              name="username"
              value={this.username}
              onChange={this.setUsername}
              iconName="user"
              placeholder={t("username")}
              autoFocus={true}
            />

            <Input
              type="password"
              name="password"
              value={this.password}
              onChange={this.setPassword}
              iconName="key"
              placeholder={t("password")}
            />

            <Select
              name="timezone"
              value={this.timezone}
              onChange={this.setTimezone}
              options={timezones}
              iconName="clock"
              placeholder={t("timezone")}
            />

            <div className="btn-wrapper">
              <Button
                text={t("login")}
                disabled={!this.isFormValid || isLoggingIn}
                isLoading={isLoggingIn}
                onClick={this.subLogin}
              />
            </div>
          </form>
          <Link to="/reset-password">{t("lostPassword")}</Link>
        </section>
      </div>
    );
  }
}

export default withTranslation("auth")(Login);
