/** @flow */
import { observable } from "mobx";
import api from "../services/api";
import i18nService from "../services/i18n";
import chartUtils from "../utils/chart";

import type { TrendPointDTO as TypeTrendPointDTO } from "../types/TrendPointDTO";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { ChartDot as TypeChartDot } from "../types/ChartDot";

export default class Tag {
  @observable
  id: string;
  @observable
  description: string;
  @observable
  type: string;
  @observable
  measureUnit: string;
  @observable
  value: number;
  @observable
  formattedValue: string;
  @observable
  trend: boolean; // if true, the tag is visibile on Trend filter
  @observable
  minValue: ?number;
  @observable
  maxValue: ?number;
  @observable
  historyTrendPoints: {
    [date: string]: TypeTrendPointDTO[]
  };

  constructor(
    id: string,
    description: string,
    type: string,
    measureUnit: string,
    value: number,
    formattedValue: string,
    trend: boolean,
    minValue: ?number = null,
    maxValue: ?number = null
  ) {
    this.id = id;
    this.description = description;
    this.type = type;
    this.measureUnit = measureUnit;
    this.value = value;
    this.formattedValue = formattedValue;
    this.trend = trend;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.historyTrendPoints = {};
  }

  formatTrendPointsChart(dateISO8601: string): TypeChartDot[] {
    return this.historyTrendPoints[dateISO8601]
      ? this.historyTrendPoints[dateISO8601]
          .filter(tP => tP.value != null)
          .map(tP => ({
            x: tP.timestamp,
            y: tP.value
          }))
      : [];
  }

  async fetchTrendPoints(
    dateISO8601: string,
    forceRefresh: boolean = false
  ): Promise<TypeResultMessage> {
    let result = { success: true };
    if (
      !this.historyTrendPoints[dateISO8601] ||
      (this.historyTrendPoints[dateISO8601] && forceRefresh)
    ) {
      try {
        let remoteData;

        if (this.type === "CURRENT_POWER") {
          remoteData = await api.getTrendPotenzaOdierno(
            this.id,
            chartUtils.getMaxSamplesNumber()
          );
        } else {
          // type = TAG
          remoteData = await api.getTrendGiornaliero(
            this.id,
            dateISO8601,
            chartUtils.getMaxSamplesNumber()
          );
        }

        if (!remoteData.success) {
          throw new Error(remoteData.error);
        }

        this.historyTrendPoints[dateISO8601] = remoteData.result.filter(
          sample => sample.value != null
        );
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError")
        };
      }
    }

    return result;
  }
}
