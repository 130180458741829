/** @flow */
import React, { Component } from "react";
import { observable, action } from "mobx";

type Props = {
  open: boolean,
  toggle: () => any,
  position: "left" | "right",
  children?: any,
  className?: string[]
};
type State = {};

class Drawer extends Component<Props, State> {
  onMaskTouch = (e: any) => {
    this.props.toggle();
  };

  render() {
    const { open, toggle, position, children, className } = this.props;
    if (!open) {
      return null;
    }

    let _classes = ["drawer-container"];
    if (className) {
      _classes = [..._classes, ...className];
    }
    return (
      <div className={_classes.join(" ")}>
        <div className={"drawer-mask"} onClick={this.onMaskTouch} />
        <div className={`drawer-content-wrapper drawer-position-${position}`}>
          {children}
        </div>
      </div>
    );
  }
}

export default Drawer;
