/** @flow */
import React, { Component } from "react";

type Props = {
  description: string,
  value: string,
  selected?: boolean,
  disabled?: boolean
};
type State = {};

class TagItem extends Component<Props, State> {
  render() {
    const {
        description,
        value,
        selected,
        disabled,
        ...otherProps
      } = this.props,
      styleClasses = ["tag-item"];

    if (selected) {
      styleClasses.push("selected");
    }

    if (disabled) {
      styleClasses.push("disabled");
    }
    return (
      <div className={styleClasses.join(" ")} {...otherProps}>
        <span>{description}</span>
        <span>{value}</span>
      </div>
    );
  }
}

export default TagItem;
