/** @flow */
import React, { Component } from "react";
import { Provider } from "mobx-react";

import App from "./App";
import AuthStore from "../stores/Auth";
import PlantsStore from "../stores/Plants";
import PWAStore from "../stores/PWA";
import NavigationStore from "../stores/Navigation";

const plantsStore = new PlantsStore();
const authStore = new AuthStore(plantsStore);
const pWAStore = new PWAStore();
const navigationStore = new NavigationStore();

type Props = {};
type State = {};

export default class Root extends Component<Props, State> {
  render() {
    return (
      <Provider
        auth={authStore}
        plants={plantsStore}
        pwa={pWAStore}
        navigation={navigationStore}
      >
        <App />
      </Provider>
    );
  }
}
