/** @flow @format */
export default {
  auth: {
    username: "Username",
    password: "Password",
    timezone: "Timezone",
    login: "Login",
    lostPassword: "Password forgotten",
    backToLogin: "Back to login",
    errors: {
      badCredentials: "Wrong username or password",
      unauthorized: "Access denied.",
      password: {
        ERR_OLD_PASSWORD_INVALID: "Old password mismatch",
        ERR_SAME_PASSWORD:
          "The new password must be different from the old one",
        ERR_CONFIRM_PASSWORD_INVALID:
          "New password and confirm password do not match",
        ERR_PASSWORD_TO_SIMPLE:
          "The new password does not meet the required complexity criteria"
      }
    },
    resetPassword: "Reset password",
    messages: {
      resetDone: "Password has been reset. Check your email!",
      changePswDone: "Password successfully updated",
      sessionExpired: "Session expired"
    }
  },
  menu: {
    home: "Home",
    plant: "Plant",
    trend: "Trend",
    alarms: "Alarms",
    monitoring: "Monitoring",
    productions: "Production",
    forecast: "Forecast",
    indicators: "Indicators",
    prodHoursCapFact: "Prod./Hours eq./Cap. Fact.",
    disponibility: "Availability",
    performanceRatio: "Performance Ratio",
    report: "Report",
    shutdowns: "Shutdowns",
    pdm: "Journal of Plant",
    monthly: "Monthly",
    accountMng: "User management",
    changePsw: "Change password",
    changeLng: "Change language",
    preset: "Preset",
    logout: "Logout"
  },
  alarm: {
    states: {
      inProgress: "Ongoing",
      acknowledged: "Ongoing",
      toBeAcknowledged: "Returned"
    },
    status: "State",
    startDate: "Start date",
    endDate: "End date",
    ackDate: "Acknowledge date",
    area: "Area",
    class: "Class",
    description: "Identification",
    description2: "Description",
    noAlarms: "No alarm present",
    ackComment: "ACK comment",
    ack: "ACK",
    ackCommentMessage: "Add comment",
    details: "Alarm detail",
    private: "Private"
  },
  aths: {
    title: "Add e-guardian to Home",
    info: "Press <i class='icon-navigation_action' /> and select Add to Home"
  },
  ob: {
    message: "App is offline"
  },
  upwas: {
    message: "New version available. Click here to update the application"
  },
  common: {
    power: "Active Power",
    energy: "Active Energy",
    forecastPower: "Forecast Power",
    forecastEnergy: "Forecast Energy",
    currentPower: "Current active power",
    totalPower: "Total active power",
    dailyProduction: "Daily production",
    annualProduction: "Yearly production",
    monthlyProduction: "Monthly production",
    plants: "Plants",
    production: "Production",
    productions: "Production",
    plantDetails: "Plant details",
    totalProductions: "Total production",
    totals: "Total",
    monthlyProductions: "{{month}} {{year}}",
    totalProduction: "Total production",
    kWValue: "{{value}} kW",
    kWhValue: "{{value}} kWh",
    hValue: "{{value}} h",
    percValue: "{{value}} %",
    noData: "No data available",
    changePlant: "Change plant",
    confirm: "Confirm",
    alarms: "Alarms",
    trend: "Trend",
    day: "Day",
    hour: "Hour",
    tag1: "Tag 1",
    tag2: "Tag 2",
    filter: "Filter",
    emptyTrendFilter: "Set a filter to display the trend",
    report: "Report",
    shutdowns: "Shutdowns",
    pdm: "Journal of Plant",
    indicators: "Indicators",
    monthly: "Monthly",
    startDate: "Start day:",
    endDate: "End day:",
    generateReport: "Generate report",
    includePdMOpen: "Include open",
    includePdMWiP: "Include in progress",
    pdMType: "Type",
    pdMTypeOrdinary: "Ordinary",
    pdMTypeExtraordinary: "Exraordinary",
    totalEnergy: "Total Production",
    produced: "Real",
    exptected: "Exptected",
    producedEnergyLbl: "Real Production produced",
    exptectedEnergyLbl: "Expected Production",
    annualForecast: "Forecast - Total monthly production",
    monthlyForecast: "Forecast - Total daily production",
    dailyChartForecast: "Forecast - Daily trend",
    dailyDetail: "{{dayExt}} {{day}} {{month}} {{year}}",
    prodEqHoursCapFact: "Prod./Hours eq./Cap. Fact.",
    eqHours: "Equivalent hours",
    capFactor: "Capacity Factor",
    maximal: "Max. scale",
    eqHoursValue: "Equivalent hours",
    disponibility: "Availability",
    faults: "Faults",
    faultsAndSchedShutdowns: "Faults and scheduled shudowns",
    overall: "Overall",
    yes: "Yes",
    no: "No",
    performanceRatio: "Performance Ratio",
    dailyPerformanceRatio: "Daily Performance Ratio",
    accountMng: "User management",
    preset: "Preset",
    setPreset: "Set preset",
    resetPreset: "Remove active preset",
    user: "User",
    settings: "Settings",
    changePassword: "Change password",
    changeLanguage: "Change language",
    oldPassword: "Old password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    selectLanguage: "Select language",
    languageChanged: "Language changed successfully",
    presetChanged: "Preset updated successfully",
    performanceRatioNotAvailable: "Data not available for this plant type",
    calendar: {
      year: "Year",
      month: "Month",
      months: {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dic"
      },
      days: {
        "0": "Sun",
        "1": "Mon",
        "2": "Tue",
        "3": "Wed",
        "4": "Thu",
        "5": "Fri",
        "6": "Sat"
      }
    },
    mu: {
      kw: "kW",
      kwh: "kWh"
    },
    lng: {
      it: "Italiano",
      en: "English",
      es: "Español"
    },
    requestError: "Generic error",
    reportError: "Report not available for the selected dates",
    requestOk: "Operation performed correctly",
    notAvailable: "N.A."
  }
};
