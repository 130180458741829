/** @flow @format */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nTranslations from "../i18n";

i18n.use(initReactI18next).init({
  lng: "it",
  fallbackLng: "it",
  resources: {
    ...i18nTranslations
  },
  ns: ["common"],
  defaultNS: "common",

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
