/** @flow */
import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";

import PlantStatistics from "../components/PlantStatistics";
import MessageBox from "../components/MessageBox";

import utilText from "../utils/text";

import PlantModel from "../models/Plant";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { RouterHistory } from "../types/ContextRouter";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

type StoresProps = {
  fetchPlantsStatistics: () => Promise<TypeResultMessage>,
  isFetchingStatistics: boolean,
  plants: PlantModel[] | null,
  currentPower: number,
  markSessionAsExpired: () => any,
  isSessionExpired: boolean
};
type OwnProps = {
  history: RouterHistory,
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  fetchPlantsStatistics: stores.plants.fetchPlantsStatistics,
  isFetchingStatistics: stores.plants.isFetchingStatistics,
  plants: stores.plants.plants,
  currentPower: stores.plants.currentPower,
  markSessionAsExpired: stores.auth.markSessionAsExpired,
  isSessionExpired: stores.auth.isSessionExpired
});

@inject(mapStoresToProps)
@observer
class HomePlantsStatistics extends Component<Props, State> {
  @observable
  fetchError: ?TypeResultMessage = null;
  @action setFetchError = (data: ?TypeResultMessage) =>
    (this.fetchError = data);

  @observable intervalId: any;

  componentDidMount() {
    this.fetchPlantsStatistics();
    let iId = setInterval(this.fetchPlantsStatistics, 60000);
    this.intervalId = iId;
  }

  fetchPlantsStatistics = async () => {
    this.setFetchError(null);

    const result = await this.props.fetchPlantsStatistics();

    if (!result.success) {
      if (result.error === "Unauthorized") {
        this.props.markSessionAsExpired();
      }
      this.setFetchError(result);
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const {
      plants,
      t,
      isFetchingStatistics,
      isSessionExpired,
      currentPower
    } = this.props;

    if (isSessionExpired) return null;

    return (
      <div className={"content-wrapper"}>
        <div className={"sub-header lr-pdd"}>
          <h2>{t("plants")}</h2>

          <div className={"meta-data text-right"}>
            {!isFetchingStatistics && !this.fetchError && (
              <span>
                <span>{`${t("totalPower")}`}</span>
                <span className={"meta-value"}>{`${t("kWValue", {
                  value: utilText.formatNumber(currentPower)
                })}`}</span>
              </span>
            )}
          </div>
        </div>
        <section className={"stat-imp-container content lr-pdd"}>
          {this.fetchError && (
            /** $FlowFixMe */
            <MessageBox
              type={this.fetchError.type}
              message={this.fetchError.message}
            />
          )}
          {plants &&
            !isFetchingStatistics &&
            plants.map(plant => (
              <Link to={`/${plant.id}/plant-details`} key={plant.id}>
                <PlantStatistics plant={plant} />
              </Link>
            ))}
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(HomePlantsStatistics);
