/** @flow @format */
import it from "./it";
import en from "./en";
import es from "./es";

export default {
  it,
  en,
  es
};
