/** @flow */
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import type { Translate, I18n } from "../types/I18n";

import logo from "../assets/images/logo.png";

type Props = {
  t: Translate
};

type State = {
  showInstallMessage: boolean
};

class AddToHomeScreen extends Component<Props, State> {
  state = {
    showInstallMessage: false
  };
  // Detects if device is on iOS
  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  // Detects if device is in standalone mode
  isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  componentDidMount() {
    if (this.isIos() && !this.isInStandaloneMode()) {
      this.setState({ showInstallMessage: true });
    }
  }

  hideInstallMessage = () => {
    this.setState({ showInstallMessage: false });
  };

  render() {
    const { showInstallMessage } = this.state;
    const { t } = this.props;

    return (
      <Fragment>
        {showInstallMessage && (
          <div className={"aths-container"}>
            <img
              src={logo}
              className={"aths-brand"}
              alt={"e-guardian logo"}
            />
            <div className={"aths-content"}>
              <div className={"aths-title"}>{t("title")}</div>
              {/* Using dangerouslySetInnerHTML to inject icon on translated text */}
              <div
                className={"aths-info"}
                dangerouslySetInnerHTML={{ __html: t("info") }}
              />
            </div>
            <div className={"aths-actions"}>
              <button onClick={this.hideInstallMessage}>
                <i className={"icon-cancel-slim"} />
              </button>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withTranslation("aths")(AddToHomeScreen);
