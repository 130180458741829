/** @flow */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import type { TabNav as TypeTabNav } from "../types/TabNav";
import type { RouterHistory } from "../types/ContextRouter";

type Props = {
  children: any,
  tabsNav: TypeTabNav[],
  history: RouterHistory //react-router-dom
};
type State = {};

class TabNav extends Component<Props, State> {
  _getIndexOfTab = () => {
    const { tabsNav } = this.props,
      _params = new URLSearchParams(location.search),
      view = _params.get("view");
    let tabIdx = 0;

    if (view) {
      const checkViewExists = tabsNav.findIndex(tab => tab.url === view);
      tabIdx = checkViewExists < 0 ? 0 : checkViewExists;
    }

    return tabIdx;
  };

  componentDidMount() {
    const { history, tabsNav } = this.props;

    // set default the first element of tab
    history.push(
      `${history.location.pathname}?view=${tabsNav[this._getIndexOfTab()].url}`
    );
  }

  render() {
    const { tabsNav, history } = this.props;

    const activeTabIndex = this._getIndexOfTab();

    return (
      <div className="tab-nav-container">
        <div className="tab-content-wrapper">
          {this.props.children[activeTabIndex]}
        </div>
        <div className="tab-bar">
          {tabsNav.map((tabNav, idx) => (
            <Link
              key={idx}
              to={`${history.location.pathname}?view=${tabNav.url}`}
              className={
                idx === activeTabIndex ? "tab-item active" : "tab-item"
              }
              replace
            >
              <i className={`tab-icon icon-${tabNav.icon}`} />
              <span>{tabNav.label}</span>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(TabNav);
