/** @flow */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import { Redirect, Link } from "react-router-dom";

import { withTranslation } from "react-i18next";
import type { Translate, I18n } from "../types/I18n";
import type { Stores } from "../types/Stores";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

import Logo from "../components/Logo";
import Input from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import MessageBox from "../components/MessageBox";

import timezones from "../config/timezones";
import utilTimezone from "../utils/timezone";

type StoresProps = {
  resetPassword: (username: string) => Promise<TypeResultMessage>,
  isResetPending: boolean,
  isLoggedIn: boolean
};
type OwnProps = { t: Translate, i18n: I18n };
type State = {
  username: string
};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  resetPassword: stores.auth.resetPassword,
  isResetPending: stores.auth.isResetPending,
  isLoggedIn: stores.auth.isLoggedIn
});

@inject(mapStoresToProps)
@observer
class ResetPassword extends Component<Props, State> {
  @observable username: string = "";
  @observable formMessage: TypeResultMessage | null = null;

  @action setUsername = (e: any) => (this.username = e.target.value);
  @action setFormMessage = (message: TypeResultMessage) =>
    (this.formMessage = message);

  get isFormValid(): boolean {
    return this.username !== "";
  }

  subReset = async () => {
    this.setFormMessage({});

    const result = await this.props.resetPassword(this.username);

    this.setFormMessage(result);
  };

  render() {
    const { t, isResetPending } = this.props;

    if (this.props.isLoggedIn) return <Redirect to="/home" />;

    return (
      <div className={"container auth-container"}>
        <section className={"col-wrapper auth-content"}>
          <Logo />

          {this.formMessage && (
            /* $FlowFixMe */
            <MessageBox
              type={this.formMessage.type}
              message={this.formMessage.message}
            />
          )}

          <Input
            type="text"
            name="username"
            value={this.username}
            onChange={this.setUsername}
            placeholder={t("username")}
            iconName="user"
          />
          <div className="btn-wrapper">
            <Button
              text={t("resetPassword")}
              disabled={!this.isFormValid || isResetPending}
              isLoading={isResetPending}
              onClick={this.subReset}
            />
          </div>

          <Link to="/login">{t("backToLogin")}</Link>
        </section>
      </div>
    );
  }
}

export default withTranslation("auth")(ResetPassword);
