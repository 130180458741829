/** @flow */
import React, { Component } from "react";
import { observable, computed, action } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

import CommonLayout from "../components/CommonLayout";
import PlantSelect from "../components/PlantSelect";
import Indicator from "../components/Indicator";
import Toolbar from "../components/Toolbar";
import MessageBox from "../components/MessageBox";
import DateSlider from "../components/DateSlider";
import IndicatorsLegend from "../components/IndicatorsLegend";

import PlantModel from "../models/Plant";

import utilText from "../utils/text";

import type { Stores } from "../types/Stores";
import type { User as TypeUser } from "../types/User";
import type { Translate, I18n } from "../types/I18n";
import type { Match as TypeMatch } from "../types/ContextRouter";
import type { RouterHistory as TypeRouterHistory } from "../types/ContextRouter";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { IndicatorsLegend as TypeIndicatorsLegend } from "../types/IndicatorsLegend";

const MODULE_NAME = "FORECAST";

type StoresProps = {
  user: TypeUser,
  plant: ?PlantModel,
  setCurrentModule: (module: string, plantId?: ?string) => string,
  plantsAllowed: PlantModel[],
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = {
  t: Translate,
  i18n: I18n,
  match: TypeMatch, //react-router url parameters
  history: TypeRouterHistory //react-router-dom
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    setCurrentModule: stores.auth.setCurrentModule,
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    plantsAllowed: stores.auth.plantsAllowed,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class PlantAnnualForecast extends Component<Props, State> {
  @observable
  currentDate: Date;
  @observable
  isFetchingData: boolean;
  @observable
  fetchError: TypeResultMessage | null;

  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  @computed
  get annualForecastsData() {
    return this.currentDate && this.props.plant
      ? /* $FlowFixMe */
        this.props.plant.annualForecasts[this.currentDate.getFullYear()]
      : null;
  }

  componentDidMount() {
    this.props.setCurrentModule(
      MODULE_NAME,
      this.props.plant ? this.props.plant.id : null
    );
    this.initView();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.plantId !== prevProps.match.params.plantId) {
      this.initView();
    }
  }

  initView = () => {
    const { history } = this.props;
    const _params = new URLSearchParams(location.search);
    let _cDate = new Date();
    if (_params.get("year")) {
      _cDate.setFullYear(parseInt(_params.get("year")));
    } else if (!_params.get("year") && this.currentDate) {
      _cDate = this.currentDate;
    }

    this.fetchData(_cDate);
  };

  fetchData = async (date: Date) => {
    const { plant, history, markSessionAsExpired } = this.props;
    if (plant) {
      this.isFetchingData = true;
      this.currentDate = date;
      this.setFetchError(null);

      history.push(`${history.location.pathname}?year=${date.getFullYear()}`);

      const res = await plant.fetchAnnualForecasts(date.getFullYear());
      this.isFetchingData = false;

      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);
      }
    }
  };

  render() {
    const {
        t,
        user,
        plant,
        plantsAllowed,
        isSessionExpired,
        isOpenSidebarMenu,
        toggleSidebarMenu
      } = this.props,
      legend: TypeIndicatorsLegend = {
        series: [t("producedEnergyLbl"), t("exptectedEnergyLbl")]
      };

    if (isSessionExpired) return null;

    return (
      <CommonLayout
        breadcrumb={t("annualForecast")}
        user={user}
        permissions={plant ? plant.permissions : []}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          {plant && <PlantSelect plant={plant} plants={plantsAllowed} />}
          <div className={"dp-wrapper"}>
            {this.currentDate && (
              <DateSlider
                type={"years"}
                onClick={this.fetchData}
                value={this.currentDate}
              />
            )}
          </div>
        </div>
        <section className="content lr-pdd forecasts-container">
          {this.fetchError && (
            <MessageBox
              /** $FlowFixMe */
              type={this.fetchError.type}
              /** $FlowFixMe */
              message={this.fetchError.message}
            />
          )}

          {this.annualForecastsData && <IndicatorsLegend items={legend} />}

          {plant &&
            this.annualForecastsData &&
            this.annualForecastsData.forecasts.map((forecast, idx) => (
              <Link
                key={idx}
                to={`/${plant.id}/forecast/${this.currentDate.getFullYear()}/${
                  forecast.key
                }`}
              >
                <Indicator data={forecast} />
              </Link>
            ))}
        </section>

        {!this.isFetchingData && this.annualForecastsData && (
          <Toolbar
            title={t("totalEnergy")}
            metaList={[
              {
                icon: true,
                value: t("kWValue", {
                  value: utilText.formatNumber(
                    // $FlowFixMe
                    this.annualForecastsData.totalEnergy
                  )
                })
              },
              {
                icon: true,
                value: t("kWValue", {
                  value: utilText.formatNumber(
                    // $FlowFixMe
                    this.annualForecastsData.totalExptectedEnergy
                  )
                })
              }
            ]}
          />
        )}
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(withRouter(PlantAnnualForecast));
