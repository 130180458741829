/** @flow */
import { action, observable, computed } from "mobx";

import { isMobile } from "../utils/screen";

export default class Navigation {
  @observable
  mainMenuOpen: boolean = !isMobile();

  @action
  toggleMainMenu = () => {
    this.mainMenuOpen = !this.mainMenuOpen;
  };
}
