/** @flow */
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import type { Translate, I18n } from "../types/I18n";

import PlantModel from "../models/Plant";

import ProgressBar from "../components/ProgressBar";

import utilsText from "../utils/text";

type Props = {
  plant: PlantModel,
  t: Translate,
  i18n: I18n,
  onClick: () => any
};
type State = {};

const ICONS = {
  IDROELETTRICO: "water-drop",
  FOTOVOLTAICO: "sun",
  EOLICO: "breeze",
  VARI: "gear",
  SOTTOSTAZIONE: "electric"
};

type SIMIProps = {
  label: string,
  value: string
};
const StatImpMetaItem = (props: SIMIProps) => (
  <div className={"stat-imp-det-item"}>
    {props.label}
    <span>{props.value}</span>
  </div>
);

class PlantStatistics extends Component<Props, State> {
  render() {
    const { t, plant, onClick } = this.props;
    const { name, type, statistics } = plant;

    if (!statistics) {
      return null;
    }

    const icon = type ? ICONS[type.toUpperCase()] : "",
      potenzaPerc = statistics
        ? utilsText.truncDecimal((statistics.power / statistics.maxPower) * 100)
        : 0;

    return (
      <Fragment>
        {statistics && (
          <div
            className={`stat-imp-content ${
              statistics.hasAlarms ? "imp-alarm" : ""
            } ${statistics.hasWarning ? "imp-wrn" : ""} ${
              statistics.power == null ? "imp-no-data" : ""
            }`}
            onClick={onClick}
          >
            <div className={"stat-imp-info"}>
              <span>{name}</span>
              {/* If PotenzaPerc is NaN (when maxPower is 0 ) don't show the percentage value */}
              <span>{isNaN(potenzaPerc) ? "" : `${potenzaPerc}%`}</span>
            </div>
            <ProgressBar
              styleClass={"pbar-sm"}
              value={statistics.power}
              max={statistics.maxPower}
            />
            <div className={"stat-imp-meta"}>
              <i className={`imp-type icon-${icon}`} />
              <div className={"stat-imp-det"}>
                <StatImpMetaItem
                  label={t("power")}
                  value={
                    statistics.power != null
                      ? `${t("kWValue", {
                          value: utilsText.formatNumber(statistics.power)
                        })}`
                      : "-"
                  }
                />

                <StatImpMetaItem
                  label={t("dailyProduction")}
                  value={
                    statistics.dailyProduction != null
                      ? `${t("kWhValue", {
                          value: utilsText.formatNumber(
                            statistics.dailyProduction
                          )
                        })}`
                      : "-"
                  }
                />
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withTranslation("common")(PlantStatistics);
