/** @flow */
import React from "react";

type Props = {
  label: string
};

const Breadcrumb = (props: Props) => (
  <div className={"breadcrumb-container"}>
    <span className={"breadcrumb-label"}>{props.label}</span>
  </div>
);

export default Breadcrumb;
