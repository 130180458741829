/** @flow */
import React, { Component } from "react";
import { observable, computed, action } from "mobx";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PlantModel from "../models/Plant";

import XYChart from "../components/XYChart";
import ErrorBoundary from "../components/ErrorBoundary";
import MessageBox from "../components/MessageBox";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { Match as TypeMatch } from "../types/ContextRouter";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

import chartConfig from "../config/chart";
import dateTimeUtils from "../utils/dateTime";

type StoresProps = {
  plant: ?PlantModel,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any
};
type OwnProps = {
  t: Translate,
  i18n: I18n,
  match: TypeMatch, //react-router url parameters
  chartType: "energy" | "power"
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired
  };
};

@inject(mapStoresToProps)
@observer
class PlantDailyForecastChartContainer extends Component<Props, State> {
  @observable
  year: number;
  @observable
  month: number;
  @observable
  day: number;
  @observable
  isFetchingData: boolean;
  @observable
  fetchError: ?TypeResultMessage | null;

  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  @computed
  get dataKey() {
    return `${this.year}${this.month}${this.day}`;
  }

  @computed
  get forecastDate() {
    const date = new Date();

    if (this.year && this.month && this.day) {
      date.setFullYear(this.year);
      date.setMonth(this.month - 1);
      date.setDate(this.day);
    }

    return date;
  }

  componentDidMount() {
    this.initView();
  }

  initView = () => {
    const { match } = this.props;

    if (match.params.year && match.params.month && match.params.day) {
      this.fetchData(
        parseInt(match.params.year),
        parseInt(match.params.month),
        parseInt(match.params.day)
      );
    }
  };

  fetchData = async (year: number, month: number, day: number) => {
    const { plant, markSessionAsExpired } = this.props;
    this.fetchError = null;
    if (plant) {
      this.isFetchingData = true;
      this.year = year;
      this.month = month;
      this.day = day;

      this.setFetchError(null);

      const res = await plant.fetchDailyTrendForecast(year, month, day);

      this.isFetchingData = false;
      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);
      }
    }
  };

  render() {
    const { plant, chartType, t, isSessionExpired } = this.props,
      series = [];
    let yAxisTitle = "";

    if (isSessionExpired) return null;

    if (plant && plant.dailyForecasts[this.dataKey]) {
      if (chartType === "energy") {
        yAxisTitle = t("mu.kwh");
        series.push({
          title: t("energy"),
          data: plant.dailyForecasts[this.dataKey].energyTrend
        });
        series.push({
          title: t("forecastEnergy"),
          data: plant.dailyForecasts[this.dataKey].energyForecastTrend,
          color: chartConfig.colors.secondary
        });
      } else if (chartType === "power") {
        yAxisTitle = t("mu.kw");
        series.push({
          title: t("power"),
          data: plant.dailyForecasts[this.dataKey].powerTrend
        });
        series.push({
          title: t("forecastPower"),
          data: plant.dailyForecasts[this.dataKey].powerForecastTrend,
          color: chartConfig.colors.secondary
        });
      }
    }

    return (
      <div className={"lr-pdd"}>
        {this.fetchError && (
          <MessageBox
            /** $FlowFixMe */
            type={this.fetchError.type}
            /** $FlowFixMe */
            message={this.fetchError.message}
          />
        )}
        <ErrorBoundary>
          <XYChart
            isLoading={this.isFetchingData}
            xTitle={"h"}
            yTitle={yAxisTitle}
            showLegend={true}
            series={series}
            xTickValues={dateTimeUtils.getDayHours(this.forecastDate)}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

export default withTranslation("common")(
  withRouter(PlantDailyForecastChartContainer)
);
