/** @flow */
import React, { Component } from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import TagItem from "./TagItem";

import TagModel from "../models/Tag";

type Props = {
  data: TagModel[],
  onClick: (tag: TagModel) => any,
  selectedItemId?: ?string
};
type State = {};

@observer
class TagsList extends Component<Props, State> {
  handleClick = (tag: TagModel) => {
    if (tag.id !== this.props.selectedItemId && tag.trend) {
      const { data, onClick } = this.props;

      onClick(tag);
    }
  };
  render() {
    const { data, onClick } = this.props;

    return (
      <div className={"content tags-list lr-pdd"}>
        {data.map((tag: TagModel, idx: number) => (
          <TagItem
            key={tag.id}
            description={tag.description}
            value={
              tag.formattedValue
                ? `${tag.formattedValue} ${
                    tag.measureUnit ? tag.measureUnit : ""
                  }`
                : ""
            }
            selected={this.props.selectedItemId === tag.id}
            onClick={() => this.handleClick(tag)}
            disabled={!tag.trend}
          />
        ))}
      </div>
    );
  }
}

export default TagsList;
