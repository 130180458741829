/** @flow */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { withTranslation } from "react-i18next";

import PlantModel from "../models/Plant";

import CommonLayout from "../components/CommonLayout";
import PlantSelect from "../components/PlantSelect";
import MessageBox from "../components/MessageBox";
import AlarmList from "../components/AlarmList";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

import type { User as TypeUser } from "../types/User";
import type { Match as TypeMatch } from "../types/ContextRouter";

const MODULE_NAME = "ALLARMI";

type StoresProps = {
  user: TypeUser,
  setCurrentModule: (module: string, plantId?: ?string) => string,
  plants: ?(PlantModel[]),
  plant: ?PlantModel,
  plantsAllowed: PlantModel[],
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = {
  match: TypeMatch, //react-router url parameters
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    setCurrentModule: stores.auth.setCurrentModule,
    plants: stores.plants.plants,
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    plantsAllowed: stores.auth.plantsAllowed,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class PlantAlarms extends Component<Props, State> {
  @observable
  isFetchingAlarms: boolean;
  @observable
  fetchError: TypeResultMessage | null;

  componentDidMount() {
    this.props.setCurrentModule(
      MODULE_NAME,
      this.props.plant ? this.props.plant.id : null
    );

    this.fetchAlarms();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.plantId !== prevProps.match.params.plantId) {
      this.fetchAlarms();
    }
  }

  handleSubmit = () => {
    this.fetchAlarms();
  };

  fetchAlarms = async () => {
    const { plant, markSessionAsExpired } = this.props;

    if (plant) {
      this.isFetchingAlarms = true;
      this.fetchError = null;

      const res = await plant.fetchAlarms();

      this.isFetchingAlarms = false;

      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.fetchError = res;
      }
    }
  };

  render() {
    const {
      user,
      plant,
      plantsAllowed,
      t,
      isSessionExpired,
      isOpenSidebarMenu,
      toggleSidebarMenu
    } = this.props;

    if (isSessionExpired) return null;

    return (
      <CommonLayout
        breadcrumb={t("alarms")}
        user={user}
        permissions={plant ? plant.permissions : []}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          {plant && <PlantSelect plant={plant} plants={plantsAllowed} />}
        </div>
        {this.fetchError && (
          <MessageBox
            /** $FlowFixMe */
            type={this.fetchError.type}
            /** $FlowFixMe */
            message={this.fetchError.message}
          />
        )}
        {plant && (
          <AlarmList
            alarms={plant.alarms}
            isFetching={this.isFetchingAlarms}
            onSubmit={this.handleSubmit}
          />
        )}
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(PlantAlarms);
