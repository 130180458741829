/** @flow */
import React, { Component } from "react";
import { observable, computed } from "mobx";
import { observer } from "mobx-react";

import { withTranslation } from "react-i18next";

import type { Translate, I18n } from "../types/I18n";

type Props = {
  t: Translate,
  type: "months" | "years",
  onClick: (data: Date) => any,
  value?: Date
};
type State = {};

@observer
class DateSlider extends Component<Props, State> {
  @observable
  date: Date = new Date();

  @computed
  get label() {
    let lbl = "";

    if (this.props.type === "years") {
      lbl = `${this.props.t("calendar.year")} ${this.date.getFullYear()}`;
    } else if (this.props.type === "months") {
      lbl = this.date.getMonth();
    }

    return lbl;
  }

  @computed
  get showNext() {
    const { type } = this.props;
    let result = true;

    if (
      type === "years" &&
      this.date.getFullYear() >= new Date().getFullYear()
    ) {
      result = false;
    }

    return result;
  }

  componentDidMount() {
    if (this.props.value) {
      this.date = this.props.value;
    }
  }

  decrase = () => {
    const { type } = this.props;
    if (type === "years") {
      this.date = new Date(this.date.setFullYear(this.date.getFullYear() - 1));
    }

    this.props.onClick(this.date);
  };

  increase = () => {
    const { type } = this.props;
    if (type === "years") {
      this.date = new Date(this.date.setFullYear(this.date.getFullYear() + 1));
    }
    this.props.onClick(this.date);
  };

  render() {
    return (
      <div className={"date-picker"}>
        <span className={"date-picker-btt"} onClick={this.decrase}>
          <i className={"icon-left-open-big"} />
        </span>
        <span> {this.label} </span>
        <span className={"date-picker-btt"} onClick={this.increase}>
          {this.showNext ? <i className={"icon-right-open-big"} /> : ""}
        </span>
      </div>
    );
  }
}

export default withTranslation("common")(DateSlider);
