/** @flow */
import React, { Component } from "react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LANGUAGES from "../config/languages";

import Select from "../components/Select";
import Button from "../components/Button";
import MessageBox from "../components/MessageBox";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { RouterHistory } from "../types/ContextRouter";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { Language as TypeLanguage } from "../types/Language";

type StoresProps = {
  sessionLanguage: TypeLanguage,
  changeLanguage: (language: TypeLanguage) => any
};
type OwnProps = {
  history: RouterHistory,
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  sessionLanguage: stores.auth.sessionLanguage,
  changeLanguage: stores.auth.changeLanguage
});

@inject(mapStoresToProps)
@observer
class ProfileSettings extends Component<Props, State> {
  @observable
  changeLngResult: TypeResultMessage = {};

  @observable
  isChangeLanguagePending: boolean = false;
  @observable
  selectedLanguage: TypeLanguage;
  @observable
  dirtyForm: boolean = false;

  @action
  setSelectedLanguage = (e: any) => {
    typeof e === "string"
      ? (this.selectedLanguage = e)
      : (this.selectedLanguage = e.target.value);
    this.dirtyForm = true;
  };

  componentDidMount() {
    this.selectedLanguage = this.props.sessionLanguage;
  }

  setLanguage = async () => {
    this.changeLngResult = {};

    this.isChangeLanguagePending = true;

    this.changeLngResult = await this.props.changeLanguage(
      this.selectedLanguage
    );

    this.props.i18n.changeLanguage(this.selectedLanguage);

    this.isChangeLanguagePending = false;
  };

  render() {
    const { t, sessionLanguage } = this.props,
      opts = LANGUAGES.map(l => ({ id: l, value: t(`lng.${l}`) }));

    return (
      <div className={"content-wrapper"}>
        <div className={"sub-header lr-pdd"}>
          <h2>{t("changeLanguage")}</h2>
        </div>
        <section className={"stat-imp-container content lr-pdd"}>
          {this.changeLngResult && (
            /** $FlowFixMe */
            <MessageBox
              type={this.changeLngResult.type}
              message={this.changeLngResult.message}
            />
          )}

          <Select
            name={"lng"}
            label={t("selectLanguage")}
            options={opts}
            value={this.selectedLanguage}
            onChange={this.setSelectedLanguage}
          />

          <div className="btn-wrapper">
            <Button
              text={t("confirm")}
              disabled={!this.dirtyForm || this.isChangeLanguagePending}
              isLoading={this.isChangeLanguagePending}
              onClick={this.setLanguage}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(ProfileSettings);
