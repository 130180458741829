/** @flow */
import React, { Component } from "react";
import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

import CommonLayout from "../components/CommonLayout";
import TabNav from "../components/TabNav";
import PlantDailyForecastChartContainer from "./PlantDailyForecastChartContainer";

import PlantModel from "../models/Plant";

import type { Stores } from "../types/Stores";
import type { User as TypeUser } from "../types/User";
import type { Translate, I18n } from "../types/I18n";
import type { Match as TypeMatch } from "../types/ContextRouter";
import type { RouterHistory as TypeRouterHistory } from "../types/ContextRouter";
import type { TabNav as TypeTabNav } from "../types/TabNav";

type StoresProps = {
  user: TypeUser,
  plant: ?PlantModel,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = {
  t: Translate,
  i18n: I18n,
  match: TypeMatch, //react-router url parameters
  history: TypeRouterHistory //react-router-dom
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class PlantDailyForecast extends Component<Props, State> {
  render() {
    const {
        t,
        user,
        plant,
        match,
        isOpenSidebarMenu,
        toggleSidebarMenu
      } = this.props,
      year = match.params.year,
      month = match.params.month,
      day = match.params.day,
      date =
        year && month && day
          ? new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
          : null,
      tabs: TypeTabNav[] = [
        {
          label: t("power"),
          icon: "chart-c1",
          url: "forecast-power"
        },
        {
          label: t("energy"),
          icon: "flash",
          url: "forecast-energy"
        }
      ];

    return (
      <CommonLayout
        breadcrumb={`${t("dailyChartForecast")} ${plant ? plant.name : ""}`}
        user={user}
        permissions={plant ? plant.permissions : []}
        backButton={true}
        backUrl={
          plant && year && month ? `/${plant.id}/forecast/${year}/${month}` : ""
        }
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          <h2>
            {t("dailyDetail", {
              dayExt: t(`calendar.days.${date ? date.getDay() : "-1"}`),
              day,
              month: t(`calendar.months.${month ? month : "-1"}`),
              year
            })}
          </h2>
        </div>

        <TabNav tabsNav={tabs}>
          <PlantDailyForecastChartContainer chartType={"power"} />
          <PlantDailyForecastChartContainer chartType={"energy"} />
        </TabNav>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(withRouter(PlantDailyForecast));
