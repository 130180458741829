/** @flow */
import React, { Component } from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import AlarmList from "../components/AlarmList";

import AlarmModel from "../models/Alarm";

import type { Stores } from "../types/Stores";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { Translate, I18n } from "../types/I18n";

type StoresProps = {
  fetchUserAlarms: () => Promise<TypeResultMessage>,
  isFetchingUserAlarms: boolean,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  userAlarms: AlarmModel[]
};
type OwnProps = {
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  fetchUserAlarms: stores.plants.fetchUserAlarms,
  isFetchingUserAlarms: stores.plants.isFetchingUserAlarms,
  userAlarms: stores.plants.userAlarms,
  isSessionExpired: stores.auth.isSessionExpired,
  markSessionAsExpired: stores.auth.markSessionAsExpired
});

@inject(mapStoresToProps)
@observer
class HomeAlarms extends Component<Props, State> {
  @observable
  fetchError: TypeResultMessage | null = null;

  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  componentDidMount() {
    this.fetchData();
  }

  handleSubmit = () => {
    this.fetchData();
  };

  fetchData = async () => {
    const { fetchUserAlarms, markSessionAsExpired } = this.props;

    this.setFetchError(null);

    const result = await fetchUserAlarms();
    if (!result.success) {
      if (result.error === "Unauthorized") {
        markSessionAsExpired();
      }
      this.setFetchError(result);
    }
  };

  render() {
    const {
      userAlarms,
      t,
      isFetchingUserAlarms,
      isSessionExpired
    } = this.props;

    if (isSessionExpired) return null;

    return (
      <div className={"content-wrapper"}>
        <div className={"sub-header lr-pdd"}>
          <h2>{t("alarms")}</h2>
        </div>
        <section className={"home-alarms"}>
          <AlarmList
            alarms={userAlarms}
            isFetching={isFetchingUserAlarms}
            onSubmit={this.handleSubmit}
          />
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(HomeAlarms);
