/** @flow */
import React, { Component } from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Link, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Indicator from "../components/Indicator";
import MessageBox from "../components/MessageBox";

import type { Stores } from "../types/Stores";
import type { Indicator as TypeIndicator } from "../types/Indicator";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { Translate, I18n } from "../types/I18n";

import utilText from "../utils/text";

type StoresProps = {
  fetchAnnualProductions: () => Promise<TypeResultMessage>,
  isFetchingAnnualProd: boolean,
  annualProductions: TypeIndicator[] | null,
  annualTotalProduction: number,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any
};
type OwnProps = {
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  fetchAnnualProductions: stores.plants.fetchAnnualProductions,
  isFetchingAnnualProd: stores.plants.isFetchingAnnualProd,
  annualProductions: stores.plants.annualProductions,
  annualTotalProduction: stores.plants.annualTotalProduction,
  isSessionExpired: stores.auth.isSessionExpired,
  markSessionAsExpired: stores.auth.markSessionAsExpired
});

@inject(mapStoresToProps)
@observer
class HomeAnnualProductions extends Component<Props, State> {
  @observable
  fetchError: TypeResultMessage | null = null;

  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { fetchAnnualProductions, markSessionAsExpired } = this.props;

    this.setFetchError(null);

    const result = await fetchAnnualProductions();
    if (!result.success) {
      if (result.error === "Unauthorized") {
        markSessionAsExpired();
      }
      this.setFetchError(result);
    }
  };

  render() {
    const {
      annualProductions,
      annualTotalProduction,
      t,
      isFetchingAnnualProd,
      isSessionExpired
    } = this.props;
    const _currTime = new Date(),
      currYear = _currTime.getFullYear();

    if (isSessionExpired) return null;

    return (
      <div className={"content-wrapper"}>
        <div className={"sub-header lr-pdd"}>
          <h2>{t("totalProductions")}</h2>
          <div className={"meta-data"}>
            <span>{`${t("calendar.year")} ${currYear}`}</span>
            {!isFetchingAnnualProd && (
              <span>
                <span>{`${t("totalProduction")}`}</span>
                <span className={"meta-value"}>{`${t("kWhValue", {
                  value: utilText.formatNumber(annualTotalProduction)
                })}`}</span>
              </span>
            )}
          </div>
        </div>

        <section className={"content lr-pdd prod-ann-container"}>
          {this.fetchError && (
            /** $FlowFixMe */
            <MessageBox
              type={this.fetchError.type}
              message={this.fetchError.message}
            />
          )}
          {annualProductions &&
            annualProductions.map((prod, idx) => (
              <Link to={`/details/monthly-productions/${prod.key}`} key={idx}>
                <Indicator data={prod} />
              </Link>
            ))}
        </section>
      </div>
    );
  }
}

export default withTranslation("common")(HomeAnnualProductions);
