/** @flow */
import React, { Component } from "react";

type Props = {
  title: string,
  description: string,
  selected?: boolean
};
type State = {};

class PresetItem extends Component<Props, State> {
  render() {
    const { title, description, selected, ...otherProps } = this.props,
      styleClasses = ["preset-item"];

    if (selected) {
      styleClasses.push("selected");
    }
    return (
      <div className={styleClasses.join(" ")} {...otherProps}>
        <div className={"preset-item-title"}>{title}</div>
        <div className={"preset-item-description"}>{description}</div>
      </div>
    );
  }
}

export default PresetItem;
