/** @flow */
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { observable } from "mobx";
import ReactModal from "react-modal";

import Button from "../components/Button";
import TextArea from "../components/TextArea";
import MessageBox from "../components/MessageBox";

import type { Translate, I18n } from "../types/I18n";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

import AlarmModel from "../models/Alarm";

type Props = {
  alarm: AlarmModel,
  t: Translate,
  onSubmit: () => any
};
type State = {};

ReactModal.setAppElement("#root");

@observer
class AlarmDetails extends Component<Props, State> {
  @observable
  isFetchingDetails: boolean;
  @observable
  showModal: boolean = false;
  @observable
  ackMessage: string = "";
  @observable
  isSubmitting: boolean = false;
  @observable
  resultMessage: TypeResultMessage | null;

  componentDidMount() {
    this.initView();
  }

  initView = async () => {
    const { alarm } = this.props;
    if (alarm) {
      this.isFetchingDetails = true;
      const res = await alarm.fetchDetails();
      this.isFetchingDetails = false;
    }
  };

  handleChangeAckMessage = event => {
    this.ackMessage = event.target.value;
  };

  handleSubmitAckMessage = async () => {
    const { alarm, onSubmit } = this.props;

    this.isSubmitting = true;

    this.resultMessage = await alarm.submitAckMessage(this.ackMessage);

    this.isSubmitting = false;
    this.ackMessage = "";
    this.showModal = false;

    onSubmit && onSubmit();
  };

  render() {
    const { alarm, t } = this.props;

    return (
      <div className={"alarm-details"}>
        <div className={"alarm-details-header"}>
          <h2>{t("details")}</h2>
          <span className={"alarm-icon"}>
            <i className={"icon-alarm"} style={{ color: alarm.styleColor }} />
          </span>
        </div>

        <div className={"alarm-data-container"}>
          {!this.isFetchingDetails && (
            <Fragment>
              {this.resultMessage && (
                <MessageBox
                  /** $FlowFixMe */
                  type={this.resultMessage.type}
                  /** $FlowFixMe */
                  message={this.resultMessage.message}
                />
              )}
              <div className={"data-row"}>
                <span>{t("status")}</span>
                <span>{t(`states.${alarm.status}`)}</span>
              </div>
              <div className={"data-row"}>
                <span>{t("startDate")}</span>
                <span>{alarm.startDateTime}</span>
              </div>
              <div className={"data-row"}>
                <span>{t("endDate")}</span>
                <span>{alarm.endDateTime ? alarm.endDateTime : "-"}</span>
              </div>
              <div className={"data-row"}>
                <span>{t("ackDate")}</span>
                <span>{alarm.ackDateTime ? alarm.ackDateTime : "-"}</span>
              </div>
              <div className={"data-row"}>
                <span>{t("area")}</span>
                <span>{alarm.area ? alarm.area : "-"}</span>
              </div>
              <div className={"data-row"}>
                <span>{t("class")}</span>
                <span>{alarm.className ? alarm.className : "-"}</span>
              </div>
              <div className={"data-col"}>
                <span>{t("description")}</span>
                <span>{alarm.description ? alarm.description : "-"}</span>
              </div>
              <div className={"data-col"}>
                <span>{t("description2")}</span>
                <span>{alarm.description2 ? alarm.description2 : "-"}</span>
              </div>
              {alarm.ackComment && (
                <div className={"data-col"}>
                  <span>{t("ackComment")}</span>
                  <span>{alarm.ackComment}</span>
                </div>
              )}
              {alarm.ackable && (
                <div className={"btn-wrapper"}>
                  <Button
                    text={t("ack")}
                    disabled={false}
                    isLoading={false}
                    onClick={() => (this.showModal = true)}
                  />
                </div>
              )}
            </Fragment>
          )}
        </div>
        <ReactModal
          isOpen={this.showModal}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => (this.showModal = false)}
          style={{
            content: {
              maxWidth: 350,
              margin: "0 auto",
              height: "fit-content",
              borderColor: "#d2d0d2"
            }
          }}
        >
          <TextArea
            name={"ackMessage"}
            label={t("ackCommentMessage")}
            onChange={this.handleChangeAckMessage}
            value={this.ackMessage}
          />
          <div className={"btn-wrapper"}>
            <Button
              text={t("common:confirm")}
              isLoading={this.isSubmitting}
              onClick={this.handleSubmitAckMessage}
            />
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default withTranslation("alarm")(AlarmDetails);
