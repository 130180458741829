/** @flow */
import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { withTranslation } from "react-i18next";

import TagModel from "../models/Tag";

import DatePicker from "../components/DatePicker";
import Select from "../components/Select";
import Button from "../components/Button";

import type { Translate, I18n } from "../types/I18n";
import type { TrendFilter as TypeTrendFilter } from "../types/TrendFilter";
import type { Language as TypeLanguage } from "../types/Language";

type Props = {
  tagList: TagModel[],
  values?: TypeTrendFilter,
  t: Translate,
  onSubmit: (filterData: TypeTrendFilter) => any,
  locale: TypeLanguage
};
type State = {};

@observer
class TrendForm extends Component<Props, State> {
  @observable
  selectedDate: Date = new Date();
  @observable
  firstTagId: string;
  @observable
  secondTagId: string;

  @action
  setSelectedDate = (date: Date) => (this.selectedDate = date);
  @action
  setFirstTagId = (e: any) => {
    this.firstTagId = e.target.value;
    this.secondTagId = "";
  };
  @action
  setSecondTagId = (e: any) => (this.secondTagId = e.target.value);
  @computed
  get isFormValid() {
    return this.selectedDate && this.firstTagId;
  }
  @computed
  get secondTagList() {
    return this.props.tagList.filter(t => t.id !== this.firstTagId);
  }

  componentDidMount() {
    const { values } = this.props;
    if (values) {
      this.selectedDate = values.date;
      this.firstTagId = values.firstTagId;
      this.secondTagId = values.secondTagId ? values.secondTagId : "";
    }
  }

  handleSubmitForm = () => {
    const filterData: TypeTrendFilter = {
      date: this.selectedDate,
      firstTagId: this.firstTagId,
      secondTagId: this.secondTagId
    };

    this.props.onSubmit(filterData);
  };

  render() {
    const { tagList, t, locale } = this.props;
    const maxDate = new Date(new Date().setDate(new Date().getDate()));

    return (
      <div className={"trend-form"}>
        <h3>{t("filter")}</h3>
        <DatePicker
          label={t("day")}
          maxDate={maxDate}
          iconName={"calendar"}
          selected={this.selectedDate}
          onChange={this.setSelectedDate}
          locale={locale}
        />
        <Select
          name={"tag1"}
          label={t("tag1")}
          value={this.firstTagId}
          emptyOption={true}
          options={tagList.map(tt => ({
            id: tt.id,
            value: tt.description
          }))}
          onChange={this.setFirstTagId}
        />

        {this.firstTagId && (
          <Select
            name={"tag2"}
            label={t("tag2")}
            value={this.secondTagId}
            emptyOption={true}
            options={this.secondTagList.map(tt => ({
              id: tt.id,
              value: tt.description
            }))}
            onChange={this.setSecondTagId}
          />
        )}

        <div className="btn-wrapper">
          <Button
            text={t("confirm")}
            onClick={this.handleSubmitForm}
            disabled={!this.isFormValid}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(TrendForm);
