/** @flow */
import React, { Component } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
registerLocale("it", it);
registerLocale("en", en);
registerLocale("es", es);

import "react-datepicker/dist/react-datepicker.css";

import type { Language as TypeLanguage } from "../types/Language";

type State = {
  active: boolean,
  isDevice: boolean
};
type Props = {
  name?: string,
  id?: string,
  error?: string,
  label?: string,
  placeholderText?: string,
  iconName?: string,
  iconSize?: number,
  selected?: Date | null,
  onChange?: (date: Date) => any,
  maxDate?: Date,
  dateFormat?: string,
  monthPicker?: boolean,
  locale?: TypeLanguage
};

class InputDatePicker extends Component<Props, State> {
  state = {
    active: false,
    isDevice: false
  };

  componentDidMount() {
    this.setState({
      isDevice: window.innerWidth < 768
    });
  }

  inputRef: ?any = null;

  handleFocus = () => {
    this.setState({
      active: true
    });
  };

  handleBlur = () => {
    this.setState({
      active: false
    });
  };

  handleClick = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  render() {
    const {
      id,
      name,
      label,
      error,
      iconName,
      maxDate,
      dateFormat,
      monthPicker,
      locale,
      ...otherProps
    } = this.props;

    let elId = id ? id : name;

    let _dateFormat = monthPicker
      ? "MM/yyyy"
      : dateFormat
      ? dateFormat
      : "dd/MM/yyyy";

    return (
      <div
        className={`form-item date-picker ${
          this.state.active && !error ? "active" : ""
        } ${error ? "error" : ""}`}
      >
        {label && <label htmlFor={elId}>{label}</label>}
        <div className="input-wrapper">
          <DatePicker
            locale={locale || "it"}
            dateFormat={_dateFormat}
            maxDate={maxDate}
            withPortal={this.state.isDevice}
            showMonthYearPicker={monthPicker}
            {...otherProps}
          />

          {iconName && (
            <i className={`icon-${iconName}`} onClick={this.handleClick} />
          )}

          {error && <div className="input-errors">{error}</div>}
        </div>
      </div>
    );
  }
}

export default InputDatePicker;
