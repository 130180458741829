/** @flow */

const truncDecimal = (value: number) =>
  typeof value === "number" ? Math.trunc(value) : value;

const formatNumber = (value: number) => truncDecimal(value).toLocaleString();

export default {
  truncDecimal,
  formatNumber
};
