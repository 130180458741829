/** @flow */
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { inject } from "mobx-react";
import { withTranslation } from "react-i18next";

import type { Stores } from "../types/Stores";
import type { CustomRoute as TypeCustomRoute } from "../types/CustomRoute";
import type { Translate, I18n } from "../types/I18n";
import type { Language as TypeLanguage } from "../types/Language";

import FullscreenWrapper from "../components/FullscreenWrapper";
import Splashscreen from "../components/Splashscreen";
import CustomRoute from "../components/CustomRoute";
import AddToHomeScreen from "../components/AddToHomeScreen";
import OfflineBanner from "../components/OfflineBanner";
import UpdatePWASnackbar from "../components/UpdatePWASnackbar";
import i18nService from "../services/i18n";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import Home from "./Home";
import MonthlyProductions from "./MonthlyProductions";
import Logout from "./Logout";
import PlantDetails from "./PlantDetails";
import PlantAnnualProductions from "./PlantAnnualProductions";
import PlantMonthlyProductions from "./PlantMonthlyProductions";
import PlantAlarms from "./PlantAlarms";
import PlantTrend from "./PlantTrend";
import PlantReportShutdowns from "./PlantReportShutdowns";
import PlantReportPdM from "./PlantReportPdM";
import PlantReportIndicators from "./PlantReportIndicators";
import PlantReportMonthly from "./PlantReportMonthly";
import PlantAnnualForecast from "./PlantAnnualForecast";
import PlantMonthlyForecast from "./PlantMonthlyForecast";
import PlantDailyForecast from "./PlantDailyForecast";
import PlantAnnualIndicators from "./PlantAnnualIndicators";
import PlantAnnualDisponibility from "./PlantAnnualDisponibility";
import PlantAnnualPerfomanceRatio from "./PlantAnnualPerfomanceRatio";
import PlantMonthlyPerfomanceRatio from "./PlantMonthlyPerformanceRatio";
import Profile from "./Profile";
import Preset from "./Preset";

type StoresProps = {
  initializeAuth: () => any,
  isLoadingSession: boolean,
  isPWAUpdateAvailable: boolean,
  sessionLanguage: TypeLanguage,
  reloadPWA: () => any
};
type OwnProps = { t: Translate, i18n: I18n };
type State = {};
type Props = StoresProps & OwnProps;

const routes: TypeCustomRoute[] = [
  {
    path: "/login",
    component: Login,
    private: false
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    private: false
  },
  {
    path: "/home",
    component: Home,
    private: true
  },
  {
    path: "/details/monthly-productions/:month",
    component: MonthlyProductions,
    private: true
  },
  {
    path: "/logout",
    component: Logout,
    private: false
  },
  {
    path: "/preset",
    component: Preset,
    private: true
  },
  {
    path: "/:plantId/plant-details",
    component: PlantDetails,
    private: true,
    authorization: true
  },
  {
    path: "/:plantId/productions/:year/:month",
    component: PlantMonthlyProductions,
    private: true
  },
  {
    path: "/:plantId/productions",
    exact: true,
    component: PlantAnnualProductions,
    private: true
  },
  {
    path: "/:plantId/alarms",
    component: PlantAlarms,
    private: true
  },
  {
    path: "/:plantId/trend",
    component: PlantTrend,
    private: true
  },
  {
    path: "/:plantId/report/shutdowns",
    component: PlantReportShutdowns,
    private: true
  },
  {
    path: "/:plantId/report/pdm",
    component: PlantReportPdM,
    private: true
  },
  {
    path: "/:plantId/report/indicators",
    component: PlantReportIndicators,
    private: true
  },
  {
    path: "/:plantId/report/monthly",
    component: PlantReportMonthly,
    private: true
  },
  {
    path: "/:plantId/forecast/:year/:month/:day",
    component: PlantDailyForecast,
    private: true
  },
  {
    path: "/:plantId/forecast/:year/:month",
    component: PlantMonthlyForecast,
    private: true
  },
  {
    path: "/:plantId/forecast",
    component: PlantAnnualForecast,
    private: true
  },
  {
    path: "/:plantId/statistics/indicators",
    component: PlantAnnualIndicators,
    private: true
  },
  {
    path: "/:plantId/statistics/disponibility",
    component: PlantAnnualDisponibility,
    private: true
  },
  {
    path: "/:plantId/statistics/pr/:year/:month",
    component: PlantMonthlyPerfomanceRatio,
    private: true
  },
  {
    path: "/:plantId/statistics/pr",
    component: PlantAnnualPerfomanceRatio,
    private: true
  },
  {
    path: "/profile",
    component: Profile,
    private: true
  }
];

const mapStoresToProps = (stores: Stores): StoresProps => ({
  initializeAuth: stores.auth.initialize,
  isLoadingSession: stores.auth.isLoadingSession,
  sessionLanguage: stores.auth.sessionLanguage,
  isPWAUpdateAvailable: stores.pwa.isUpdateAvailable,
  reloadPWA: stores.pwa.reload
});

@inject(mapStoresToProps)
class App extends Component<Props, State> {
  componentDidMount() {
    this.initializeApp();
  }

  initializeApp = async () => {
    const result = await this.props.initializeAuth();
    this.props.i18n.changeLanguage(this.props.sessionLanguage);
  };

  render() {
    const { isLoadingSession, isPWAUpdateAvailable, reloadPWA } = this.props;
    return (
      <FullscreenWrapper>
        {!isLoadingSession && (
          <Router basename={`${process.env.PUBLIC_URL || ""}`}>
            <Route
              exact
              path="/"
              component={() => (
                <Redirect
                  to={{
                    pathname: "/home"
                  }}
                />
              )}
            />

            {routes.map((route, i) => (
              <CustomRoute key={i} {...route} />
            ))}
          </Router>
        )}
        {isLoadingSession && <Splashscreen />}
        <AddToHomeScreen />
        <OfflineBanner />
        <UpdatePWASnackbar
          isVisible={isPWAUpdateAvailable}
          onClick={reloadPWA}
        />
      </FullscreenWrapper>
    );
  }
}

export default withTranslation("common")(App);
