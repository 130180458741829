/** @flow */

import timezones from "../config/timezones";

const getClientTimezone = () => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezones.indexOf(timezone) < 0) {
    timezone = "";
  }

  return timezone;
};

export default {
  getClientTimezone
};
