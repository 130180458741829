/** @flow */
import React from "react";

type Props = {
  title: string,
  metaList: { label?: string, icon?: boolean, value: string }[],
  classNames?: string[]
};

const Toolbar = ({ title, metaList = [], classNames = [] }: Props) => {
  const classes = ["toolbar", ...classNames].join(" ");
  return (
    <div className={classes}>
      <div className={"toolbar-title"}>{title}</div>
      <div className={"toolbar-meta"}>
        {metaList.map((metaData, index) => (
          <div className={"meta-group"} key={index}>
            {metaData.label && <span>{metaData.label}</span>}
            {metaData.icon && <div className={"meta-icon"} />}
            <div className={"meta-value"}>{metaData.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Toolbar;
