/** @flow */

import config from "../config/chart";

const getMaxSamplesNumber = () => {
  return parseInt(config.samples.limiter * window.innerWidth);
};

export default {
  getMaxSamplesNumber
};
