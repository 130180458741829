/** @flow */
import React from "react";

import type { IndicatorsLegend as TypeIndicatorsLegend } from "../types/IndicatorsLegend";

type Props = {
  items: TypeIndicatorsLegend
};

const IndicatorsLegend = (props: Props) => (
  <div className={"indicators-legend-container"}>
    {props.items.series.map((serie, idx) => (
      <div key={idx} className={"indicators-legend-item"}>
        <div className={"item-icon"} />
        <span className={"item-label"}>{serie}</span>
      </div>
    ))}
  </div>
);

export default IndicatorsLegend;
