/** @flow */
import React from "react";

type Props = { type: string, message: string };

const mapIcons = {
  err: "attention-alt",
  info: "info",
  done: "ok"
};

const MessageBox = (props: Props) => {
  if (!props.message || props.message.length === 0) {
    return "";
  }

  return (
    <div className={`form-msg type-${props.type}`}>
      <i className={`icon-${mapIcons[props.type]}`} />
      <div>{props.message}</div>
    </div>
  );
};

export default MessageBox;
