/** @flow */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import type { Translate, I18n } from "../types/I18n";

type Props = {
  t: Translate,
  i18n: I18n,
  isVisible: boolean,
  onClick: () => any
};
type State = {};

class UpdatePWASnackbar extends Component<Props, State> {
  render() {
    const { isVisible, onClick, t } = this.props;

    return (
      <div
        className={`update-pwa-snackbar ${isVisible ? "show" : ""}`}
        onClick={onClick}
      >
        {t("message")}
      </div>
    );
  }
}

export default withTranslation("upwas")(UpdatePWASnackbar);
