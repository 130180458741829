/** @flow @format */
export default {
  auth: {
    username: "Usuario",
    password: "Contraseña",
    timezone: "Timezone",
    login: "Login",
    lostPassword: "¿Has olvidado tu contraseña?",
    backToLogin: "Volver al login",
    errors: {
      badCredentials: "usuario y/o contraseña incorrectos",
      unauthorized: "Acceso denegado.",
      password: {
        ERR_OLD_PASSWORD_INVALID: "La contraseña actual es incorrecta",
        ERR_SAME_PASSWORD:
          "La nueva contraseña debe ser diferente de la actual",
        ERR_CONFIRM_PASSWORD_INVALID:
          "La nueva contraseña debe ser la misma que la de confirmación",
        ERR_PASSWORD_TO_SIMPLE:
          "La nueva contraseña no cumple con los criterios de complejidad requeridos"
      }
    },
    resetPassword: "Restablecer la contraseña",
    messages: {
      resetDone:
        "La contraseña ha sido restablecida y enviada por correo electrónico!",
      changePswDone: "Contraseña actualizada correctamente",
      sessionExpired: "La sesión ha expirado"
    }
  },
  menu: {
    home: "Inicio",
    plant: "Planta",
    trend: "Tendencia",
    alarms: "Alarmas",
    monitoring: "Monitoreo",
    productions: "Producciones",
    forecast: "Forecast",
    indicators: "Indicadores",
    prodHoursCapFact: "Prod./Hor. Eq./Fact. Cap.",
    disponibility: "Disponibidad",
    performanceRatio: "Performance Ratio",
    report: "Informe",
    shutdowns: "Paradas",
    pdm: "Diario de Planta",
    monthly: "Mensual",
    accountMng: "Gestion de usuario",
    changePsw: "Cambiar contraseña",
    changeLng: "Cambiar idioma",
    preset: "Preset",
    logout: "Logout"
  },
  alarm: {
    states: {
      inProgress: "En curso",
      acknowledged: "En curso",
      toBeAcknowledged: "Devuelto"
    },
    status: "Estado",
    startDate: "Fecha de entrada",
    endDate: "Fecha de salida",
    ackDate: "Fecha de reconocimiento",
    area: "Zona",
    class: "Tipo",
    description: "Identificación",
    description2: "Descripción",
    noAlarms: "Nessun allarme presente",
    ackComment: "Comentario de reconocimiento",
    ack: "ACK",
    ackCommentMessage: "Añadir un comentario",
    details: "Detalle allarma",
    private: "Privados"
  },
  aths: {
    title: "Añadir e-guardian a la pantalla de inicio",
    info:
      "Presiona <i class='icon-navigation_action' /> y luego Añadir a Inicio"
  },
  ob: {
    message: "La aplicación está offline"
  },
  upwas: {
    message:
      "Una nueva versión de la aplicación está disponible. Haga clic aquí para actualizar"
  },
  common: {
    power: "Potencia",
    energy: "Energía",
    forecastPower: "Forecast Potencia",
    forecastEnergy: "Forecast Energía",
    currentPower: "Potencia actual",
    totalPower: "Potencia total",
    dailyProduction: "Prod. del día",
    annualProduction: "Producción anual",
    monthlyProduction: "Producción mensual",
    plants: "Plantas",
    production: "Producción",
    productions: "Producciones",
    plantDetails: "Planta",
    totalProductions: "Producciones totales",
    totals: "Totales",
    monthlyProductions: "{{month}} {{year}}",
    totalProduction: "Producción total",
    kWValue: "{{value}} kW",
    kWhValue: "{{value}} kWh",
    hValue: "{{value}} h",
    percValue: "{{value}} %",
    noData: "No hay datos disponibles",
    changePlant: "Cambiar planta",
    confirm: "Confimación",
    alarms: "Alarmas",
    trend: "Tendencia",
    day: "Día",
    hour: "Hora",
    tag1: "Tag 1",
    tag2: "Tag 2",
    filter: "Filtro",
    emptyTrendFilter: "Para mostrar la tendencia, establecer un filtro",
    report: "Informe",
    shutdowns: "Paradas",
    pdm: "Diario de Planta",
    indicators: "Indicadores",
    monthly: "Mensual",
    startDate: "Día inicial:",
    endDate: "Día final:",
    generateReport: "Generar informe",
    includePdMOpen: "Incluir abiertos",
    includePdMWiP: "Incluir en procesamiento",
    pdMType: "Tipo",
    pdMTypeOrdinary: "Ordinario",
    pdMTypeExtraordinary: "Extraordinario",
    totalEnergy: "Energía total",
    produced: "Producida",
    exptected: "Esperada",
    producedEnergyLbl: "Energía producida",
    exptectedEnergyLbl: "Energía esperada",
    annualForecast: "Forecast - Energía total mensual",
    monthlyForecast: "Forecast - Energía total diaria",
    dailyChartForecast: "Forecast - Gráfico diario",
    dailyDetail: "{{dayExt}} {{day}} {{month}} {{year}}",
    prodEqHoursCapFact: "Prod./Hor. Eq./Fact. Cap.",
    eqHours: "Horas equivalentes",
    capFactor: "Factor de capacidad",
    maximal: "Techos",
    eqHoursValue: "Horas equivalentes",
    disponibility: "Disponibilidad",
    faults: "Fallas",
    faultsAndSchedShutdowns: "Fallas y paradas programadas",
    overall: "Total",
    yes: "Si",
    no: "No",
    performanceRatio: "Performance Ratio",
    dailyPerformanceRatio: "Performance Ratio diario",
    accountMng: "Gestion de usuario",
    preset: "Preset",
    setPreset: "Establecer preset",
    resetPreset: "Eliminar preset activo",
    user: "Usuario",
    settings: "Ajustes",
    changePassword: "Cambiar contraseña",
    changeLanguage: "Cambiar idioma",
    oldPassword: "Contraseña actual",
    newPassword: "Nueva contraseña",
    confirmNewPassword: "Confirmar nueva contraseña",
    selectLanguage: "Seleccionar el idioma",
    languageChanged: "Idioma cambiado correctamente",
    presetChanged: "Actualización preset ejecutada correctamente",
    performanceRatioNotAvailable:
      "Datos no disponibles para este tipo de planta",
    calendar: {
      year: "Año",
      month: "Mes",
      months: {
        "1": "Ene",
        "2": "Feb",
        "3": "Mar",
        "4": "Abr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Ago",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dic"
      },
      days: {
        "0": "Dom",
        "1": "Lun",
        "2": "Mar",
        "3": "Mie",
        "4": "Jue",
        "5": "Vie",
        "6": "Sab"
      }
    },
    mu: {
      kw: "kW",
      kwh: "kWh"
    },
    lng: {
      it: "Italiano",
      en: "English",
      es: "Español"
    },
    requestError: "Error genérico",
    reportError: "Informe no disponible para las fechas indicadas",
    requestOk: "Operación realizada correctamente",
    notAvailable: "N.D."
  }
};
