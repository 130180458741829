/** @flow */
import { action, observable, computed } from "mobx";
import api from "../services/api";
import language from "../utils/language";
import _ from "lodash";
import i18nService from "../services/i18n";

import PlantModel from "../models/Plant";

import PlantsStore from "./Plants";

import type { User as TypeUser } from "../types/User";
import type { Abilitazione as TypeAbilitazione } from "../types/Abilitazione";
import type { Language as TypeLanguage } from "../types/Language";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { PresetDTO as TypePreset } from "../types/PresetDTO";

const LS_LANGUAGE_KEY = "client-language",
  LS_PLANT_ID = "client-plant-id";

export default class Auth {
  plantsStore: PlantsStore;

  @observable
  isLoadingSession: boolean = true;
  @observable
  isSessionExpired: boolean = false;

  @observable
  isLoggingIn: boolean = false;
  @observable
  user: TypeUser = {};
  @observable
  modulesPermissions: TypeAbilitazione[];
  @observable
  currentModule: string;

  @observable
  isResetPending: boolean = false;

  @observable
  sessionLanguage: TypeLanguage;
  @observable
  presetList: TypePreset[] | null = null;

  constructor(plantsStore: PlantsStore) {
    this.plantsStore = plantsStore;
    this.sessionLanguage =
      // $FlowFixMe
      localStorage.getItem(LS_LANGUAGE_KEY) || language.getClientLanguage();
  }

  _fetchModulesPermissions = async () => {
    const res = await api.getAbilitazioni();

    if (!res.success) {
      throw new Error(res.error);
    }

    this.modulesPermissions = res.result;
  }; // _fetchModulesPermissions

  _setSessionLanguage = async (language: TypeLanguage) => {
    this.sessionLanguage = language;

    const response = await api.setLocale(language);

    if (response.success) {
      localStorage.setItem(LS_LANGUAGE_KEY, language);
    }

    return response;
  };

  @action
  setCurrentModule = (moduleName: string, plantId?: ?string): string => {
    this.currentModule = moduleName;

    if (plantId && plantId !== "-1") {
      localStorage.setItem(LS_PLANT_ID, plantId);
    } else if (plantId === "-1") {
      localStorage.removeItem(LS_PLANT_ID);
    }

    return this.currentModule;
  };

  @action
  initialize = async () => {
    this.isLoadingSession = true;
    if (await this.loadCurrentUserData()) {
      await this._fetchModulesPermissions();
      await this.plantsStore.fetchUserPlants();
    }
    this.isLoadingSession = false;
  };

  @action
  loadCurrentUserData = async () => {
    let result = false;
    try {
      const userData = await api.getCurrentUser();

      if (userData.success) {
        this.user = userData.result;
        result = true;
      }
    } catch (err) {
      console.log(err);
    }
    return result;
  };

  @action
  login = async (
    username: string,
    password: string,
    timezone: string
  ): Promise<TypeResultMessage> => {
    this.isLoggingIn = true;
    // Reset the session state
    this.isSessionExpired = false;

    let result = { success: true };

    try {
      const loginRes = await api.login(username, password);

      if (!loginRes.success) {
        let errorMessage = i18nService.t("common:requestError");

        if(loginRes.error === "LOGIN.ERROR.BAD_CREDENTIALS"){
          errorMessage = i18nService.t("auth:errors:badCredentials");
        } else if(loginRes.error === "LOGIN.ERROR.UNAUTHORIZED"){
          errorMessage = i18nService.t("auth:errors:unauthorized");
        }
        result = {
          success: false,
          type: "err",
          message: errorMessage
        };
      } else {
        const localeResponse = await this._setSessionLanguage(
          this.sessionLanguage
        );

        const timezoneResponse = await api.setTimezone(timezone);

        await this._fetchModulesPermissions();
        await this.plantsStore.fetchUserPlants();
        await this.loadCurrentUserData();
      }
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError")
      };
    }

    this.isLoggingIn = false;
    return result;
  }; // login

  @action
  resetPassword = async (username: string): Promise<TypeResultMessage> => {
    this.isResetPending = true;

    let result = {
      success: true,
      type: "done",
      message: i18nService.t("auth:messages:resetDone")
    };

    try {
      const resetResponse = await api.resetPassword(username);

      if (!resetResponse.success) {
        throw Error(resetResponse.error);
      }
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError")
      };
    }

    this.isResetPending = false;

    return result;
  }; // resetPassword

  @action
  changePassword = async (
    newPassword: string,
    oldPassword: string
  ): Promise<TypeResultMessage> => {
    let result = {
      success: true,
      type: "done",
      message: i18nService.t("auth:messages:changePswDone")
    };

    try {
      const response = await api.changePassword(newPassword, oldPassword);

      if (!response.success) {
        throw Error(response.error);
      }
    } catch (err) {
      let message = i18nService.t("common:requestError");

      if (
        [
          "ERR_OLD_PASSWORD_INVALID",
          "ERR_SAME_PASSWORD",
          "ERR_CONFIRM_PASSWORD_INVALID",
          "ERR_PASSWORD_TO_SIMPLE"
        ].indexOf(err.message) != -1
      ) {
        message = i18nService.t(`auth:errors:password:${err.message}`);
      }

      result = {
        success: false,
        type: "err",
        error: err.message,
        message
      };
    }

    return result;
  }; // changePassword

  @action
  changeLanguage = async (lng: TypeLanguage): Promise<TypeResultMessage> => {
    let result = {
      success: true,
      type: "done",
      message: i18nService.t("common:languageChanged")
    };

    try {
      const response = await this._setSessionLanguage(lng);

      if (!response.success) {
        throw Error(response.error);
      }
    } catch (error) {
      result = {
        success: false,
        type: "err",
        error,
        message: i18nService.t("common:requestError")
      };
    }

    return result;
  }; // changeLanguage

  @action
  getLastActivePlantId = (): string => {
    return localStorage.getItem(LS_PLANT_ID) || "";
  };

  @action
  logout = async () => {
    const logoutResponse = await api.logout();
    this.user = {};
    localStorage.removeItem(LS_PLANT_ID);
  };

  @action
  markSessionAsExpired = () => {
    this.logout();
    this.isSessionExpired = true;
  };

  @computed
  get isLoggedIn(): boolean {
    return !_.isEmpty(this.user);
  }

  @computed
  get plantsAllowed(): PlantModel[] {
    const mP = this.modulesPermissions.find(
      mP => mP.modulo === this.currentModule
    );

    let pAId = [];

    if (mP) {
      pAId = mP.impianti;
    }

    let listPlantsAllowed = [];

    if (this.plantsStore.plants) {
      listPlantsAllowed = this.plantsStore.plants.filter(
        plant => pAId.indexOf(plant.id) !== -1
      );
    }

    return listPlantsAllowed;
  } // plantsAllowed

  @action
  isPlantEnabledOnModule = (plantId: string, moduleName: string) => {
    const modulePermissions = this.modulesPermissions.find(
      mP => mP.modulo === moduleName
    );

    return modulePermissions
      ? modulePermissions.impianti.includes(plantId)
      : false;
  }; // isPlantEnabledOnModule

  @action
  fetchPresetList = async (): Promise<TypeResultMessage> => {
    let result = {
      success: true
    };
    try {
      const response = await api.getListaPreset();

      if (!response.success) {
        throw Error(response.error);
      }

      this.presetList = response.result;
    } catch (error) {
      result = {
        success: false,
        type: "err",
        error,
        message: i18nService.t("common:requestError")
      };
    }

    return result;
  };

  @action
  setPreset = async (presetId: ?string): Promise<TypeResultMessage> => {
    let result = {
      success: true,
      type: "done",
      message: i18nService.t("common:presetChanged")
    };

    try {
      const response = await api.setPreset(presetId);

      if (!response.success) {
        throw Error(response.error);
      }
      // Prefetch user plants
      await this.plantsStore.fetchUserPlants();
    } catch (error) {
      result = {
        success: false,
        type: "err",
        error,
        message: i18nService.t("common:requestError")
      };
    }

    return result;
  }; // setPreset

  @computed
  get isCustomer(): boolean {
    return this.user.ruolo === "CLIENTE";
  } // isCustomer
}
