/** @flow */
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import type { Translate, I18n } from "../types/I18n";

type Props = { t: Translate };
type State = {
  offline: boolean
};

class OfflineBanner extends Component<Props, State> {
  state = {
    offline: false
  };

  setOfflineStatus = () => {
    this.setState({ offline: true });
  };

  setOnlineStatus = () => {
    this.setState({ offline: false });
  };

  componentDidMount() {
    window.addEventListener("online", this.setOnlineStatus);
    window.addEventListener("offline", this.setOfflineStatus);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.setOnlineStatus);
    window.removeEventListener("offline", this.setOfflineStatus);
  }

  render() {
    const { offline } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        {offline && <div className={"offline-banner"}>{t("message")}</div>}
      </Fragment>
    );
  }
}

export default withTranslation("ob")(OfflineBanner);
