export default [
  {
    key: "HOME",
    label: "home",
    path: "home",
    default: true
  },
  {
    key: "MONITOR",
    label: "plant",
    path: ":plantId/plant-details"
  },
  {
    key: "TREND",
    label: "trend",
    path: ":plantId/trend"
  },
  {
    key: "ALLARMI",
    label: "alarms",
    path: ":plantId/alarms"
  },
  {
    key: "PRODUZIONI",
    label: "productions",
    path: ":plantId/productions",
    group: "monitoring"
  },
  {
    key: "FORECAST",
    label: "forecast",
    path: ":plantId/forecast",
    group: "monitoring"
  },
  {
    key: "INDICATORI",
    label: "prodHoursCapFact",
    path: ":plantId/statistics/indicators",
    group: "indicators"
  },
  {
    key: "INDICATORI",
    label: "disponibility",
    path: ":plantId/statistics/disponibility",
    group: "indicators"
  },
  {
    key: "INDICATORI",
    label: "performanceRatio",
    path: ":plantId/statistics/pr",
    group: "indicators",
    exp: (props) => props.plant && props.plant.type === "Fotovoltaico"
  },
  {
    key: "FERMI",
    label: "shutdowns",
    path: ":plantId/report/shutdowns",
    group: "report"
  },
  {
    key: "PDM",
    label: "pdm",
    path: ":plantId/report/pdm",
    group: "report"
  },
  {
    key: "INDICATORI",
    label: "indicators",
    path: ":plantId/report/indicators",
    group: "report"
  },
  {
    key: "REPORT_MENSILE",
    label: "monthly",
    path: ":plantId/report/monthly",
    group: "report"
  },
  {
    key: "CHANGE_PSW",
    label: "changePsw",
    path: "profile?view=user",
    group: "accountMng",
    default: true
  },
  {
    key: "CHANGE_LNG",
    label: "changeLng",
    path: "profile?view=settings",
    group: "accountMng",
    default: true
  },
  {
    key: "PRESET",
    label: "preset",
    path: "preset",
    group: "accountMng",
    default: true
  },
  {
    key: "LOGOUT",
    label: "logout",
    path: "logout",
    default: true
  }
];
