/** @flow */
import React, { Component } from "react";

import ProgressBar from "./ProgressBar";

import type { Indicator as TypeIndicator } from "../types/Indicator";

type Props = {
  data: TypeIndicator
};
type State = {};

class Indicator extends Component<Props, State> {
  render() {
    const { data } = this.props;
    return (
      <div className={"indicator-container"}>
        <div className={"indicator-lbl"}>
          {data.label.split(" ").map((lbl, idx) => (
            <span key={idx}>{lbl}</span>
          ))}
        </div>
        <div className={"indicator-pbars-wrapper"}>
          {data.samples.map((data, idx) => (
            <ProgressBar
              key={idx}
              value={data.value}
              max={data.max}
              label={data.label}
              valid={data.valid}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Indicator;
