/** @flow */
import { observable } from "mobx";
import api from "../services/api";
import i18nService from "../services/i18n";

import alarmConfig from "../config/alarms";

import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { AllarmeDTO as TypeAllarmeDTO } from "../types/AllarmeDTO";

export default class Alarm {
  @observable
  id: number;
  @observable
  className: string;
  @observable
  description: string;
  @observable
  ackTimestamp: number;
  @observable
  startTimestamp: number;
  @observable
  endTimestamp: number;
  @observable
  classColor: string;
  @observable
  area: ?string;
  @observable
  description2: ?string;
  @observable
  ackComment: ?string;
  @observable
  ackable: ?boolean;
  @observable
  plantName: ?string;

  constructor(
    id: number,
    className: string,
    description: string,
    ackTimestamp: number,
    startTimestamp: number,
    endTimestamp: number,
    classColor: string,
    plantName: ?string
  ) {
    this.id = id;
    this.className = className;
    this.description = description;
    this.ackTimestamp = ackTimestamp;
    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
    this.classColor = classColor;
    this.plantName = plantName;
    this.area = null;
    this.description2 = null;
    this.ackComment = null;
    this.ackable = false;
  }

  get ackDateTime() {
    return this.ackTimestamp
      ? new Date(this.ackTimestamp).toLocaleString()
      : null;
  }

  get startDateTime() {
    return this.startTimestamp
      ? new Date(this.startTimestamp).toLocaleString()
      : null;
  }

  get endDateTime() {
    return this.endTimestamp
      ? new Date(this.endTimestamp).toLocaleString()
      : null;
  }

  get styleColor() {
    return this.classColor
      ? alarmConfig.colors[this.classColor] || "inherit"
      : "inherit";
  }

  get status() {
    let status = "";

    if (
      this.startTimestamp !== null &&
      this.endTimestamp == null &&
      this.ackTimestamp == null
    ) {
      status = "inProgress";
    } else if (
      this.startTimestamp !== null &&
      this.endTimestamp !== null &&
      this.ackTimestamp === null
    ) {
      status = "toBeAcknowledged";
    } else if (
      this.startTimestamp !== null &&
      this.ackTimestamp !== null &&
      this.endTimestamp === null
    ) {
      status = "acknowledged";
    }

    return status;
  }

  async fetchDetails(): Promise<TypeResultMessage> {
    let result = { success: true };

    if (!this.area && !this.description2) {
      try {
        const res = await api.getDettaglioAllarme(this.id);

        if (!res.success) {
          throw new Error(res.error);
        }

        const remoteAlarmDetails: TypeAllarmeDTO = res.result;

        this.area = remoteAlarmDetails.area;
        this.description2 = remoteAlarmDetails.descrizione2;
        this.ackComment = remoteAlarmDetails.commentoAck;
        this.ackable = remoteAlarmDetails.ackable;
      } catch (err) {
        result = {
          success: false,
          type: "err",
          error: err.message,
          message: i18nService.t("common:requestError")
        };
      }
    }

    return result;
  }

  async submitAckMessage(message: string): Promise<TypeResultMessage> {
    let result = { success: true };

    try {
      const res = await api.submitMessaggioAckAllarme(this.id, message);

      if (!res.success) {
        throw new Error(res.error);
      }

      result = {
        success: true,
        type: "done",
        message: i18nService.t("common:requestOk")
      };

      this.ackable = false;
      this.ackComment = message;
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError")
      };
    }

    return result;
  }
}
