/** @flow */
import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import AlarmModel from "../models/Alarm";

import AlarmItem from "./AlarmItem";
import Drawer from "./Drawer";
import AlarmDetails from "./AlarmDetails";
import MessageBox from "./MessageBox";

import i18nService from "../services/i18n";

type Props = {
  alarms: ?(AlarmModel[]),
  isFetching: boolean,
  onSubmit: () => any
};
type State = {};

@observer
class AlarmList extends Component<Props, State> {
  @observable
  visibleDrawer: boolean = false;
  @observable
  selectedAlarm: ?AlarmModel;

  handleClick = (alarmId: number) => {
    const alarm = this.props.alarms.find(a => a.id === alarmId);

    if (alarm) {
      this.toggleDrawer();
      this.selectedAlarm = alarm;
    }
  };

  toggleDrawer = () => {
    this.visibleDrawer = !this.visibleDrawer;
  };

  render() {
    const { alarms, isFetching, onSubmit } = this.props;
    return (
      <div className={"content lr-pdd alarm-list"}>
        {alarms &&
          alarms.map(alarm => (
            <AlarmItem
              key={alarm.id}
              data={alarm}
              onClick={() => this.handleClick(alarm.id)}
            />
          ))}
        {!isFetching && alarms && alarms.length === 0 && (
          <MessageBox type={"info"} message={i18nService.t("alarm:noAlarms")} />
        )}

        <Drawer
          open={this.visibleDrawer}
          position={"right"}
          toggle={this.toggleDrawer}
          className={["drawer-lg"]}
        >
          <div className="drawer-header">
            <button className="btt-close" onClick={this.toggleDrawer}>
              <i className={"icon-cancel"} />
            </button>
          </div>
          <div className={"drawer-content"}>
            <AlarmDetails alarm={this.selectedAlarm} onSubmit={onSubmit} />
          </div>
        </Drawer>
      </div>
    );
  }
}

export default AlarmList;
