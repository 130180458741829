/** @flow @format */
export default {
  auth: {
    username: "Username",
    password: "Password",
    timezone: "Timezone",
    login: "Login",
    lostPassword: "Hai dimenticato la password?",
    backToLogin: "Torna al login",
    errors: {
      badCredentials: "Username e/o password non corretti.",
      unauthorized: "Accesso non autorizzato.",
      password: {
        ERR_OLD_PASSWORD_INVALID: "La vecchia password non è corretta",
        ERR_SAME_PASSWORD:
          "La nuova password deve essere diversa da quella attuale",
        ERR_CONFIRM_PASSWORD_INVALID:
          "La nuova password deve essere uguale a quella di conferma",
        ERR_PASSWORD_TO_SIMPLE:
          "La nuova password non soddisfa i criteri di complessità richiesti"
      }
    },
    resetPassword: "Reimposta la password",
    messages: {
      resetDone: "La password è stata reimpostata ed inviata per mail!",
      changePswDone: "Password aggiornata correttamente",
      sessionExpired: "La sessione è scaduta"
    }
  },
  menu: {
    home: "Home",
    plant: "Impianto",
    trend: "Trend",
    alarms: "Allarmi",
    monitoring: "Monitoraggio",
    productions: "Produzioni",
    forecast: "Forecast",
    indicators: "Indicatori",
    prodHoursCapFact: "Prod./Ore Eq./Fatt. Cap.",
    disponibility: "Disponibilità",
    performanceRatio: "Performance Ratio",
    report: "Report",
    shutdowns: "Fermi",
    pdm: "Giornale di Centrale",
    monthly: "Mensile",
    accountMng: "Gestione utente",
    changePsw: "Cambio password",
    changeLng: "Cambio lingua",
    preset: "Preset",
    logout: "Logout"
  },
  alarm: {
    states: {
      inProgress: "In corso",
      acknowledged: "In corso",
      toBeAcknowledged: "Rientrato"
    },
    status: "Stato",
    startDate: "Data inizio",
    endDate: "Data fine",
    ackDate: "Data presa visione",
    area: "Area",
    class: "Classe",
    description: "Identificazione",
    description2: "Descrizione",
    noAlarms: "Nessun allarme presente",
    ackComment: "Commento presa visione",
    ack: "ACK",
    ackCommentMessage: "Aggiungi un commento",
    details: "Dettaglio allarme",
    private: "Privato"
  },
  aths: {
    title: "Aggiungi e-gaurdian alla schermata Home",
    info: "Premi <i class='icon-navigation_action' /> e poi Aggiungi a Home"
  },
  ob: {
    message: "L'app è offline"
  },
  upwas: {
    message:
      "E' disponibile una nuova versione dell'app. Clicca qui per aggiornare."
  },
  common: {
    power: "Potenza",
    energy: "Energia",
    forecastPower: "Forecast Potenza",
    forecastEnergy: "Forecast Energia",
    currentPower: "Potenza attuale",
    totalPower: "Potenza totale",
    dailyProduction: "Prod. Odierna",
    annualProduction: "Produzione annuale",
    monthlyProduction: "Produzione mensile",
    plants: "Impianti",
    production: "Produzione",
    productions: "Produzioni",
    plantDetails: "Dettagli impianto",
    totalProductions: "Produzioni totali",
    totals: "Totali",
    monthlyProductions: "{{month}} {{year}}",
    totalProduction: "Produzione totale",
    kWValue: "{{value}} kW",
    kWhValue: "{{value}} kWh",
    hValue: "{{value}} h",
    percValue: "{{value}} %",
    noData: "Nessun dato disponibile",
    changePlant: "Cambia impianto",
    confirm: "Conferma",
    alarms: "Allarmi",
    trend: "Trend",
    day: "Giorno",
    hour: "Ora",
    tag1: "Tag 1",
    tag2: "Tag 2",
    filter: "Filtro",
    emptyTrendFilter: "Per visualizzare il trend imposta un filtro",
    report: "Report",
    shutdowns: "Fermi",
    pdm: "Giornale di Centrale",
    indicators: "Indicatori",
    monthly: "Mensile",
    startDate: "Giorno Inizio:",
    endDate: "Giorno Fine:",
    generateReport: "Genera report",
    includePdMOpen: "Includi Aperti",
    includePdMWiP: "Includi in Lavorazione",
    pdMType: "Tipologia",
    pdMTypeOrdinary: "Ordinario",
    pdMTypeExtraordinary: "Straordinario",
    totalEnergy: "Totale energia",
    produced: "Prodotta",
    exptected: "Prevista",
    producedEnergyLbl: "Energia prodotta",
    exptectedEnergyLbl: "Energia prevista",
    annualForecast: "Forecast - Totale energia mensile",
    monthlyForecast: "Forecast - Totale energia giornaliera",
    dailyChartForecast: "Forecast - Grafico giornaliero",
    dailyDetail: "{{dayExt}} {{day}} {{month}} {{year}}",
    prodEqHoursCapFact: "Prod./Ore Eq./Fatt. Cap.",
    eqHours: "Ore equivalenti",
    capFactor: "Fattore capacità",
    maximal: "Massimali",
    eqHoursValue: "Ore equivalenti",
    disponibility: "Disponibilità",
    faults: "Guasti",
    faultsAndSchedShutdowns: "Guasti e fermi programmati",
    overall: "Complessiva",
    yes: "Si",
    no: "No",
    performanceRatio: "Performance Ratio",
    dailyPerformanceRatio: "Performance Ratio Giornaliera",
    accountMng: "Gestione utente",
    preset: "Preset",
    setPreset: "Imposta preset",
    resetPreset: "Rimuovi preset attivo",
    user: "Utente",
    settings: "Impostazioni",
    changePassword: "Cambio password",
    changeLanguage: "Cambio lingua",
    oldPassword: "Vecchia password",
    newPassword: "Nuova password",
    confirmNewPassword: "Conferma nuova password",
    selectLanguage: "Seleziona la lingua",
    languageChanged: "Lingua cambiata correttamente",
    presetChanged: "Aggiornamento preset eseguito correttamente",
    performanceRatioNotAvailable:
      "Dato non disponibile per questa tipologia di impianto",
    calendar: {
      year: "Anno",
      month: "Mese",
      months: {
        "1": "Gen",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "Mag",
        "6": "Giu",
        "7": "Lug",
        "8": "Ago",
        "9": "Set",
        "10": "Ott",
        "11": "Nov",
        "12": "Dic"
      },
      days: {
        "0": "Dom",
        "1": "Lun",
        "2": "Mar",
        "3": "Mer",
        "4": "Gio",
        "5": "Ven",
        "6": "Sab"
      }
    },
    mu: {
      kw: "kW",
      kwh: "kWh"
    },
    lng: {
      it: "Italiano",
      en: "English",
      es: "Español"
    },
    requestError: "Errore generico durante l'esecuzione della richiesta",
    reportError: "Report non disponibile per le date indicate",
    requestOk: "Operazione eseguita correttamente",
    notAvailable: "N.D."
  }
};
