export default {
  colors: {
    GREEN: "#71B55E",
    YELLOW: "#EBF000",
    ORANGE: "#DB7A27",
    RED: "#D82C2B",
    BLUE: "#357DDB",
    DARK_GREEN: "#619C51",
    DARK_YELLOW: "#D3D600",
    DARK_ORANGE: "#C26D23",
    DARK_RED: "#BF2626",
    DARK_BLUE: "#2F6EC2",
    BLACK: "#000",
    GRAY: "#757575"
  }
};
