/** @flow */
import React from "react";
import { withTranslation } from "react-i18next";

import type { Translate, I18n } from "../types/I18n";

import AlarmModel from "../models/Alarm";

type Props = {
  data: AlarmModel,
  t: Translate,
  onClick: (alarmId: number) => any
};

const AlarmItem = (props: Props) => {
  const { data, t, onClick } = props;

  return (
    <div className={"alarm-item"} onClick={onClick}>
      <div className={"alarm-item-header"}>
        <div className="alarm-item-meta-wrapper">
          <i className={"icon-alarm"} style={{ color: data.styleColor }} />
          <span className={"alarm-item-date"}>{data.startDateTime}</span>
          {data.plantName && (
            <span className={"alarm-item-plant-name"}>{data.plantName}</span>
          )}
        </div>
      </div>
      <div className={"alarm-item-content"}>
        <div
          className={"alarm-item-meta-ack"}
          style={{ borderColor: data.styleColor }}
        >
          {data.ackDateTime && <i className={"icon-worker"} />}
        </div>
        <div className={"alarm-item-description"}>{data.description}</div>
      </div>
      <div className={"alarm-item-footer"}>
        <div className="alarm-item-meta-wrapper">
          <i
            className={`icon-${
              data.endDateTime ? "dot-circled" : "circle-empty"
            }`}
            style={{ color: data.styleColor }}
          />
          <span className={"alarm-item-date"}>{data.endDateTime}</span>
        </div>

        <div className="alarm-item-meta-wrapper">
          {data.status && (
            <span className={"alarm-item-meta"}>
              {t(`states.${data.status}`)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation("alarm")(AlarmItem);
