/** @flow */
import { action, observable } from "mobx";
import api from "../services/api";

import i18nService from "../services/i18n";

import utilText from "../utils/text";

import type { ImpiantoDTO as TypeImpiantoDTO } from "../types/ImpiantoDTO";
import type { StatisticheDTO as TypeStatisticheDTO } from "../types/StatisticheDTO";
import type { StatisticheImpiantoDTO as TypeStatisticheImpiantoDTO } from "../types/StatisticheImpiantoDTO";
import type { ProduzioneDTO as TypeProduzioneDTO } from "../types/ProduzioneDTO";
import type { AllarmeDTO as TypeAllarmeDTO } from "../types/AllarmeDTO";
import type { PlantStatistics as TypePlantStatistics } from "../types/PlantStatistics";
import type { Indicator as TypeIndicator } from "../types/Indicator";
import type { ClasseAllarmeDTO as TypeClasseAllarmeDTO } from "../types/ClasseAllarmeDTO";

import type { ResultMessage } from "../types/ResultMessage";
import PlantModel from "../models/Plant";
import AlarmModel from "../models/Alarm";

export default class Plants {
  @observable
  plants: PlantModel[] | null = null;

  @observable
  isFetchingStatistics: boolean = true;

  // Plants statistics
  @observable
  currentPower: number = 0;

  // Annual Productions
  @observable
  annualProductions: TypeIndicator[] | null = null;
  @observable
  annualTotalProduction: number = 0;
  @observable
  isFetchingAnnualProd: boolean = false;

  // Monthly Productions
  @observable
  isFetchingMonthlyProductions: boolean = false;
  @observable
  monthlyProductions: TypeIndicator[] | null = null;
  @observable
  monthlyTotalProduction: number = 0;

  // User Alarms
  @observable
  isFetchingUserAlarms: boolean = false;
  @observable
  userAlarms: AlarmModel[] = [];

  @action
  fetchUserPlants = async () => {
    let _plants: PlantModel[] = [];
    const remoteLI = await api.getListaImpianti();

    if (remoteLI.success) {
      const _plantsList: TypeImpiantoDTO[] = remoteLI.result;

      _plants = _plantsList.map(
        plant =>
          new PlantModel(
            plant.id,
            plant.codice,
            plant.nome,
            plant.tipologia,
            plant.abilitazioni
          )
      );
    }

    this.plants = _plants;
  };

  @action
  fetchPlantsStatistics = async (): Promise<ResultMessage> => {
    this.isFetchingStatistics = true;
    let result = { success: true };

    let _plants: PlantModel[] = [];

    try {
      const resFSI = await api.getStatisticheImpianti();

      if (!resFSI.success) {
        throw Error(resFSI.error);
      }

      let remoteStats: TypeStatisticheDTO = resFSI.result;
      let remotePlantStats: TypeStatisticheImpiantoDTO[] =
        remoteStats.statisticheImpianti;
      this.currentPower = remoteStats.potenzaAttuale;

      _plants = remotePlantStats.map(statImp => {
        const pM = this.plants
          ? this.plants.find(p => p.id === statImp.impianto.id)
          : new PlantModel(
              statImp.impianto.id,
              statImp.impianto.codice,
              statImp.impianto.nome,
              statImp.impianto.tipologia,
              []
            );
        const _stats: TypePlantStatistics = {
          power: statImp.potenza,
          maxPower: statImp.potenzaMassima,
          dailyProduction: statImp.produzioneOdierna,
          hasAlarms: statImp.hasAllarmi,
          hasWarning: statImp.hasWarning
        };
        /* $FlowFixMe */
        pM.setStatistics(_stats);
        /* $FlowFixMe */
        return pM;
      });

      this.plants = _plants;
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError")
      };
    }

    this.isFetchingStatistics = false;
    return result;
  }; // fetchPlantsStatistics

  @action
  fetchAnnualProductions = async (): Promise<ResultMessage> => {
    let result = { success: true };

    this.isFetchingAnnualProd = true;
    try {
      const _cDate = new Date(),
        _cMonth = _cDate.getMonth() + 1,
        _cYear = _cDate.getFullYear();

      const remoteProdListM = await api.getProduzioniListaMensile(_cYear);

      if (!remoteProdListM.success) {
        throw Error(remoteProdListM.error);
      }

      const _productions: TypeProduzioneDTO[] = remoteProdListM.result;

      // Limit data until the current month
      const validMonthsData: TypeProduzioneDTO[] = _productions.filter(
        prod => prod.data <= _cMonth
      );

      // Find the max production for the scale of the Indicators
      const annualMaxProduction = validMonthsData.reduce(
        (result: number, production) =>
          production.valoreMassimo > result ? production.valoreMassimo : result,
        0
      );

      this.annualTotalProduction = validMonthsData.reduce(
        (result: number, production) => production.valore + result,
        0
      );

      // map Productions to Indicators
      this.annualProductions = validMonthsData.map(prod => ({
        key: prod.data.toString(),
        label: i18nService.t(`common:calendar:months:${prod.data}`),
        samples: [
          {
            label: i18nService.t(`common:kWhValue`, {
              value: utilText.formatNumber(prod.valore)
            }),
            value: prod.valore,
            max: annualMaxProduction
          }
        ]
      }));
      this.isFetchingAnnualProd = false;
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError")
      };
    }

    return result;
  }; // fetchAnnualProductions

  @action resetMonthlyProductions = () => {
    this.monthlyTotalProduction = 0;
    this.monthlyProductions = null;
  }; // resetMonthlyProductions

  @action
  fetchMonthlyProductions = async (month: number): Promise<ResultMessage> => {
    this.isFetchingMonthlyProductions = true;

    let result = { success: true };

    try {
      const _cDate = new Date(),
        _cDay = _cDate.getDate(),
        _cMonth = _cDate.getMonth() + 1,
        _cYear = _cDate.getFullYear();

      const remoteProdListG = await api.getProduzioniListaGiornaliera(
        _cYear,
        month
      );

      if (!remoteProdListG.success) {
        throw Error(remoteProdListG.error);
      }

      let _productions: TypeProduzioneDTO[] = remoteProdListG.result;

      // Only for the current month, limit data until the current day
      if (month == _cMonth) {
        _productions = _productions.filter(prod => prod.data <= _cDay);
      }

      // Find the max production for the scale of the Indicators
      const monthlyMaxProduction = _productions.reduce(
        (result: number, production) =>
          production.valoreMassimo > result ? production.valoreMassimo : result,
        0
      );

      this.monthlyTotalProduction = _productions.reduce(
        (result: number, production) => production.valore + result,
        0
      );

      // map Productions to Indicators
      this.monthlyProductions = _productions.map(prod => {
        const date = new Date(_cYear, month - 1, prod.data);

        return {
          key: prod.data.toString(),
          label: `${i18nService.t(`common:calendar:days:${date.getDay()}`)} ${
            prod.data
          }`,
          samples: [
            {
              label: i18nService.t(`common:kWhValue`, {
                value: utilText.formatNumber(prod.valore)
              }),
              value: prod.valore,
              max: monthlyMaxProduction
            }
          ]
        };
      });
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError")
      };
    }

    this.isFetchingMonthlyProductions = false;

    return result;
  }; // fetchMonthlyProductions

  @action
  fetchUserAlarms = async () => {
    this.isFetchingUserAlarms = true;
    this.userAlarms = [];

    let result = { success: true };

    try {
      const resLCA = await api.getListaClassiAllarmi();

      if (!resLCA.success) {
        throw new Error(resLCA.error);
      }

      const remoteAlarmClasses: TypeClasseAllarmeDTO[] = resLCA.result;

      const resLA = await api.getListaAllarmi();

      if (!resLA.success) {
        throw Error(resLA.error);
      }

      let remoteAlarms: TypeAllarmeDTO[] = resLA.result;

      const _userAlarms = remoteAlarms.map(alarm => {
        const alarmClass = remoteAlarmClasses.find(
          aC => aC.nome === alarm.classe
        );

        return new AlarmModel(
          alarm.id,
          alarm.classe,
          alarm.descrizione,
          alarm.oraAck,
          alarm.oraIngresso,
          alarm.oraUscita,
          alarmClass ? alarmClass.colore : "",
          alarm.nomeImpianto || i18nService.t("alarm:private")
        );
      });

      this.userAlarms = _userAlarms;
    } catch (err) {
      result = {
        success: false,
        type: "err",
        error: err.message,
        message: i18nService.t("common:requestError")
      };
    }

    this.isFetchingUserAlarms = false;
    return result;
  }; // fetchUserAlarms
}
