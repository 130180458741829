/** @flow */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, action, computed } from "mobx";
import { withTranslation } from "react-i18next";

import PlantModel from "../models/Plant";

import CommonLayout from "../components/CommonLayout";
import PlantSelect from "../components/PlantSelect";
import MessageBox from "../components/MessageBox";

import Button from "../components/Button";
import DatePicker from "../components/DatePicker";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

import type { User as TypeUser } from "../types/User";
import type { Match as TypeMatch } from "../types/ContextRouter";
import type { Language as TypeLanguage } from "../types/Language";

const MODULE_NAME = "REPORT_MENSILE";

type StoresProps = {
  user: TypeUser,
  setCurrentModule: (module: string, plantId?: ?string) => string,
  plants: ?(PlantModel[]),
  plant: ?PlantModel,
  plantsAllowed: PlantModel[],
  sessionLanguage: TypeLanguage,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = {
  match: TypeMatch, //react-router url parameters
  t: Translate,
  i18n: I18n
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    setCurrentModule: stores.auth.setCurrentModule,
    plants: stores.plants.plants,
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    plantsAllowed: stores.auth.plantsAllowed,
    sessionLanguage: stores.auth.sessionLanguage,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class PlantReportMonthly extends Component<Props, State> {
  @observable
  isFetchingReport: boolean = false;
  @observable
  monthDate: ?Date;
  @observable
  fetchError: TypeResultMessage | null;

  @action
  setMonth = (date: Date) => (this.monthDate = date);

  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  @computed
  get validForm() {
    return this.monthDate;
  }

  componentDidMount() {
    this.props.setCurrentModule(
      MODULE_NAME,
      this.props.plant ? this.props.plant.id : null
    );
    this.initView();
  }

  initView = () => {
    // Set to default monthDate = previous month
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
    this.setMonth(date);
  };

  fetchReport = async () => {
    const { plant, markSessionAsExpired } = this.props;
    this.isFetchingReport = true;
    this.setFetchError(null);

    if (plant && this.monthDate) {
      const res = await plant.fetchMonthlyReport(
        this.monthDate.getFullYear(),
        // $FlowFixMe
        this.monthDate.getMonth() + 1
      );

      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);
      } else {
        res.data && window.location.assign(res.data.filename);
      }
    }

    this.isFetchingReport = false;
  };

  render() {
    const {
      user,
      plant,
      plantsAllowed,
      t,
      sessionLanguage,
      isSessionExpired,
      isOpenSidebarMenu,
      toggleSidebarMenu
    } = this.props;

    const maxDate = new Date(new Date().setDate(new Date().getDate()));

    if (isSessionExpired) return null;

    return (
      <CommonLayout
        breadcrumb={`${t("report")} ${t("monthly")}`}
        user={user}
        permissions={plant ? plant.permissions : []}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          {plant && <PlantSelect plant={plant} plants={plantsAllowed} />}
        </div>

        <div className={"content lr-pdd"}>
          {this.fetchError && (
            <MessageBox
              /** $FlowFixMe */
              type={this.fetchError.type}
              /** $FlowFixMe */
              message={this.fetchError.message}
            />
          )}

          <form id={"login-form"} noValidate>
            <DatePicker
              label={t("calendar.month")}
              iconName={"calendar"}
              selected={this.monthDate}
              onChange={this.setMonth}
              monthPicker={true}
              maxDate={maxDate}
              locale={sessionLanguage}
            />

            <div className={"btn-wrapper"}>
              <Button
                icon={"download-alt"}
                text={t("generateReport")}
                onClick={this.fetchReport}
                disabled={!this.validForm}
                isLoading={this.isFetchingReport}
              />
            </div>
          </form>
        </div>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(PlantReportMonthly);
