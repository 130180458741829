/** @flow */
import React, { Component } from "react";

type Props = {
  enabled?: boolean,
  label?: string,
  onClick?: (e: any) => any
};
type State = {
  enabled: boolean
};

class ToggleSwitch extends Component<Props, State> {
  state = {
    enabled:
      typeof this.props.enabled === "undefined" ? false : this.props.enabled
  };

  get isEnabled() {
    return this.state.enabled;
  }

  toggleSwitch = (e: any) => {
    e.persist();
    e.preventDefault();

    const { onClick } = this.props;

    this.setState(
      {
        enabled: !this.state.enabled
      },
      () => {
        //const switchEvent = Object.assign(e, { SWITCH_STATE: this.state });
        e.target.value = this.state.enabled;

        if (onClick) {
          onClick(e);
        }
      }
    );
  };

  render() {
    const { label } = this.props;
    const { enabled, ...othersProps } = this.state;

    return (
      <div
        className={"switch switch--default"}
        onClick={this.toggleSwitch}
        {...othersProps}
      >
        <div
          className={`switch-toggle switch-toggle--${enabled ? "on" : "off"}`}
        />
        {label && <label>{label}</label>}
      </div>
    );
  }
}

export default ToggleSwitch;
