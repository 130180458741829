/** @flow */
import React, { Component } from "react";
import { observable, computed, action } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";

import CommonLayout from "../components/CommonLayout";
import PlantSelect from "../components/PlantSelect";
import Indicator from "../components/Indicator";
import Toolbar from "../components/Toolbar";
import MessageBox from "../components/MessageBox";

import PlantModel from "../models/Plant";

import utilText from "../utils/text";

import type { Stores } from "../types/Stores";
import type { User as TypeUser } from "../types/User";
import type { Translate, I18n } from "../types/I18n";
import type { Match as TypeMatch } from "../types/ContextRouter";
import type { RouterHistory as TypeRouterHistory } from "../types/ContextRouter";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

type StoresProps = {
  user: TypeUser,
  plant: ?PlantModel,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = {
  t: Translate,
  i18n: I18n,
  match: TypeMatch, //react-router url parameters
  history: TypeRouterHistory //react-router-dom
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    plant: stores.plants.plants
      ? stores.plants.plants.find(p => p.id === plantId)
      : null,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu
  };
};

@inject(mapStoresToProps)
@observer
class PlantMonthlyPerformanceRatio extends Component<Props, State> {
  @observable
  year: number;
  @observable
  month: number;
  @observable
  isFetchingData: boolean;
  @observable
  fetchError: TypeResultMessage | null;

  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  @computed
  get monthlyProductionsData() {
    return this.year && this.month && this.props.plant
      ? this.props.plant.monthlyPerfomanceRatio[`${this.year}${this.month}`]
      : null;
  }

  componentDidMount() {
    this.initView();
  }

  initView = () => {
    const { match } = this.props;

    if (match.params.year && match.params.month) {
      this.fetchData(parseInt(match.params.year), parseInt(match.params.month));
    }
  };

  fetchData = async (year: number, month: number) => {
    const { plant, history, markSessionAsExpired } = this.props;
    if (plant) {
      this.isFetchingData = true;
      this.year = year;
      this.month = month;

      this.setFetchError(null);

      const res = await plant.fetchMonthlyPerformanceRatio(year, month);
      this.isFetchingData = false;

      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);
      }
    }
  };

  render() {
    const {
        t,
        user,
        plant,
        match,
        isSessionExpired,
        isOpenSidebarMenu,
        toggleSidebarMenu
      } = this.props,
      year = match.params.year,
      month = match.params.month;

    if (isSessionExpired) return null;

    return (
      <CommonLayout
        breadcrumb={`${t("dailyPerformanceRatio")} ${plant ? plant.name : ""}`}
        user={user}
        permissions={plant ? plant.permissions : []}
        backButton={true}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          <h2>
            {t("monthlyProductions", {
              month: t(`calendar.months.${month ? month : "-1"}`),
              year
            })}
          </h2>
        </div>
        <section className="content lr-pdd prod-ann-container">
          {this.fetchError && (
            <MessageBox
              /** $FlowFixMe */
              type={this.fetchError.type}
              /** $FlowFixMe */
              message={this.fetchError.message}
            />
          )}
          {this.monthlyProductionsData &&
            this.monthlyProductionsData.indicators.map((prod, idx) => (
              <Indicator key={idx} data={prod} />
            ))}
        </section>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(
  withRouter(PlantMonthlyPerformanceRatio)
);
