/** @flow */
import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { observable, computed, action, toJS } from "mobx";
import { withTranslation } from "react-i18next";

import PlantModel from "../models/Plant";

import dateTimeUtils from "../utils/dateTime";
import chartConfig from "../config/chart";

import CommonLayout from "../components/CommonLayout";
import PlantSelect from "../components/PlantSelect";
import MessageBox from "../components/MessageBox";
import Button from "../components/Button";
import Drawer from "../components/Drawer";
import TrendForm from "../components/TrendForm";
import EmptyFilterPlaceholder from "../components/EmptyFilterPlaceholder";
import XYChart from "../components/XYChart";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { User as TypeUser } from "../types/User";
import type { Match as TypeMatch } from "../types/ContextRouter";
import type { TrendFilter as TypeTrendFilter } from "../types/TrendFilter";
import type { Language as TypeLanguage } from "../types/Language";

const MODULE_NAME = "TREND";

type StoresProps = {
  user: TypeUser,
  setCurrentModule: (module: string, plantId?: ?string) => string,
  plants: ?(PlantModel[]),
  plant: ?PlantModel,
  plantsAllowed: PlantModel[],
  sessionLanguage: TypeLanguage,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any,
};
type OwnProps = {
  match: TypeMatch, //react-router url parameters
  t: Translate,
  i18n: I18n,
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    setCurrentModule: stores.auth.setCurrentModule,
    plants: stores.plants.plants,
    plant: stores.plants.plants
      ? stores.plants.plants.find((p) => p.id === plantId)
      : null,
    plantsAllowed: stores.auth.plantsAllowed,
    sessionLanguage: stores.auth.sessionLanguage,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu,
  };
};

@inject(mapStoresToProps)
@observer
class PlantTrend extends Component<Props, State> {
  @observable
  visibleDrawer: boolean = false;
  @observable
  filter: ?TypeTrendFilter;
  @observable
  isFetching: boolean = false;
  @observable
  fetchError: TypeResultMessage | null;
  @observable
  chartSeries: any;

  @action
  setFetchError = (data: TypeResultMessage | null) => (this.fetchError = data);

  @computed
  get primaryChartSeries() {
    return this.chartSeries && this.chartSeries[0].data.length > 0
      ? this.chartSeries[0]
      : this.chartSeries[1] || this.chartSeries[0] || null;
  }

  @computed
  get secondaryChartSeries() {
    return this.chartSeries &&
      this.chartSeries.length > 1 &&
      this.chartSeries[0].data.length === 0 &&
      this.chartSeries[1].data.length > 0
      ? this.chartSeries[0] || null
      : this.chartSeries[1] || null;
  }

  @computed
  get chartFilterLegend() {
    const items = [];

    if (
      !this.filter ||
      !this.chartSeries ||
      (this.chartSeries && this.chartSeries.length === 0)
    ) {
      return items;
    }
    // date
    items.push({
      icon: "filter",
      value: dateTimeUtils.getDateFormatISO8601(this.filter.date),
    });
    //first filter item
    items.push({
      icon: "circle-empty",
      color: chartConfig.colors.primary,
      value: this.primaryChartSeries.description,
    });
    // $FlowFixMe
    if (this.filter.secondTagId) {
      //second filter item
      items.push({
        icon: "circle-empty",
        color: chartConfig.colors.secondary,
        value: this.secondaryChartSeries.description,
      });
    }

    return items;
  }

  componentDidMount() {
    this.props.setCurrentModule(
      MODULE_NAME,
      this.props.plant ? this.props.plant.id : null
    );

    this.fetchTags();

    // Refresh chart data on screen rotation
    window.addEventListener("orientationchange", this.handleRotationChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.plantId !== prevProps.match.params.plantId) {
      this.filter = null;
      this.fetchError = null;
      this.chartSeries = null;

      this.fetchTags();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.handleRotationChange);
  }

  handleRotationChange = () => {
    setTimeout(() => this.fetchData(true), 500);
  };

  fetchTags = async () => {
    const { plant, markSessionAsExpired } = this.props;
    this.setFetchError(null);

    if (plant) {
      const res = await plant.fetchTrendTags();

      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);
      }
    }
  };

  toggleDrawer = () => {
    this.visibleDrawer = !this.visibleDrawer;
  };

  fetchTagHistoryTrendPoints = async (
    tagId: string,
    historyTagKey: string,
    forceRefresh: boolean = false
  ) => {
    const { plant, markSessionAsExpired } = this.props;
    this.setFetchError(null);

    if (plant && plant.trendTags) {
      const tag = plant.trendTags.find((tag) => tag.id === tagId);

      if (!tag) {
        return;
      }

      const res = await tag.fetchTrendPoints(historyTagKey, forceRefresh);
      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);

        return;
      }

      this.chartSeries.push({
        id: tagId,
        description: tag.description,
        yAxisTitle: tag.measureUnit,
        data: tag.formatTrendPointsChart(historyTagKey),
        yDomain: {
          min: tag.minValue,
          max: tag.maxValue,
        },
      });
    }
  };

  fetchData = async (forceRefresh: boolean = false) => {
    const { plant } = this.props;

    if (plant && plant.trendTags && this.filter) {
      const historyTagKey = dateTimeUtils.getDateFormatISO8601(
          this.filter ? this.filter.date : new Date()
        ),
        firstTagId = this.filter ? this.filter.firstTagId : "",
        secondTagId = this.filter ? this.filter.secondTagId : "";

      this.isFetching = true;
      this.chartSeries = [];

      await this.fetchTagHistoryTrendPoints(
        firstTagId,
        historyTagKey,
        forceRefresh
      );

      if (secondTagId) {
        await this.fetchTagHistoryTrendPoints(
          secondTagId,
          historyTagKey,
          forceRefresh
        );
      }

      this.isFetching = false;
    }
  };

  handleSubmitForm = (filterData: TypeTrendFilter) => {
    this.toggleDrawer();
    this.filter = filterData;

    this.fetchData(true);
  };

  render() {
    const {
      user,
      plant,
      plantsAllowed,
      t,
      sessionLanguage,
      isSessionExpired,
      isOpenSidebarMenu,
      toggleSidebarMenu,
    } = this.props;

    if (isSessionExpired) return null;

    return (
      <CommonLayout
        breadcrumb={t("trend")}
        user={user}
        permissions={plant ? plant.permissions : []}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className="sub-header lr-pdd">
          {plant && <PlantSelect plant={plant} plants={plantsAllowed} />}
        </div>

        <div className={"content trend-container"}>
          <div className={"lr-pdd"}>
            <div className={"btn-wrapper"}>
              <Button
                icon={"filter"}
                onClick={this.toggleDrawer}
                isLoading={this.isFetching}
              />
            </div>
            {!this.chartSeries && <EmptyFilterPlaceholder />}
          </div>

          {this.fetchError && (
            <MessageBox
              /** $FlowFixMe */
              type={this.fetchError.type}
              /** $FlowFixMe */
              message={this.fetchError.message}
            />
          )}
          {!this.isFetching && this.chartSeries && (
            <div className="chart-wrapper">
              <XYChart
                key={`${isOpenSidebarMenu.toString()}`}
                isLoading={false}
                xTitle={"h"}
                yTitle={
                  this.primaryChartSeries
                    ? this.primaryChartSeries.yAxisTitle
                    : null
                }
                ySecondaryTitle={
                  this.secondaryChartSeries
                    ? this.secondaryChartSeries.yAxisTitle
                    : null
                }
                series={
                  this.primaryChartSeries
                    ? [
                        {
                          unit: this.primaryChartSeries.yAxisTitle,
                          data: toJS(this.primaryChartSeries.data),
                          color: chartConfig.colors.primary,
                          yDomain: this.primaryChartSeries.yDomain,
                        },
                      ]
                    : null
                }
                secondarySeries={
                  this.secondaryChartSeries
                    ? [
                        {
                          unit: this.secondaryChartSeries.yAxisTitle,
                          data: toJS(this.secondaryChartSeries.data),
                          color: chartConfig.colors.secondary,
                          yDomain: this.secondaryChartSeries.yDomain,
                        },
                      ]
                    : null
                }
                showLegend={false}
                dontCheckIfEmpty={false}
                xTickValues={
                  this.filter ? dateTimeUtils.getDayHours(this.filter.date) : []
                }
              />

              <div className={"chart-legend"}>
                {this.chartFilterLegend.map((item, idx) => (
                  <div key={idx} className={"chart-legend-item"}>
                    <i
                      className={`icon-${item.icon}`}
                      style={item.color ? { color: item.color } : {}}
                    />
                    <span>{item.value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <Drawer
          open={this.visibleDrawer}
          position={"right"}
          toggle={this.toggleDrawer}
          className={["drawer-lg"]}
        >
          <div className="drawer-header">
            <button className="btt-close" onClick={this.toggleDrawer}>
              <i className={"icon-cancel"} />
            </button>
          </div>
          <div className={"drawer-content"}>
            {plant && (
              <TrendForm
                values={this.filter}
                tagList={plant.trendTags}
                onSubmit={this.handleSubmitForm}
                locale={sessionLanguage}
              />
            )}
          </div>
        </Drawer>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(PlantTrend);
