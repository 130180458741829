/** @flow */
import React from "react";
import logo from "../assets/images/logo-home.svg";
import textLogo from "../assets/images/text-ste-guardian.svg";

const Logo = () => (
  <div className="logo">
    {/* <img className="logoImg" src={logo} alt={"e-guardian logo"} /> */}
    <img className="logoText" src={textLogo} alt={"e-guardian"} />
    {/* <h1>
      STE <span>Guardian</span>
    </h1>  */}
  </div>
);

export default Logo;
