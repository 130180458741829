/** @flow */
import React, { Component } from "react";
import { observable, computed, action } from "mobx";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import CommonLayout from "../components/CommonLayout";
import PresetList from "../components/PresetList";
import Button from "../components/Button";
import MessageBox from "../components/MessageBox";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { User as TypeUser } from "../types/User";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";
import type { PresetDTO as TypePreset } from "../types/PresetDTO";

const MODULE_NAME = "PRESET";

type StoresProps = {
  setCurrentModule: (module: string) => string,
  user: TypeUser,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any,
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  fetchPresetList: () => any,
  presetList: TypePreset[] | null,
  setPreset: (presetId: string) => any
};
type OwnProps = { t: Translate, i18n: I18n };
type Props = StoresProps & OwnProps;
type State = {};

const mapStoresToProps = (stores: Stores): StoresProps => ({
  setCurrentModule: stores.auth.setCurrentModule,
  user: stores.auth.user,
  isOpenSidebarMenu: stores.navigation.mainMenuOpen,
  toggleSidebarMenu: stores.navigation.toggleMainMenu,
  isSessionExpired: stores.auth.isSessionExpired,
  markSessionAsExpired: stores.auth.markSessionAsExpired,
  fetchPresetList: stores.auth.fetchPresetList,
  presetList: stores.auth.presetList,
  setPreset: stores.auth.setPreset
});

@inject(mapStoresToProps)
@observer
class Preset extends Component<Props, State> {
  @observable
  presetSelectedId: string = "";
  @observable
  initialPresetSelectedId: string = "";
  @observable
  isFetching: boolean = false;
  @observable
  fetchResultMessage: TypeResultMessage | null;
  @observable
  isResettingPreset: boolean = false;
  @observable
  isSubmittingPreset: boolean = false;

  @action
  setFetchResultMessage = (data: TypeResultMessage | null) =>
    (this.fetchResultMessage = data);

  @computed
  get isPresetChanged() {
    return this.presetSelectedId !== this.initialPresetSelectedId;
  }

  componentDidMount() {
    this.props.setCurrentModule(MODULE_NAME);

    this.fetchData();
  }

  _checkEnabledPreset = () => {
    const { presetList } = this.props;
    if (presetList) {
      const activePreset = presetList.find(preset => preset.attivo);

      if (activePreset) {
        this.initialPresetSelectedId = activePreset.id;
        this.presetSelectedId = activePreset.id;
      }
    }
  };

  fetchData = async () => {
    this.isFetching = true;
    const result = await this.props.fetchPresetList();

    if (!result.success) {
      if (result.error === "Unauthorized") {
        this.props.markSessionAsExpired();
      }
      this.setFetchResultMessage(result);
    } else {
      this._checkEnabledPreset();
    }
    this.isFetching = false;
  };

  handleClick = async preset => {
    this.presetSelectedId = preset.id;

    this.isSubmittingPreset = true;
    const result = await this.props.setPreset(this.presetSelectedId);

    this.setFetchResultMessage(result);
    if (!result.success && result.error === "Unauthorized") {
      this.props.markSessionAsExpired();
    }

    if (result.success) {
      this.initialPresetSelectedId = this.presetSelectedId;
    }

    this.isSubmittingPreset = false;
  };

  resetPreset = async () => {
    this.isResettingPreset = true;
    const result = await this.props.setPreset("");

    this.setFetchResultMessage(result);
    if (!result.success && result.error === "Unauthorized") {
      this.props.markSessionAsExpired();
    }

    this.isResettingPreset = false;
    this.presetSelectedId = "";
    this.initialPresetSelectedId = "";
  };

  render() {
    const {
      t,
      user,
      isOpenSidebarMenu,
      toggleSidebarMenu,
      presetList,
      isSessionExpired
    } = this.props;
    if (isSessionExpired) return null;
    return (
      <CommonLayout
        breadcrumb={t("preset")}
        user={user}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className={"preset-actions lr-pdd"}>
          <div className={""}>
            {this.fetchResultMessage && this.fetchResultMessage.type && (
              <MessageBox
                type={this.fetchResultMessage.type}
                /** $FlowFixMe */
                message={this.fetchResultMessage.message}
              />
            )}
          </div>
          <Button
            icon={"ccw"}
            text={t("resetPreset")}
            onClick={this.resetPreset}
            disabled={!this.initialPresetSelectedId}
            isLoading={this.isResettingPreset}
            isSecondary={true}
          />
        </div>
        <div className={"content preset-list lr-pdd"}>
          {presetList && (
            <PresetList
              data={presetList}
              onClick={this.handleClick}
              selectedItemId={this.presetSelectedId}
            />
          )}
        </div>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(Preset);
