/** @flow */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { observable, computed, action } from "mobx";
import { withTranslation } from "react-i18next";

import CommonLayout from "../components/CommonLayout";
import PlantSelect from "../components/PlantSelect";
import TagsList from "../components/TagsList";
import XYChart from "../components/XYChart";
import MessageBox from "../components/MessageBox";

import PlantModel from "../models/Plant";
import TagModel from "../models/Tag";

import dateTimeUtils from "../utils/dateTime";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { ResultMessage as TypeResultMessage } from "../types/ResultMessage";

import type { User as TypeUser } from "../types/User";
import type { Match as TypeMatch } from "../types/ContextRouter";
import type { ChartDot as TypeChartDot } from "../types/ChartDot";

const MODULE_NAME = "MONITOR";

type StoresProps = {
  user: TypeUser,
  setCurrentModule: (module: string, plantId?: ?string) => string,
  plants: ?(PlantModel[]),
  plant: ?PlantModel,
  plantsAllowed: PlantModel[],
  isSessionExpired: boolean,
  markSessionAsExpired: () => any,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any,
  isPlantEnabledOnModule: (plantId: string, moduleName: string) => any,
};
type OwnProps = {
  match: TypeMatch, //react-router url parameters
  t: Translate,
  i18n: I18n,
};
type State = {};
type Props = StoresProps & OwnProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const plantId = props.match.params.plantId || "";
  return {
    user: stores.auth.user,
    setCurrentModule: stores.auth.setCurrentModule,
    plants: stores.plants.plants,
    plant: stores.plants.plants
      ? stores.plants.plants.find((p) => p.id === plantId)
      : null,
    plantsAllowed: stores.auth.plantsAllowed,
    isSessionExpired: stores.auth.isSessionExpired,
    markSessionAsExpired: stores.auth.markSessionAsExpired,
    isOpenSidebarMenu: stores.navigation.mainMenuOpen,
    toggleSidebarMenu: stores.navigation.toggleMainMenu,
    isPlantEnabledOnModule: stores.auth.isPlantEnabledOnModule,
  };
};

@inject(mapStoresToProps)
@observer
class PlantDetails extends Component<Props, State> {
  @observable
  selectedTag: TagModel;
  @observable
  isFetchingTrendPoints: boolean;
  @observable
  fetchError: TypeResultMessage | null;

  @action setFetchError = (data: TypeResultMessage | null) =>
    (this.fetchError = data);

  @computed
  get tagTrendPoints() {
    const { plant } = this.props;
    let res = null;

    if (this.selectedTag) {
      res = this.selectedTag.formatTrendPointsChart(
        dateTimeUtils.getDateFormatISO8601()
      );
    }
    return res;
  }

  componentDidMount() {
    this.props.setCurrentModule(
      MODULE_NAME,
      this.props.plant ? this.props.plant.id : null
    );
    this.fetchTags();

    // Refresh chart data on screen rotation
    window.addEventListener("orientationchange", this.handleRotationChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.plantId !== prevProps.match.params.plantId) {
      this.fetchTags();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.handleRotationChange);
  }

  handleRotationChange = () => {
    setTimeout(this.refreshData, 500);
  };

  refreshData = () => {
    if (this.selectedTag) {
      this.fetchData(this.selectedTag.id, true);
    }
  };

  fetchTags = async () => {
    this.isFetchingTrendPoints = true;
    const { plant, markSessionAsExpired, isPlantEnabledOnModule } = this.props;

    if (plant) {
      this.setFetchError(null);

      const trendModuleEnabled = isPlantEnabledOnModule(plant.id, "TREND");

      const res = await plant.fetchTags(trendModuleEnabled);

      if (!res.success) {
        if (res.error === "Unauthorized") {
          markSessionAsExpired();
        }
        this.setFetchError(res);
      } else if (plant.tags.length > 0) {
        this.fetchData(plant.tags[0].id);
      }
    }
  };

  handleClick = async (tag: TagModel) => {
    this.fetchData(tag.id, true);
  };

  fetchData = async (tagId: string, forceRefresh: boolean = false) => {
    const { plant, markSessionAsExpired } = this.props;
    if (plant) {
      this.isFetchingTrendPoints = true;
      this.setFetchError(null);

      const tag = plant.tags.find((tag) => tag.id === tagId);

      if (tag) {
        this.selectedTag = tag;
        const res = await tag.fetchTrendPoints(
          dateTimeUtils.getDateFormatISO8601(),
          forceRefresh
        );

        if (!res.success) {
          if (res.error === "Unauthorized") {
            markSessionAsExpired();
          }
          this.setFetchError(res);
        }
      }

      this.isFetchingTrendPoints = false;
    }
  };

  render() {
    const {
      t,
      plant,
      user,
      plantsAllowed,
      isSessionExpired,
      isOpenSidebarMenu,
      toggleSidebarMenu,
    } = this.props;
    const tag = this.selectedTag;
    const chartSerie = [];

    if (isSessionExpired) return null;

    if (this.selectedTag && this.tagTrendPoints) {
      chartSerie.push({
        unit: this.selectedTag.measureUnit,
        title: this.selectedTag.description,
        data: this.tagTrendPoints,
        yDomain: {
          min: this.selectedTag.minValue || null,
          max: this.selectedTag.maxValue || null,
        },
      });
    }

    return (
      <CommonLayout
        breadcrumb={t("plantDetails")}
        user={user}
        permissions={plant ? plant.permissions : []}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <div className={"sub-header lr-pdd plant-details"}>
          {plant && <PlantSelect plant={plant} plants={plantsAllowed} />}

          {this.fetchError && (
            <MessageBox
              /** $FlowFixMe */
              type={this.fetchError.type}
              /** $FlowFixMe */
              message={this.fetchError.message}
            />
          )}

          {!this.fetchError && this.selectedTag && (
            <XYChart
              key={`${isOpenSidebarMenu.toString()}`}
              isLoading={this.isFetchingTrendPoints}
              showLegend={false}
              series={chartSerie}
              xTitle={"h"}
              yTitle={
                this.selectedTag.measureUnit ? this.selectedTag.measureUnit : ""
              }
            />
          )}
        </div>
        {plant && plant.tags && !this.fetchError && (
          <TagsList
            data={plant.tags}
            onClick={this.handleClick}
            selectedItemId={this.selectedTag ? this.selectedTag.id : null}
          />
        )}
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(PlantDetails);
