/** @flow */
import React from "react";
import { withTranslation } from "react-i18next";

import arrow from "../assets/images/ascend.svg";

import type { Translate, I18n } from "../types/I18n";

type Props = {
  t: Translate
};

const EmptyFilterPlaceholder = (props: Props) => (
  <div className={"empty-filter-placeholder-container"}>
    <span className={"empty-filter-placeholder-text"}>
      {props.t("emptyTrendFilter")}
    </span>
    <img className={"img-arrow"} src={arrow} alt={"Set filter"} />
  </div>
);

export default withTranslation("common")(EmptyFilterPlaceholder);
