/* @flow */
import React, { Component } from "react";

import Drawer from "./Drawer";
import MainMenu from "./MainMenu";

import type { User as TypeUser } from "../types/User";

type Props = {
  user: TypeUser,
  permissions?: string[],
  plantId?: string,
  open: boolean,
  handleToggle: () => any
};
type State = {};

class MainSidebar extends Component<Props, State> {
  render() {
    const { user, permissions, plantId, open, handleToggle } = this.props;

    return (
      <Drawer
        open={open}
        position={"left"}
        toggle={handleToggle}
        className={["main-sidebar"]}
      >
        <MainMenu
          user={user}
          permissions={permissions}
          plantId={plantId}
          toggle={handleToggle}
        />
      </Drawer>
    );
  }
}

export default MainSidebar;
