/** @flow */
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import CommonLayout from "../components/CommonLayout";
import TabNav from "../components/TabNav";
import HomePlantsStatistics from "./HomePlantsStatistics";
import HomeAnnualProductions from "./HomeAnnualProductions";
import HomeAlarms from "./HomeAlarms";

import type { Stores } from "../types/Stores";
import type { Translate, I18n } from "../types/I18n";
import type { TabNav as TypeTabNav } from "../types/TabNav";
import type { User as TypeUser } from "../types/User";

const MODULE_NAME = "HOME";

type StoresProps = {
  setCurrentModule: (module: string, plantId: string) => string,
  user: TypeUser,
  isOpenSidebarMenu: boolean,
  toggleSidebarMenu: () => any
};
type OwnProps = { t: Translate, i18n: I18n };
type Props = StoresProps & OwnProps;
type State = {};

const mapStoresToProps = (stores: Stores): StoresProps => ({
  setCurrentModule: stores.auth.setCurrentModule,
  user: stores.auth.user,
  isOpenSidebarMenu: stores.navigation.mainMenuOpen,
  toggleSidebarMenu: stores.navigation.toggleMainMenu
});

@inject(mapStoresToProps)
@observer
class Home extends Component<Props, State> {
  componentDidMount() {
    this.props.setCurrentModule(MODULE_NAME, "-1");
  }

  render() {
    const { t, user, isOpenSidebarMenu, toggleSidebarMenu } = this.props;

    const tabs: TypeTabNav[] = [
      {
        label: t("plants"),
        icon: "marker",
        url: "plants"
      },
      {
        label: t("productions"),
        icon: "flash",
        url: "productions"
      },
      {
        label: t("alarms"),
        icon: "menu-alarms",
        url: "alarms"
      }
    ];

    return (
      <CommonLayout
        user={user}
        isMainMenuOpen={isOpenSidebarMenu}
        toggleMainMenu={toggleSidebarMenu}
      >
        <TabNav tabsNav={tabs}>
          <HomePlantsStatistics />
          <HomeAnnualProductions />
          <HomeAlarms />
        </TabNav>
      </CommonLayout>
    );
  }
}

export default withTranslation("common")(Home);
